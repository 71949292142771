import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Session } from 'src/app/models/session';
import { Responder365AppConfig } from 'src/app/services/app-config-service';
import { LoadingService } from 'src/app/services/loading-service';
import { SessionService } from 'src/app/services/session-service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  error: string = '';
  constructor(
    public authService: MsalService,
    private config: Responder365AppConfig,
    private sessionService: SessionService,
    private router: Router,
    public loadingService: LoadingService
  ) {}
  async ngOnInit(): Promise<void> {
    this.loadingService.show();
    this.error = history.state.error;
    let session: Session | null = null;

    if (!this.error) {
      try {
        session = await this.sessionService.fetch();
      } catch (err: any) {
        console.error(err);
        switch (err.status) {
          case 401:
            this.error = 'invalid-user';
            break;
          case 403:
            this.error = 'expired-session';
            break;
          default:
            this.error = 'unknown';
        }
      } finally {
        if (session?.roles?.length != null && session.roles.length > 0)
          await this.router.navigateByUrl('/');
      }
    }
    this.loadingService.hide();
  }

  async switchAccount() {
    this.loadingService.show();
    try {
      await this.sessionService.end();
    } finally {
      await this.authService.instance.loginRedirect({
        scopes: [`api://${this.config?.authConfig?.clientId}/User.Api.Access`],
        prompt: 'select_account',
      });
    }
  }
}
