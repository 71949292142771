import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ConsentInformation,
  PrerequisiteAppIds,
} from 'src/app/models/consent-information';
import { LogSource } from 'src/app/models/log-source';
import { Responder365AppConfig } from 'src/app/services/app-config-service';
import { AppInsightsService } from 'src/app/services/app-insights-service';
import { LoadingService } from 'src/app/services/loading-service';
import { RegistrationService } from 'src/app/services/registration-service';

@Component({
  selector: 'app-admin-consent',
  templateUrl: './admin-consent.component.html',
  styleUrls: ['./admin-consent.component.scss'],
})
export class AdminConsentComponent implements OnInit {
  consent = false;
  tenantId: string = '';
  state: string = '';
  error: string = '';
  error_description: string = '';
  appId: string = '';
  prerequisiteAppId: string = '';
  consentState: ConsentInformation[] = [];
  nextAppLogSources!: LogSource[];
  nextConsentInfo: ConsentInformation | undefined;
  confirmationOpen: boolean = false;
  consentInfo: ConsentInformation | undefined;
  shortError: string = '';
  PrerequisiteAppIds = PrerequisiteAppIds;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private registrationService: RegistrationService,
    private loadingService: LoadingService,
    private appInsightsService: AppInsightsService,
    public config: Responder365AppConfig
  ) {}

  async ngOnInit(): Promise<void> {
    this.loadingService.show();
    let stateObject = JSON.parse(this.route.snapshot.queryParams.state);
    this.state = stateObject?.state;
    this.appId = stateObject?.appId;
    this.error = this.route.snapshot.queryParams.error;
    this.error_description = this.route.snapshot.queryParams.error_description;
    this.shortError = this.error_description?.substring(
      0,
      this.error_description?.indexOf('Trace ID:')
    );
    this.consent =
      this.route.snapshot.queryParams?.admin_consent?.toLowerCase() === 'true';
    this.tenantId = this.route.snapshot.queryParams.tenant;

    if (this.tenantId) {
      localStorage.setItem('tenantId', this.tenantId);
    }

    this.consentState = JSON.parse(
      localStorage.getItem('consent-state') ?? '[]'
    );
    this.consentInfo = this.consentState.find((ci) => ci.app_id === this.appId);
    if (this.consentInfo) {
      this.consentInfo.consent = this.consent;
      this.prerequisiteAppId = this.consentInfo.prerequisite_app_id;
      localStorage.setItem('consent-state', JSON.stringify(this.consentState));
    }

    if (this.error_description) {
      this.appInsightsService.logEvent('Admin consent failed', {
        error: this.error,
        error_description: this.error_description,
        tenant_id: this.tenantId,
      });
    }

    if (this.consent && !this.error) {
      this.appInsightsService.logEvent('Admin consent succeeded', {
        tenant_id: this.tenantId,
      });

      if (this.consent && this.appId == this.config.authConfig.clientId) {
        await this.router.navigateByUrl(`/eligible-services/${this.state}`);
      } else {
        this.nextConsentInfo = this.consentState.find(
          (cs) => !cs.consent && !cs.skipped
        );
        //If everything has been consented, navigate back to dashboard
        if (!this.nextConsentInfo && this.state === 'reconsent') {
          await this.router.navigateByUrl('/');
        }
      }
    }
    this.loadingService.hide();
  }

  async adminConsent() {
    await this.registrationService.adminConsent(
      this.appId,
      encodeURIComponent(
        JSON.stringify({
          state: this.state,
          appId: this.appId,
        })
      )
    );
  }

  async nextAdminConsent() {
    this.nextConsentInfo = this.consentState.find(
      (cs) => !cs.consent && !cs.skipped
    );
    if (this.nextConsentInfo) {
      await this.registrationService.adminConsent(
        this.nextConsentInfo.app_id,
        encodeURIComponent(
          JSON.stringify({
            state: this.state,
            appId: this.nextConsentInfo.app_id,
          })
        )
      );
    } else {
      await this.router.navigateByUrl(
        `/finish-setup?code=${this.state}&tid=${this.tenantId}`
      );
    }
  }

  async finishSetup() {
    await this.router.navigateByUrl(`/finish-setup?code=${this.state}`);
  }

  async skipConsent() {
    if (this.consentInfo) {
      this.consentInfo.skipped = true;
      localStorage.setItem('consent-state', JSON.stringify(this.consentState));
    }
    await this.nextAdminConsent();
  }
}
