<div
  class="container py-5 text-center"
  *ngIf="!(loadingService.loadingObservable$ | async)"
>
  <section
    class="d-flex justify-content-center align-items-middle align-middle"
  >
    <span
      class="material-icons-outlined text-warning pr-1"
      style="font-size: 38px"
    >
      error</span
    >
    <h2 class="danger-text">
      {{ "Error.Heading" | translate }}
    </h2>
  </section>
  <section
    class="d-flex justify-content-center align-items-middle align-middle"
  >
    <div class="description" *ngIf="error?.toLowerCase() === 'invalid-user'">
      {{ "Error.InvalidUser" | translate }}
      <div class="d-flex pt-3 justify-content-center">
        <button class="btn btn-primary" (click)="switchAccount()">
          Switch Account
        </button>
      </div>
    </div>
    <div class="description" *ngIf="error?.toLowerCase() === 'expired-session'">
      {{ "Error.SessionExpired" | translate }}
      <div class="d-flex pt-3 justify-content-center">
        <button class="btn btn-primary" (click)="authService.loginRedirect()">
          Login
        </button>
      </div>
    </div>
    <div class="description" *ngIf="error?.toLowerCase() === 'unknown'">
      {{ "Error.GenericServerError" | translate }}
    </div>
  </section>
</div>
