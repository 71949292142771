import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { lastValueFrom } from 'rxjs';
import {
  AppRole,
  ConsentInformation,
  PrerequisiteAppIds,
  ServicePrincipal,
} from 'src/app/models/consent-information';
import { LogSourceType } from 'src/app/models/log-source-type';
import { ActiveUserService } from 'src/app/services/active-user-service';
import { LoadingService } from 'src/app/services/loading-service';
import { RegistrationService } from 'src/app/services/registration-service';
import { TenantService } from 'src/app/services/tenant-service';

@Component({
  selector: 'app-eligible-services',
  templateUrl: './eligible-services.component.html',
  styleUrls: ['./eligible-services.component.scss'],
})
export class EligibleServicesComponent implements OnInit {
  eligibleAzureADLogSources: LogSourceType[] = [];
  eligibleDefenderLogSources: LogSourceType[] = [];
  eligibleOffice365LogSources: LogSourceType[] = [];
  isAzureAdDisabled = false;
  isO365Disabled = false;
  isDefenderDisabled = false;
  AzureAdDisabledAppRoles: AppRole[] = [];
  Office365DisabledAppRoles: AppRole[] = [];
  DefenderDisabledAppRoles: AppRole[] = [];
  registrationCode!: string;
  consentInformation!: ConsentInformation[];
  error: string = '';
  loadingData: boolean = false;
  gridData!: GridDataResult;
  PrerequisiteAppIds = PrerequisiteAppIds;
  constructor(
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private registrationService: RegistrationService,
    private tenantService: TenantService,
    private activeUserService: ActiveUserService,
    private router: Router,
    private translateService: TranslateService
  ) {}
  async ngOnInit() {
    try {
      this.loadingService.show();
      this.loadingData = true;
      this.registrationCode = this.route.snapshot.params.code;

      let registration = await this.registrationService.fetch(
        this.registrationCode
      );

      this.error = await this.activeUserService.validateActiveUser(
        registration.email
      );

      if (registration.log_sources.length > 0) {
        let logSourcesByAppId = new Map(
          registration.log_sources.map((ls) => [ls.responder_app_id, ls])
        );
        this.consentInformation = [...logSourcesByAppId.values()]
          .map((ls) => {
            let order = 0;
            switch (ls.log_source_type.prerequisite_app_id) {
              case PrerequisiteAppIds.Graph:
                order = 3;
                break;
              case PrerequisiteAppIds.Office365ManagementApi:
                order = 2;
                break;
              case PrerequisiteAppIds.MicrosoftThreatProtection:
                order = 1;
                break;
            }
            return <ConsentInformation>{
              app_id: ls.responder_app_id,
              prerequisite_app_id: ls.log_source_type?.prerequisite_app_id,
              consent: false,
              order: order,
            };
          })
          .sort((a, b) => a.order - b.order);
        localStorage.setItem(
          'consent-state',
          JSON.stringify(this.consentInformation)
        );
      }
      let logSourceTypes = registration.log_sources.map(
        (ls) => ls.log_source_type
      );
      let logSourcesTypesByPrerequisiteAppId = new Map(
        logSourceTypes.map((lst) => [
          lst?.prerequisite_app_id,
          logSourceTypes.filter(
            (flst) => flst.prerequisite_app_id === lst.prerequisite_app_id
          ),
        ])
      );
      this.eligibleAzureADLogSources =
        logSourcesTypesByPrerequisiteAppId.get(PrerequisiteAppIds.Graph) ?? [];
      this.eligibleDefenderLogSources =
        logSourcesTypesByPrerequisiteAppId.get(
          PrerequisiteAppIds.MicrosoftThreatProtection
        ) ?? [];
      this.eligibleOffice365LogSources =
        logSourcesTypesByPrerequisiteAppId.get(
          PrerequisiteAppIds.Office365ManagementApi
        ) ?? [];

      //Note: the call to list service principals may take a little time to start working after providing consent.
      var retries = 10;
      var servicePrincipals: ServicePrincipal[] = [];
      while (retries-- > 0) {
        try {
          servicePrincipals = await this.tenantService.listServicePrincipals();
          break;
        } catch (err: any) {
          if (retries == 0) {
            console.error(err);
            throw err;
          } else {
            await new Promise((r) => setTimeout(r, 3000));
          }
        }
      }
      let prerequisiteAppIds: string[] = [];
      for (let logSourceType of logSourceTypes) {
        prerequisiteAppIds.push(logSourceType.prerequisite_app_id);
        let servicePrincipal = servicePrincipals.find(
          (sp) => sp.app_id === logSourceType.prerequisite_app_id
        );

        switch (logSourceType.prerequisite_app_id) {
          case PrerequisiteAppIds.Graph: {
            this.isAzureAdDisabled =
              !servicePrincipal ||
              !servicePrincipal?.account_enabled ||
              !!servicePrincipal?.disabled_by_microsoft_status;
            this.AzureAdDisabledAppRoles =
              servicePrincipal?.app_roles.filter(
                (ar) => ar.is_enabled === false
              ) ?? [];
            break;
          }
          case PrerequisiteAppIds.MicrosoftThreatProtection: {
            this.isDefenderDisabled =
              !servicePrincipal ||
              !servicePrincipal?.account_enabled ||
              !!servicePrincipal?.disabled_by_microsoft_status;
            this.DefenderDisabledAppRoles =
              servicePrincipal?.app_roles.filter(
                (ar) => ar.is_enabled === false
              ) ?? [];
            break;
          }
          case PrerequisiteAppIds.Office365ManagementApi: {
            this.isO365Disabled =
              !servicePrincipal ||
              !servicePrincipal?.account_enabled ||
              !!servicePrincipal?.disabled_by_microsoft_status;
            this.Office365DisabledAppRoles =
              servicePrincipal?.app_roles.filter(
                (ar) => ar.is_enabled === false
              ) ?? [];
            break;
          }
        }
      }

      let services = await lastValueFrom(this.translateService.get('Services'));
      this.gridData = {
        data: services.filter((s: any) =>
          prerequisiteAppIds.includes(s.PrerequisiteAppId)
        ),
        total: services.length,
      };
    } catch (err: any) {
      if (err instanceof HttpErrorResponse) {
        const httpError = err as HttpErrorResponse;
        switch (httpError.error) {
          case 'Registration code has already been completed.': {
            await this.router.navigateByUrl('/');
            break;
          }
          default: {
            if (typeof httpError.error === 'string')
              this.error = httpError.error;
            else
              this.error = this.translateService.instant(
                'Error.GenericServerError'
              );
          }
        }
      }
      console.error(err);
    } finally {
      this.loadingData = false;
      this.loadingService.hide();
    }
  }

  async beginConsent() {
    let ci = this.consentInformation.find((ci) => ci.consent == false);
    if (ci)
      await this.registrationService.adminConsent(
        ci.app_id,
        encodeURIComponent(
          JSON.stringify({
            state: this.registrationCode,
            appId: ci.app_id,
          })
        )
      );
  }
}
