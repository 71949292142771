import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { TranslateService } from '@ngx-translate/core';
import { SideBarService } from './services/side-bar-service';
import { lastValueFrom } from 'rxjs';
import { AppInsightsService } from './services/app-insights-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({
          transform: 'translateX(-100%)',
        }),
        animate('250ms', style({ transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        style({
          transform: 'translateX(0)',
        }),
        animate('250ms', style({ transform: 'translateX(-100%)' })),
      ]),
    ]),
  ],
})
export class AppComponent implements OnInit {
  showSidebar = false;
  constructor(
    private translateService: TranslateService,
    private sideBarService: SideBarService,
    private msalService: MsalService,
    private appInsightsService: AppInsightsService
  ) {
    this.sideBarService.toggleObservable$.subscribe((toggled) => {
      this.showSidebar = toggled;
    });
    // Add languages
    this.translateService.addLangs(['en-US']);

    // Use a language
    this.translateService.setDefaultLang('en-US');
    this.translateService.use('en-US');
  }
  async ngOnInit(): Promise<void> {
    //I assumed microsoft would take care of this but I guess not...
    const authCallback = await lastValueFrom(
      this.msalService.handleRedirectObservable()
    );

    let activeAccount = this.msalService.instance.getActiveAccount();

    if (authCallback?.account)
      this.msalService.instance.setActiveAccount(authCallback.account);
    else {
      const accounts = this.msalService.instance.getAllAccounts();

      if (!activeAccount && accounts.length > 0) {
        activeAccount = accounts[0];
        this.msalService.instance.setActiveAccount(activeAccount);
      }
    }

    if (activeAccount) {
      this.appInsightsService.setAuthenticatedUser(
        activeAccount.username,
        activeAccount.tenantId
      );
    }
  }

  toggleSidebar(value: boolean) {
    this.showSidebar = value;
  }
}
