import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import moment from 'moment-timezone';
import { EscalationDetail } from 'src/app/models/escalation-detail';
import { EscalationContact } from 'src/app/models/escalation-contact';
import { ContactType } from 'src/app/models/contact-type';
import { SupportType } from 'src/app/models/support-type';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { Tenant } from 'src/app/models/tenant';
import { Align } from '@progress/kendo-angular-popup';

@Component({
  selector: 'app-escalation-matrix',
  templateUrl: './escalation-matrix.component.html',
  styleUrls: ['./escalation-matrix.component.scss'],
})
export class EscalationMatrixComponent implements OnInit, OnChanges {
  registrationCode!: string;
  formGroup!: FormGroup;
  isError: boolean = false;
  error: string = '';
  success: string = '';
  timezones: any[] = [];
  @Input() contactTypes: ContactType[] = [];
  @Input() supportTypes: SupportType[] = [];
  @Input() disabled = true;
  @Input() escalationContacts: EscalationContact[] = [];
  @Input() escalationDetail: EscalationDetail = <EscalationDetail>{};
  @Input() tenant: Tenant = <Tenant>{};
  @Output() onSubmit = new EventEmitter();
  readonly PHONE_REGEX = /^([1-9]\d{0,2}[\d+() -]+)?$/;
  readonly NAME_REGEX =
    /^(?=.{1,50}$)((?!.*[-']{2})[-']*)[a-zA-Z]+(?:[-']*[a-zA-Z\s]*)*$/;
  selectedContactTypes: ContactType[] = [];
  @ViewChild('passphrase') public passphrase!: TextBoxComponent;
  showPasswordTip = false;
  @ViewChild('anchor', { read: ElementRef }) public anchor!: ElementRef;
  @ViewChild('popup', { read: ElementRef }) public popup!: ElementRef;
  public popupAlign: Align = { horizontal: 'left', vertical: 'top' };
  public anchorAlign: Align = { horizontal: 'right', vertical: 'center' };
  constructor(private formBuilder: FormBuilder) {}

  async ngOnInit(): Promise<void> {
    this.getTimezones();
    const guessedTimezone = this.timezones.find(
      (tz) => tz.value === moment.tz.guess()
    );

    const inHoursTimezone = this.timezones.find(
      (tz) => tz.offset === this.escalationDetail.in_hours_utc_offset
    );

    this.formGroup = this.formBuilder.group({});

    this.formGroup.addControl(
      'passphrase',
      new FormControl(this.escalationDetail.pass_phrase, [
        Validators.required,
        Validators.maxLength(256),
      ])
    );

    this.formGroup.addControl(
      'inHoursTimezone',
      new FormControl(inHoursTimezone ?? guessedTimezone, [Validators.required])
    );

    this.formGroup.addControl(
      'inHoursStartTime',
      new FormControl(
        this.getDateFromTimeParts(this.escalationDetail.in_hours_start_time),
        [Validators.required]
      )
    );

    this.formGroup.addControl(
      'inHoursEndTime',
      new FormControl(
        this.getDateFromTimeParts(this.escalationDetail.in_hours_end_time),
        [Validators.required]
      )
    );

    this.formGroup.addValidators(this.startTimeBeforeEnd('inHours'));

    const primaryContactType = this.contactTypes[0];
    if (!this.disabled && primaryContactType) {
      this.selectedContactTypes.push(primaryContactType);
    } else {
      this.selectedContactTypes = this.contactTypes;
    }

    for (let contactType of this.selectedContactTypes) {
      for (let supportType of this.supportTypes) {
        const contact = this.escalationContacts.find(
          (c) =>
            c.contact_type_id === contactType.id &&
            c.support_type_id === supportType.id
        );

        this.formGroup.addControl(
          contactType.name + '_' + supportType.name + '_' + 'email',
          new FormControl(contact?.email, [
            Validators.required,
            Validators.email,
          ])
        );
        this.formGroup.addControl(
          contactType.name + '_' + supportType.name + '_' + 'first_name',
          new FormControl(contact?.first_name, [
            Validators.required,
            Validators.pattern(this.NAME_REGEX),
          ])
        );
        this.formGroup.addControl(
          contactType.name + '_' + supportType.name + '_' + 'last_name',
          new FormControl(contact?.last_name, [
            Validators.required,
            Validators.pattern(this.NAME_REGEX),
          ])
        );
        this.formGroup.addControl(
          contactType.name + '_' + supportType.name + '_' + 'phone',
          new FormControl(contact?.phone, [
            Validators.required,
            Validators.pattern(this.PHONE_REGEX),
            Validators.maxLength(31), //this is technically not required, but should not be possible to have a phone number longer than this
          ])
        );
      }
    }
    if (this.disabled) {
      this.formGroup.disable();
    }
  }

  async submitForm() {
    if (this.formGroup.valid) {
      this.escalationDetail.pass_phrase = this.passphrase.value;
      this.escalationDetail.in_hours_start_time = moment(
        this.formGroup.value['inHoursStartTime']
      ).format('HH:mm');
      this.escalationDetail.in_hours_end_time = moment(
        this.formGroup.value['inHoursEndTime']
      ).format('HH:mm');
      this.escalationDetail.in_hours_utc_offset =
        this.formGroup.value['inHoursTimezone'].offset;

      for (const supportType of this.supportTypes) {
        for (const contactType of this.selectedContactTypes) {
          const firstName =
            this.formGroup.value[
              contactType.name + '_' + supportType.name + '_first_name'
            ];
          const lastName =
            this.formGroup.value[
              contactType.name + '_' + supportType.name + '_last_name'
            ];
          const phone: string =
            this.formGroup.value[
              contactType.name + '_' + supportType.name + '_phone'
            ];

          const email =
            this.formGroup.value[
              contactType.name + '_' + supportType.name + '_email'
            ];

          if ((firstName && lastName) || phone || email) {
            let escalationContact = this.escalationContacts.find(
              (ec) =>
                ec.contact_type_id === contactType.id &&
                ec.support_type_id === supportType.id
            );
            if (escalationContact) {
              escalationContact.first_name = firstName;
              escalationContact.last_name = lastName;
              escalationContact.phone = phone;
              escalationContact.email = email;
            } else {
              this.escalationContacts.push(<EscalationContact>{
                contact_type_id: contactType.id,
                support_type_id: supportType.id,
                first_name: firstName,
                last_name: lastName,
                phone: phone,
                email: email,
              });
            }
          }
        }
      }
      this.onSubmit.emit();
    } else {
      this.formGroup.markAllAsTouched();
    }
  }

  getTimezones() {
    let names = moment.tz.names();
    let offsetTmz = [];
    for (let name of names) {
      let currentDate = moment.tz(name);
      offsetTmz.push({
        display: `(UTC${currentDate.format('Z')}) ${name.replace('_', ' ')}`,
        value: name,
        offset: currentDate.format('Z'),
        offsetMinutes: currentDate.utcOffset(),
      });
    }
    this.timezones = offsetTmz.sort((a, b) =>
      a.offsetMinutes < b.offsetMinutes ? -1 : 0
    );
  }

  startTimeBeforeEnd(group: string) {
    return (): { [key: string]: any } => {
      let f = this.formGroup.controls[group + 'StartTime'];
      let t = this.formGroup.controls[group + 'EndTime'];
      if (!f?.value || !t?.value) return {};
      if (f?.value >= t?.value) {
        return {
          dates: group,
        };
      }
      return {};
    };
  }

  getDateFromTimeParts(timeString: string) {
    if (timeString) {
      const timeParts = timeString.split(':');
      return new Date(
        new Date().setHours(Number(timeParts[0]), Number(timeParts[1]))
      );
    }
    return null;
  }

  addContact() {
    const nextType = this.contactTypes[this.selectedContactTypes.length];
    if (nextType) {
      this.selectedContactTypes.push(nextType);
      for (let supportType of this.supportTypes) {
        this.formGroup.addControl(
          nextType.name + '_' + supportType.name + '_' + 'email',
          new FormControl('', [Validators.required, Validators.email])
        );
        this.formGroup.addControl(
          nextType.name + '_' + supportType.name + '_' + 'first_name',
          new FormControl('', [Validators.required])
        );
        this.formGroup.addControl(
          nextType.name + '_' + supportType.name + '_' + 'last_name',
          new FormControl('', [Validators.required])
        );
        this.formGroup.addControl(
          nextType.name + '_' + supportType.name + '_' + 'phone',
          new FormControl('', [
            Validators.required,
            Validators.pattern(this.PHONE_REGEX),
          ])
        );
      }
    }
  }

  removeContact() {
    const lastType = this.selectedContactTypes.pop();
    if (lastType) {
      for (let supportType of this.supportTypes) {
        this.formGroup.removeControl(
          lastType.name + '_' + supportType.name + '_' + 'email'
        );
        this.formGroup.removeControl(
          lastType.name + '_' + supportType.name + '_' + 'first_name'
        );
        this.formGroup.removeControl(
          lastType.name + '_' + supportType.name + '_' + 'last_name'
        );
        this.formGroup.removeControl(
          lastType.name + '_' + supportType.name + '_' + 'phone'
        );
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled?.currentValue === true) {
      this.formGroup?.disable();
    } else {
      this.formGroup?.enable();
    }
  }

  public mouseEnter(event: Event) {
    if (this.contains(event.target)) {
      this.showPasswordTip = true;
    }
  }

  public mouseLeave(event: Event) {
    if (this.contains(event.target)) {
      this.showPasswordTip = false;
    }
  }

  private contains(target: EventTarget | null): boolean {
    return (
      this.anchor.nativeElement.contains(target) ||
      (this.popup ? this.popup.nativeElement.contains(target) : false)
    );
  }
}
