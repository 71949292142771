import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReportDefinition } from 'src/app/models/report-definition';
import { ReportDefinitionService } from 'src/app/services/report-definition-service';

@Component({
  selector: 'app-report-edit',
  templateUrl: './report-edit.component.html',
  styleUrls: ['./report-edit.component.scss']
})
export class ReportEditComponent implements OnInit {
  isLoading: boolean = false;
  error: string = '';
  formGroup!: FormGroup;
  id: string = '';
  name: string = '';
  filename: string = '';
  fileBase64: string = '';
  definition!: ReportDefinition;

  constructor(private reportDefinitionService: ReportDefinitionService, private translateService: TranslateService, private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router) {
   this.createForm(); 
  }

  async ngOnInit(): Promise<void> {
    this.id = this.route.snapshot.paramMap.get('id')!;
    this.definition = await this.reportDefinitionService.fetch(this.id);

    this.formGroup.patchValue({
      name: this.definition.name 
    });
  }

  async onSubmit(): Promise<void> {
    try {
      if (this.formGroup.valid) {
        this.isLoading = true;
        this.error = '';
  
        await this.reportDefinitionService.edit(
          this.id,
          this.formGroup.value.name,
          this.filename,
          this.fileBase64,
        );

        await this.router.navigateByUrl(
          `/admin/reports`
        );
    
      } else {
        this.formGroup.markAllAsTouched();
      }
    } catch (err: any) {
      console.error(err);
      if (err instanceof HttpErrorResponse) {
        const httpError = err as HttpErrorResponse;
        switch (httpError.error) {
          default: {
            if (typeof httpError.error === 'string')
              this.error = httpError.error;
            else
              this.error = this.translateService.instant(
                'Error.GenericServerError'
              );
          }
        }
      }
    } finally {
      this.isLoading = false;
    }
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      name: new FormControl(null, [Validators.required])
    });
  }

  onFileSelected(evt: any) {
    const file:File = evt.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = (event) => {
        const base64 = btoa(event!.target!.result!.toString())  
        this.updateFile(file.name, base64);
      };
    }
  }
 
  private updateFile(filename: string, base64: string) {
    this.filename = filename;
    this.fileBase64 = base64;
  }


}

