import { Component } from '@angular/core';

@Component({
  selector: 'app-end-user-agreement',
  templateUrl: './end-user-agreement.component.html',
  styleUrls: ['./end-user-agreement.component.scss']
})
export class EndUserAgreementComponent {

}
