<div class="content">
  <h2>{{ "ViewRegistration.Header" | translate }}</h2>
  <div *ngIf="(loadingService.loadingObservable$ | async) === false">
    <div class="row">
      <div class="form-group">
        <label class="control-label">
          {{ "ViewRegistration.Email" | translate }}
        </label>
        <div>{{ registration.email }}</div>
      </div>
    </div>
    <div class="row">
      <div class="form-group">
        <label class="control-label">
          {{ "ViewRegistration.Company" | translate }}
        </label>
        <div [title]="registration.tenant.code">
          {{ registration.tenant.company }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group">
        <label class="control-label">
          {{ "ViewRegistration.ServiceType" | translate }}
        </label>
        <div>
          {{ registration.service_type || "Standard" }}
        </div>
      </div>
    </div>
    <div class="row" *ngIf="registration?.is_complete">
      <div class="form-group">
        <label class="control-label">
          {{ "ViewRegistration.TenantId" | translate }}
        </label>
        <div class="d-flex align-items-center">
          {{ registration.tenant.tenant_id }}
          <button
            class="btn btn-light btn-sm material-icons primary-color ml-1"
            title="Copy to clipboard"
            (click)="copyTenantId()"
          >
            content_copy
          </button>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="registration?.is_complete === false">
      <div class="form-group">
        <label class="control-label">
          {{ "ViewRegistration.Expiration" | translate }}
        </label>
        <div>{{ registration.expiration | date : "medium" }}</div>
      </div>
    </div>
    <div class="row">
      <div class="form-group">
        <label class="control-label">
          {{ "ViewRegistration.Status" | translate }}
        </label>
        <div *ngIf="registration.is_complete" class="d-flex align-items-center">
          <span class="material-icons secondary-color status">
            check_circle
          </span>
          {{ "ViewRegistration.StatusComplete" | translate }}
        </div>
        <div
          *ngIf="
            !registration.is_complete && isExpired(registration.expiration)
          "
          class="d-flex align-items-center"
        >
          <span class="material-icons text-warning status"> error </span>
          {{ "ViewRegistration.StatusExpired" | translate }}
        </div>
        <div
          *ngIf="
            !registration.is_complete && !isExpired(registration.expiration)
          "
          class="d-flex align-items-center"
        >
          <span class="material-icons primary-color status">
            mark_email_read
          </span>
          {{ "ViewRegistration.StatusPending" | translate }}
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group">
        <label class="control-label">
          {{ "ViewRegistration.Trial" | translate }}
        </label>
        <div class="d-flex align-items-center">
          <kendo-switch
            size="small"
            [disabled]="
              registration.is_complete == false &&
              isExpired(registration.expiration)
            "
            onLabel=" "
            [(ngModel)]="registration.is_trial"
            (valueChange)="toggleTrial()"
          ></kendo-switch>
          <div
            class="d-flex align-items-center justify-content-between"
            *ngIf="registration.is_trial"
          >
            <div
              class="d-flex align-items-center"
              *ngIf="
                registration.is_complete &&
                !isExpired(registration.trial_expiration)
              "
            >
              <span class="material-icons secondary-color mx-1">
                hourglass_top
              </span>
              <span class="text-nowrap">
                {{ "ViewRegistration.TrialActive" | translate }}
                {{ registration.trial_expiration | date : "medium" }} local time
                ({{ registration.trial_expiration | date : "medium" : "+0000" }}
                UTC)
              </span>
            </div>
            <div
              *ngIf="
                registration.trial_expiration &&
                isExpired(registration.trial_expiration)
              "
              class="d-flex align-items-center"
            >
              <span class="material-icons text-danger mx-1">
                hourglass_bottom
              </span>
              <span class="text-nowrap">
                {{ "ViewRegistration.TrialExpired" | translate }}
                {{ registration.trial_expiration | date : "medium" }} local time
                ({{ registration.trial_expiration | date : "medium" : "+0000" }}
                UTC)
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex align-items-center"
        *ngIf="registration.is_trial && registration.is_complete"
      >
        <label>
          {{ "ViewRegistration.ExtendTrial" | translate }}
        </label>
        <kendo-dropdownlist
          class="mx-2"
          style="max-width: 100px"
          [data]="trialOptions"
          textField="text"
          valueField="value"
          [(ngModel)]="selectedTrialOption"
        >
        </kendo-dropdownlist>
        <button
          class="btn btn-light btn-sm material-icons primary-color"
          title="Extend trial"
          (click)="extendTrial()"
        >
          more_time
        </button>
      </div>
    </div>

    <kendo-tabstrip class="pt-3">
      <kendo-tabstrip-tab
        title="{{ 'ViewRegistration.EscalationMatrix' | translate }}"
        [selected]="registration.is_complete"
        *ngIf="registration.is_complete && escalationDetail"
      >
        <ng-template kendoTabContent>
          <app-escalation-matrix
            [escalationDetail]="escalationDetail"
            [escalationContacts]="escalationContacts"
            [contactTypes]="contactTypes"
            [supportTypes]="supportTypes"
          ></app-escalation-matrix>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab
        [selected]="!registration.is_complete"
        title="{{ 'ViewRegistration.LogSources' | translate }}"
      >
        <ng-template kendoTabContent>
          <div>
            <div class="row">
              <div class="form-group col-6">
                <label class="control-label">
                  {{ "ViewRegistration.LogSourceType" | translate }}
                </label>
              </div>
              <div class="form-group col-2">
                <label class="control-label">
                  {{ "ViewRegistration.Interval" | translate }}
                </label>
              </div>
              <div class="form-group col-2 d-flex justify-content-end">
                <label class="control-label">
                  {{ "ViewRegistration.LastRetrieved" | translate }}
                </label>
              </div>
              <div class="form-group col-2 d-flex justify-content-end">
                <label class="control-label">
                  {{ "ViewRegistration.Enabled" | translate }}
                </label>
              </div>
            </div>
            <div
              class="row pb-2"
              *ngFor="let lsw of logSourceWrappers; let i = index"
            >
              <div class="col-6 text-truncate d-flex align-items-center">
                <span
                  class="material-icons primary-color hover-hand mr-1"
                  title="{{ 'PendingTip' | translate }}"
                  *ngIf="
                    !registration.is_complete &&
                    !isExpired(registration.expiration)
                  "
                >
                  mark_email_read
                </span>
                <span
                  [ngClass]="{
                    disabled:
                      registration.is_trial &&
                      isExpired(registration.trial_expiration)
                  }"
                  class="material-icons text-warning hover-hand mr-1"
                  [title]="
                    registration.is_trial &&
                    isExpired(registration.trial_expiration)
                      ? ('TrialExpiredTip' | translate) +
                        ('UnhealthyTip' | translate)
                      : ('UnhealthyTip' | translate)
                  "
                  *ngIf="
                    !registration.is_complete &&
                    isExpired(registration.expiration)
                  "
                >
                  error
                </span>
                <span
                  [ngClass]="{
                    disabled:
                      registration.is_trial &&
                      isExpired(registration.trial_expiration)
                  }"
                  class="material-icons secondary-color hover-hand mr-1"
                  [title]="
                    registration.is_trial &&
                    isExpired(registration.trial_expiration)
                      ? ('TrialExpiredTip' | translate) +
                        ('ActiveTip' | translate)
                      : ('ActiveTip' | translate)
                  "
                  *ngIf="
                    registration.is_complete && lsw?.log_source?.is_healthy
                  "
                >
                  check_circle
                </span>
                <span
                  [ngClass]="{
                    disabled:
                      registration.is_trial &&
                      isExpired(registration.trial_expiration)
                  }"
                  class="material-icons text-warning hover-hand mr-1"
                  [title]="
                    registration.is_trial &&
                    isExpired(registration.trial_expiration)
                      ? ('TrialExpiredTip' | translate) +
                        ('UnhealthyTip' | translate)
                      : ('UnhealthyTip' | translate)
                  "
                  *ngIf="
                    registration.is_complete && !lsw?.log_source?.is_healthy
                  "
                >
                  error
                </span>
                {{ lsw?.log_source_type?.name }}
              </div>
              <div class="col-2">
                {{ lsw?.log_source?.retrieval_interval_minutes }}
              </div>
              <div class="col-2 text-truncate text-end">
                {{ lsw?.log_source?.last_retrieved | date : "medium" }}
              </div>
              <div class="col-2 d-flex justify-content-end">
                <kendo-switch
                  [disabled]="
                    !registration.is_complete ||
                    (registration.is_trial &&
                      isExpired(registration.trial_expiration))
                  "
                  size="small"
                  onLabel=" "
                  [(ngModel)]="lsw.log_source.is_enabled"
                  (valueChange)="toggleLogSource(lsw.log_source)"
                ></kendo-switch>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
    <app-status
      [success]="success"
      [error]="error"
      [isLoading]="loadingService.loadingObservable$ | async"
    ></app-status>
  </div>
</div>
