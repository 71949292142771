<div class="container-fluid header menu">
  <div class="d-flex justify-content-between">
    <div>
      <a (click)="toggle()">
        <img src="../../../assets/responder.svg" class="logo-img" />
      </a>
    </div>
    <div
      *ngIf="isLoggedIn()"
      class="d-flex justify-content-center align-items-center"
    >
      <div #target class="d-flex align-items-center hover-hand">
        <div class="d-flex flex-column align-items-end pr-1 name">
          <span class="full-name">
            {{ getActiveUsersName() }}
          </span>
          <span class="gray" style="font-size: 14px">{{
            getActiveUsername()
          }}</span>
        </div>
        <span class="material-icons primary-color" style="font-size: 42px"
          >account_circle</span
        >
        <span class="material-icons primary-color" style="font-size: 24px"
          >arrow_drop_down</span
        >
      </div>
      <kendo-contextmenu
        [target]="target"
        showOn="click"
        [hoverDelay]="1"
        [alignToAnchor]="true"
        [anchorAlign]="{ horizontal: 'right', vertical: 'bottom' }"
        [popupAlign]="{ horizontal: 'right', vertical: 'top' }"
      >
        <kendo-menu-item>
          <ng-template kendoMenuItemTemplate>
            <div class="d-flex align-items-center" (click)="switchAccount()">
              <span class="material-icons primary-color mr-2"
                >switch_account</span
              >
              <span>Switch Account</span>
            </div>
          </ng-template>
        </kendo-menu-item>
        <kendo-menu-item>
          <ng-template kendoMenuItemTemplate>
            <div class="d-flex align-items-center" (click)="logout()">
              <span title="Logout" class="material-icons mr-2 primary-color"
                >logout</span
              >
              Log out
            </div>
          </ng-template>
        </kendo-menu-item>
      </kendo-contextmenu>
    </div>
  </div>
</div>
