import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnInit {
  @Input() success = '';
  @Input() error = '';
  @Input() isLoading: boolean | null = false;
  constructor() {}

  ngOnInit(): void {}

  get isError() {
    return !this.isLoading && !!this.error;
  }
  get isSuccess() {
    return !this.isLoading && !!this.success && !this.isError;
  }
}
