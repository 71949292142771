import { HttpClient } from '@angular/common/http';
import { Responder365AppConfig } from './app-config-service';

export abstract class BaseApiService {
  protected readonly baseServiceUrl: string;
  constructor(
    protected config: Responder365AppConfig,
    protected _http: HttpClient,
    protected serviceName: string
  ) {
    this.baseServiceUrl = `${config.apiUrl}/api/${serviceName}`;
  }

  get headers() {
    return {
      'Content-Type': 'application/json',
    };
  }
}
