import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateAdminRegistrationComponent } from 'src/app/components/create-admin-registration/create-admin-registration.component';
import { CreateRegistrationComponent } from 'src/app/components/create-registration/create-registration.component';
import { SearchAdminRegistrationComponent } from 'src/app/components/search-admin-registration/search-admin-registration.component';
import { SearchRegistrationComponent } from 'src/app/components/search-registration/search-registration.component';
import { ViewRegistrationComponent } from 'src/app/components/view-registration/view-registration.component';
import { ReportManagementComponent } from '../../components/report-management/report-management.component';
import { ReportUploadComponent } from 'src/app/components/report-upload/report-upload.component';
import { ReportEditComponent } from 'src/app/components/report-edit/report-edit.component';
import { ReportViewComponent } from 'src/app/components/report-view/report-view.component';

const routes: Routes = [
  {
    path: 'register',
    component: CreateRegistrationComponent,
    pathMatch: 'full',
  },
  {
    path: 'register-admin',
    component: CreateAdminRegistrationComponent,
    pathMatch: 'full',
  },
  {
    path: 'registrations',
    component: SearchRegistrationComponent,
    pathMatch: 'full',
  },
  {
    path: 'administrators',
    component: SearchAdminRegistrationComponent,
    pathMatch: 'full',
  },
  {
    path: 'registration/:id',
    component: ViewRegistrationComponent,
    pathMatch: 'full',
  },
  {
    path: 'reports',
    component: ReportManagementComponent,
    pathMatch: 'full',
  },
  {
    path: 'reports/upload',
    component: ReportUploadComponent,
    pathMatch: 'full',
  },
  {
    path: 'reports/:id/edit',
    component: ReportEditComponent,
    pathMatch: 'full',
  },
  {
    path: 'reports/:id',
    component: ReportViewComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
