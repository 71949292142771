<div class="content">
  <h2>
    {{ "Dashboard.Heading" | translate }}
  </h2>
  <div
    *ngIf="
      !(loadingService.loadingObservable$ | async) &&
      logSourceWrappers.length > 0
    "
  >
    <div class="d-flex justify-content-between">
      <p>
        {{ "Dashboard.SubHeading" | translate }}
      </p>
      <div class="form-group"></div>
    </div>
    <div class="py-1">
      <kendo-grid
        #grid
        [data]="gridData"
        [loading]="loadingData"
        [resizable]="true"
        [sortable]="false"
      >
        <kendo-grid-column [title]="'Dashboard.Name' | translate">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.Name }}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [title]="'Dashboard.Description' | translate">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.Description }}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column>
          <ng-template
            kendoGridHeaderTemplate
            let-column
            let-columnIndex="columnIndex"
          >
            {{ "Dashboard.Logs" | translate }} ({{
              "Dashboard.LastRetrieved" | translate
            }})
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="mt-2">
              <ul
                *ngFor="
                  let item of getLogSourcesForPrerequisiteAppId(
                    dataItem.PrerequisiteAppId
                  )
                "
              >
                <li
                  class="d-flex justify-content-between"
                  [ngClass]="{
                    disabled:
                      item.registration.is_trial &&
                      isExpired(item.registration.trial_expiration)
                  }"
                >
                  <div class="d-flex align-items-start">
                    <span
                      [ngClass]="{
                        disabled:
                          item.registration.is_trial &&
                          isExpired(item.registration.trial_expiration)
                      }"
                      class="material-icons secondary-color hover-hand mr-1"
                      [title]="
                        item.registration.is_trial &&
                        isExpired(item.registration.trial_expiration)
                          ? ('TrialExpiredTip' | translate) +
                            ('ActiveTip' | translate)
                          : ('ActiveTip' | translate)
                      "
                      *ngIf="item?.log_source?.is_healthy"
                    >
                      check_circle
                    </span>
                    <span
                      [ngClass]="{
                        disabled:
                          item.registration.is_trial &&
                          isExpired(item.registration.trial_expiration),
                        spin:
                          !item.registration.is_trial ||
                          !isExpired(item.registration.trial_expiration)
                      }"
                      class="material-icons text-warning hover-hand mr-1"
                      [title]="
                        isExpired(item.registration.trial_expiration)
                          ? ('TrialExpiredTip' | translate) +
                            ('UnhealthyTip' | translate)
                          : ('UnhealthyTip' | translate)
                      "
                      *ngIf="!item?.log_source?.is_healthy"
                    >
                      change_circle
                    </span>
                    <span>{{ item?.log_source_type?.name }}</span>
                  </div>
                  <span>{{
                    item?.log_source?.last_retrieved | date : "medium"
                  }}</span>
                </li>
              </ul>
            </div>
          </ng-template>
        </kendo-grid-column></kendo-grid
      >
    </div>

    <div *ngIf="isAllHealthy === false && hasExpiredTrial === false">
      <p>
        {{ "Dashboard.UnhealthyMessage" | translate }}
        {{ "Dashboard.WaitMessage" | translate }}
      </p>
      <div
        *ngIf="
          isActivityFeedHealthy === false ||
          isDefenderCloudAppsHealthy === false ||
          isDefenderHealthy == false
        "
      >
        <p *ngIf="isAllHealthy === false">
          <b>{{ "Dashboard.AdditionalSteps" | translate }}</b>
        </p>
        <ul>
          <li *ngIf="isActivityFeedHealthy === false">
            <p>
              {{ "Dashboard.ActivityFeedUnhealthyMessage" | translate }}
              <a href="https://compliance.microsoft.com" target="_blank"
                >https://compliance.microsoft.com</a
              >
            </p>
          </li>
          <li *ngIf="isDefenderCloudAppsHealthy === false">
            <p>
              {{ "Dashboard.DefenderCloudAppsUnhealthyMessage" | translate }}
              <a href="https://portal.cloudappsecurity.com/" target="_blank"
                >https://portal.cloudappsecurity.com/</a
              >
            </p>
          </li>
          <li *ngIf="isDefenderHealthy === false">
            <p>
              {{ "Dashboard.Defender365UnhealthyMessage" | translate }}
              <a href="https://security.microsoft.com" target="_blank"
                >https://security.microsoft.com</a
              >
            </p>
          </li>
        </ul>
      </div>
      <div class="d-flex justify-content-between py-1">
        <b>{{ "Dashboard.ProvideConsent" | translate }}</b>
        <div>
          <button class="btn btn-secondary" (click)="adminConsent()">
            {{ "Dashboard.Consent" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <app-status
    [success]="success"
    [error]="error"
    [isLoading]="loadingService.loadingObservable$ | async"
  ></app-status>
</div>
