import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SupportType } from '../models/support-type';
import { AutoGeneratedApiService } from './autogenerated-api-service';
import { Responder365AppConfig } from './app-config-service';

@Injectable({
  providedIn: 'root',
})
export class SupportTypeService extends AutoGeneratedApiService<SupportType> {
  constructor(config: Responder365AppConfig, http: HttpClient) {
    super(config, http, 'support-type');
  }
}
