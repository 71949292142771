<div class="content">
    <h2>{{ "UploadReport.Header" | translate }}</h2>
    <form
      class="form"
      [formGroup]="formGroup"
      *ngIf="formGroup"
    >
      <div class="row">
        <div class="form-group">
          <label class="control-label">
            {{ "UploadReport.Name" | translate }}
          </label>
          <input
            type="text"
            class="form-control"
            formControlName="name"
          />
          <div
            *ngIf="
              (formGroup.get('name')?.invalid &&
                formGroup.get('name')?.touched) ||
              formGroup.get('name')?.dirty
            "
          >
            <small
              class="text-danger"
              *ngIf="formGroup.get('name')?.errors?.required"
              >{{ "UploadReport.NameRequired" | translate }}</small
            >
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-group">
          <label class="control-label">
            {{ "UploadReport.File" | translate }}
          </label>

          <input type="file" class="form-control"
          (change)="onFileSelected($event)" #fileUpload required>

          <div *ngIf="showFileValidationMessage">
            <small class="text-danger">
                {{ "UploadReport.FileRequired" | translate }}
            </small>
          </div>

        </div>
      </div>

      <div class="py-2 d-flex justify-content-end">
        <button
          class="btn btn-primary"
          (click)="onSubmit()"
          type="button"
          [disabled]="isLoading"
        >
          <span class="spinner-border spinner-border-sm mr-1" *ngIf="isLoading"></span>
          Submit
        </button>
      </div>
    </form>
    <app-status
      [error]="error"
      [isLoading]="isLoading"
    ></app-status>
  </div>
  