import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SideBarService {
  private toggleSubject$ = new BehaviorSubject(false);
  private toggle = false;
  toggleObservable$ = this.toggleSubject$.asObservable();

  async toggleSideBar() {
    this.toggle = !this.toggle;
    this.toggleSubject$.next(this.toggle);
  }
}
