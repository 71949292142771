import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injector } from '@angular/core';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Responder365AppConfig } from './app-config-service';
import { EndSessionRequest, RedirectRequest } from '@azure/msal-browser';
import { Router } from '@angular/router';
import { LoadingService } from './loading-service';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
  apiUrl: any;
  get authService(): MsalService {
    // Need to use injector like a service provider to prevent circular dep chain of
    // HttpClient -> HttpInterceptor -> SessionService -> HttpClient
    return this.injector.get(MsalService);
  }

  constructor(
    private injector: Injector,
    private config: Responder365AppConfig,
    private router: Router
  ) {
    this.apiUrl = config.apiUrl;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.startsWith(this.apiUrl)) {
      return next.handle(req).pipe(
        map((event: HttpEvent<any>) => this.handleUnauthorizedResponse(event)),
        catchError((error: HttpErrorResponse) => {
          if (error instanceof HttpErrorResponse) {
            switch (error.status) {
              case 401:
                this.router.navigateByUrl('/error', {
                  state: { error: 'invalid-user' },
                });
                break;
              case 403:
                this.authService.instance.loginRedirect(<RedirectRequest>{
                  scopes: [
                    `api://${this.config?.authConfig?.clientId}/User.Api.Access`,
                  ],
                  prompt: 'select_account',
                });
                break;
            }
          }
          return throwError(() => error);
        })
      );
    }
    return next.handle(req);
  }

  private handleUnauthorizedResponse(event: HttpEvent<any>) {
    if (event instanceof HttpResponse) {
      if (event.status === 200 && event.body?.status === 401) {
        this.authService.instance.logout(<EndSessionRequest>{
          account: this.authService.instance.getActiveAccount(),
        });
      }
    }
    return event;
  }
}
