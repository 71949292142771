import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, ReplaySubject } from 'rxjs';
import { Session } from '../models/session';
import { Responder365AppConfig } from './app-config-service';
import { BaseApiService } from './base-api-service';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root',
})
export class SessionService extends BaseApiService {
  private sessionSubject$ = new ReplaySubject<Session>(1);
  sessionObservable$ = this.sessionSubject$.asObservable();
  constructor(
    config: Responder365AppConfig,
    http: HttpClient,
    msalService: MsalService
  ) {
    super(config, http, 'session');
  }

  async fetch() {
    let session = await lastValueFrom(
      this._http.get<Session>(this.baseServiceUrl + '/user')
    );
    this.sessionSubject$.next(session);
    return session;
  }

  async end() {
    await lastValueFrom(this._http.get(this.baseServiceUrl + '/end'));
    this.sessionSubject$.next(<Session>{});
  }
}
