import { HttpClient } from '@angular/common/http';
import { PageResponse, PageRequest } from '../models/entity-base';
import { Responder365AppConfig } from './app-config-service';
import { BaseApiService } from './base-api-service';
import { lastValueFrom } from 'rxjs';

/* This is intended to be used with the standard autogenerated API endpoints */
export abstract class AutoGeneratedApiService<T> extends BaseApiService {
  constructor(
    protected appConfigService: Responder365AppConfig,
    protected _http: HttpClient,
    protected serviceName: string
  ) {
    super(appConfigService, _http, serviceName);
  }

  async fetch(id: string): Promise<T> {
    const url = `${this.baseServiceUrl}/fetch`;
    return lastValueFrom(this._http.post<T>(url, id));
  }

  async fetchMultiple(ids: string[]): Promise<T[]> {
    const url = `${this.baseServiceUrl}/fetch-multiple`;
    return lastValueFrom(this._http.post<T[]>(url, ids));
  }

  async delete(id: string): Promise<unknown> {
    const url = `${this.baseServiceUrl}/${id}`;
    return lastValueFrom(this._http.delete(url));
  }

  async deleteMultiple(ids: string[]): Promise<unknown> {
    const url = `${this.baseServiceUrl}/delete-for-ids`;
    return lastValueFrom(this._http.delete(url, { body: ids }));
  }

  async list(): Promise<T[]> {
    return lastValueFrom(this._http.get<T[]>(this.baseServiceUrl));
  }

  async save(model: T): Promise<string> {
    return lastValueFrom(this._http.post<string>(this.baseServiceUrl, model));
  }

  async saveMultiple(model: T[]): Promise<string[]> {
    return lastValueFrom(
      this._http.post<string[]>(`${this.baseServiceUrl}/multiple`, model)
    );
  }

  async page(
    orderBy: string,
    page: number,
    pageSize: number,
    where?: string
  ): Promise<PageResponse<T>> {
    const url = `${this.baseServiceUrl}/page`;
    const request = new PageRequest();
    request.order_by = orderBy;
    request.page_number = page;
    request.page_size = pageSize;
    request.where = where || '';
    return lastValueFrom(this._http.post<PageResponse<T>>(url, request));
  }

  async listForProperty(name: string, id: string): Promise<T[]> {
    const url = `${this.baseServiceUrl}/for/${name}`;

    return lastValueFrom(this._http.post<T[]>(url, id));
  }

  async listForMultipleProperty(
    pluralName: string,
    ids: string[]
  ): Promise<T[]> {
    const url = `${this.baseServiceUrl}/for/${pluralName}`;
    return lastValueFrom(this._http.post<T[]>(url, ids));
  }
}
