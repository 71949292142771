<div class="container d-flex justify-content-center dynamic-html">
  <div class="col-8 mt-5">
    <div class="text">
      <h1 style="text-align: center">
        <strong>KROLL RESPONDER FOR OFFICE 365 PORTAL <br /></strong
        ><strong>END USER AGREEMENT – UNITED STATES</strong>
      </h1>
      <h2 style="text-align: center">
        <strong>PLEASE READ THIS END USER AGREEMENT CAREFULLY</strong>
      </h2>
      <h3>&nbsp;</h3>
      <h3><strong>1. WHO WE ARE AND WHAT THIS DOCUMENT DOES</strong></h3>
      <p>
        We are Kroll Information Assurance, LLC (“<strong>Kroll</strong>,”
        “<strong>we</strong>”, “<strong>us</strong>”, or “<strong>our</strong>”
        as applicable), of 55 E. 52<sup>nd</sup> Street, 17<sup>th</sup> floor,
        New York, NY 10055, Attn. General Counsel, and hereby provide a limited
        license to you an End User data owner (““<strong>End User</strong>”,
        “<strong>you</strong>” or “<strong>your</strong>” as applicable) to
        perform necessary additions or modifications to Personally Identifiable
        Information or Protected Health Information required to provide identity
        theft and breach notification services to affected individuals via the
        Kroll Responder for Office 365 Portal (the “<strong>Portal</strong>”)
        provided by Kroll pursuant to the Portal Terms of Use (Kroll and the End
        User are each a “<strong>Party</strong>” and together, the
        “<strong>Parties</strong>”) to use:
      </p>
      <ul>
        <li>
          Kroll’s portal software (“<strong>Portal</strong>”) and any updates or
          supplements to it
        </li>
        <li>
          Related online or electronic documentation found in the Portal
          (“<strong>Documentation</strong>”)
        </li>
        <li>
          The services we provide to you through the Portal
          (“<strong>Services</strong>”)
        </li>
      </ul>
      <p>
        as permitted by and subject to the terms and conditions of this Portal
        End User Agreement (“<strong>Agreement</strong>”), which forms a legally
        binding contract between End User and Kroll. Together, each of the items
        bullet pointed above are collectively referred to within this Agreement
        as the “<strong>Platform</strong>”.&nbsp;
      </p>
      <p>
        If you are an individual agreeing to this Agreement, you agree that the
        terms and conditions of this Agreement apply to you as an End User;
        additionally, if you are agreeing to this Agreement on behalf of a
        company, organization, other legal entity or legal person, you represent
        that you have the authority to bind that entity and its affiliates,
        related entities, and its respective employees, agents, delegates,
        representatives and any other individuals that access and use the Portal
        and receive the Services (“<strong>Authorized End Users</strong>”), to
        the terms and conditions of this Agreement. If you do not agree with the
        terms and conditions in this Agreement, or if you do not have such
        authority, Authorized End Users will not be permitted to access, receive
        and use the Portal and Services
      </p>
      <p><strong>Why the End User should read this Agreement</strong></p>
      <p>
        These terms tell you who we are, how we will provide Services to you,
        how you and we may change or end the Agreement, what to do if there is a
        problem and other important information. If you think that there is a
        mistake in these terms that require any changes, please contact us to
        discuss.
      </p>
      <p>
        <strong
          >The Services referred to herein mean any or all of the following as
          appropriate:</strong
        ><br /><br /><strong>Third-party Breach Management:</strong> With input
        from End User, or End User and/or Law Firm, Kroll will develop and
        operate a customer-facing website portal that enables customers to view
        their list of impacted data, opt-in to End User notifying individuals
        and/or regulators on customers’ behalf, adjust contact information and
        receive reporting regarding notified individuals.
      </p>
      <p>
        <strong
          >Multi-Level Notification for Third-Party Breach Management:</strong
        >
        For End Users that require notification to their customers, as well as
        downstream data owners of the End User’s customers, additional fees will
        be required to facilitate this multi-level notification in developing
        and operating the customer-facing website portal.
      </p>
      <p>
        <strong
          >Custom Third-Party Breach Technology &amp; Reporting
          Development:</strong
        >
        Requests regarding modifications to the user flow and/or functionality
        of the customer-facing website portal outside of current, or development
        of custom third-party reports will incur additional fees. This service
        is an hourly fee.&nbsp; Upon receipt of a request, Kroll can provide an
        estimate of delivery time and cost.
      </p>
      <p>
        If you would like a more detailed overview of the Services we provide,
        please contact us using our
        <a href="/contact-us" target="_blank" rel="noopener">Contact Us</a>
        page.
      </p>
      <h3><strong>2. OUR AGREEMENT WITH YOU</strong></h3>
      <p>
        <strong>How Kroll will provide End User with the Services. </strong>
      </p>
      <p>
        We will provide you with access to Services through the use of our
        Platform.&nbsp; You must agree to the
        <strong>Portal Terms of Use</strong> in order to access our Services.
        The Portal Terms of Use are expressly incorporated into the terms of
        this Agreement between us. Failure to agree to the Portal Terms of Use
        may result in our refusing to allow you access or withdrawing or
        suspending your access to the Services.
      </p>
      <p>
        <strong>If Kroll cannot provide End User with the Services.</strong
        ><strong>&nbsp;</strong>
      </p>
      <p>
        It may not be possible to provide Services for any reason, including
        because of unexpected limits on our resources that we could not
        reasonably plan for, because we are unable to meet a performance
        deadline or unable to meet a specification you have instructed us to
        meet or for technical or security reasons.
      </p>
      <p>
        <strong
          >Kroll only offers our Services within the United States.</strong
        >
      </p>
      <p>
        Our Services are solely for use by US residents (or permanent residents)
        in the United States of America (“US”). We do not provide our Services
        outside of the United States.
      </p>
      <h3>3. THERE ARE OTHER TERMS THAT APPLY TO YOU</h3>
      <p>
        This Agreement refers to the following additional terms that also apply
        to your use of our Portal:
      </p>
      <ul>
        <li>
          Our&nbsp;<a
            href="https://www.kroll.com/en/privacy-policy"
            target="_blank"
            rel="noopener"
            >Privacy Policy</a
          >
        </li>
        <li>
          Our
          <a href="/terms-of-use" target="_blank" rel="noopener"
            >Terms of Use</a
          >
        </li>
        <li>
          Our
          <a
            href="https://www.kroll.com/en/cookies-policy"
            target="_blank"
            rel="noopener"
            >Cookies Policy</a
          >,&nbsp;which sets our information about the cookies on our site
        </li>
      </ul>
      <h3>
        <strong>4. KROLL’S SERVICES</strong><br /><br /><strong
          >Services may vary slightly from their description</strong
        >
      </h3>
      <p>
        The description of the Services is for illustrative purposes only.
        Although we have made every effort to describe them, their scope and
        their purpose accurately, we cannot guarantee that the description
        accurately reflects the Services or that you will receive the Services
        exactly as described. We do not make any representation, warranty or
        guarantees, express, implied, or otherwise that the Services are
        suitable for your specific needs.
      </p>
      <p>
        <strong
          >Making sure the information the End User gives to Kroll is
          accurate.</strong
        >
      </p>
      <p>
        Because Kroll is offering the End User the Services based on the
        information (including personal data) the End User has given Kroll, the
        End User is responsible for ensuring that this information is correct,
        and the End User will continue to maintain it in an updated and accurate
        manner.
      </p>
      <h3><strong>5. KROLL’S RIGHT TO MAKE CHANGES TO THE SERVICES</strong></h3>
      <p><strong>Changes to the Services</strong></p>
      <p>Kroll may change the Services:</p>
      <ul>
        <li>
          To reflect changes in relevant laws and regulatory requirements; and
        </li>
        <li>
          To implement minor technical adjustments and improvements, for
          example, to address a security threat.
        </li>
      </ul>
      <p><strong>Update to the Portal and changes to the Services</strong></p>
      <p>
        From time to time Kroll may automatically update the Platform and change
        it to improve performance, enhance functionality, reflect changes to the
        operating system or address security issues.
      </p>
      <p><strong>6. PROVIDING THE SERVICES</strong></p>
      <p><strong>When Kroll will provide the Services</strong></p>
      <p>
        Kroll will provide End User with the Services as outlined in the service
        description until End User or Kroll end this Agreement as described in
        the Termination of Relationship Section.
      </p>
      <p>
        <strong>Kroll is not responsible for delays outside our control</strong>
      </p>
      <p>
        If Kroll’s supply of the Services to End User is delayed by an event
        outside our control, Kroll will contact End User as soon as possible to
        let you know and we will take steps to minimize the effect of the delay.
        Provided Kroll does this, Kroll will not be liable for delays caused by
        the event, but if there is a risk of substantial delay the End User may
        contact Kroll to end the Agreement.
      </p>
      <p>
        <strong
          >What will happen if the End User does not give required information
          to Kroll</strong
        >
      </p>
      <p>
        Kroll may need certain information from the End User so that we can
        supply the Services to you in full or to the best of our ability. For
        example, we do require you to provide personal data as directed on our
        Platform. If you do not give us this information within a reasonable
        time of our request, or if you give us incomplete or incorrect
        information, we may end the Agreement (and the Termination of
        Relationship Section will apply). Kroll will not be responsible for
        supplying the Services late or not supplying any part of them if this is
        caused by End User not providing the information Kroll needs.
      </p>
      <h3><strong>7. TERMINATION OF RELATIONSHIP</strong></h3>
      <p><strong>Termination by End User</strong></p>
      <p>
        If you want to terminate the Agreement, you may do so, for any reason,
        at any time, by requesting Kroll deactivate your account(s).&nbsp;
      </p>
      <p><strong>Termination by Kroll</strong></p>
      <p><strong>For Cause: </strong></p>
      <p>
        Kroll may terminate the Agreement if the End User has breached the
        Agreement or if the End User commits (or causes to be committed)
        negligence, fraud, willful misconduct or unlawful conduct arising from,
        in connection with, or related to use of Kroll’s Services or Platform.
      </p>
      <p><strong>For Convenience:</strong></p>
      <p>
        Kroll may terminate the Agreement for convenience, for any reason
        permitted by law. In such case, Kroll will provide fifteen (15) days’
        prior written notice of such termination, where practicable.
      </p>
      <p><strong>Effect of Termination:</strong></p>
      <p>
        Provisions of the Agreement which by their nature are intended to
        survive termination of the Agreement shall survive termination of the
        Agreement.
      </p>
      <h3>
        <strong
          >8. HOW KROLL MAY USE THE END USER’S PERSONAL INFORMATION</strong
        >
      </h3>
      <p>
        Kroll will only use your personal information as set out in our&nbsp;<a
          href="https://www.kroll.com/en/privacy-policy"
          >Privacy Policy</a
        >. Additional consumer identity monitoring information collected about
        you may include the following:
      </p>
      <p><strong>Personal Information You Provide to Us </strong></p>
      <p>
        During the creation of an End User account on Kroll’s Portal, or in
        order to provide the Services, we may collect personal information about
        you, such as:
      </p>
      <ul>
        <li>Name and e-mail address</li>
        <li>
          The information you provide in customer service contact or
          correspondence
        </li>
        <li>
          When you talk to us: for example, on the phone, or in person including
          call recordings and voice messages. We may monitor or record calls
          with you to verify we have carried out your instructions, to resolve
          queries or disputes, to improve the quality of our service or for
          regulatory or fraud prevention purposes.
        </li>
        <li>
          In writing: for example, letters, proposal forms, survey responses,
          e-mails, chat messages and texts.
        </li>
      </ul>
      <p>
        <strong
          >Information that may be collected automatically including when an End
          User uses Kroll’s Services</strong
        >
      </p>
      <ul>
        <li>
          Transaction data: for example, when Kroll deals with or manages the
          End User’s account;
        </li>
        <li>
          Electronically: for example, when an End User uses Kroll’s Portal;
        </li>
        <li>
          Usage and profile data: for example, from End User’s use of Kroll’s
          Portal. We gather this data using cookies and other software, which
          also helps us to fix bugs or crashes on the operating system or
          network.
        </li>
      </ul>
      <h3>
        <strong>9. OTHER IMPORTANT TERMS</strong><br /><br /><strong
          >Entire Agreement.</strong
        >
      </h3>
      <p>
        This Agreement, including the Portal Terms of Use, constitutes the
        entire agreement between the parties and supersedes and extinguishes all
        previous agreements, promises, assurances, warranties, representations
        and understandings between us, whether written or oral, relating to its
        subject matter.
      </p>
      <p><strong>Rights and Remedies.</strong></p>
      <p>
        The rights and remedies provided under this Agreement are in addition
        to, and not exclusive of, any rights or remedies provided by law.
      </p>
      <p><strong>Notices.</strong></p>
      <p>
        Any notice of other communication given to us will only be valid if it
        is given in writing to the details set out for us in Section 1 of this
        Agreement or as we may otherwise notify you. You agree that any
        communication sent to you will be deemed to have been safely received if
        it is given to you in writing using the contact details, including
        e-mail, which you provide to us on our Platform or as you may otherwise
        notify us in writing.
      </p>
      <p><strong>Variation.</strong></p>
      <p>
        Except as set out herein, no variation of this Agreement shall be
        effective unless it is in writing and signed by the parties (or their
        authorized representatives).
      </p>
      <p><strong>Questions.</strong></p>
      <p>
        If you have any questions or comments about this Agreement, please
        <a href="/contact-us" target="_blank" rel="noopener">Contact Us</a>.
        &nbsp;
      </p>
      <p><strong>End User Agreement Effective Date: June 1st, 2023</strong></p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
  </div>
</div>
