import { Component } from '@angular/core';

@Component({
  selector: 'app-browser-compatibility',
  templateUrl: './browser-compatibility.component.html',
  styleUrls: ['./browser-compatibility.component.scss']
})
export class BrowserCompatibilityComponent {

}
