<div class="container d-flex justify-content-center dynamic-html">
  <div class="col-8 mt-5">
    <div class="text">
      <h3>
        <strong>BROWSER COMPATIBITILITY<br /><br /></strong>
      </h3>
      <div>
        <p>
          This website is best viewed using the latest versions of Mozilla
          Firefox, Microsoft Edge, Google Chrome or Apple Safari.
        </p>
        <p>
          Using an outdated browser can compromise the security of your computer
          or mobile device and cause certain websites to look or behave
          incorrectly.
        </p>
        <p>
          Use one of the following links to download the latest version of your
          preferred browser. The link will either take you to the browser
          developer’s download page, or to the app store download page based on
          the selected operating system. From there, follow the download
          instructions.
        </p>
        <p>Please note this website is not optimized for Internet Explorer.</p>
        <p><strong>Desktop Browsers</strong></p>
        <ul>
          <li>
            <a
              href="https://www.google.com/intl/en/chrome/"
              target="_blank"
              rel="noopener"
              >Google Chrome</a
            >
          </li>
          <li>
            <a
              href="https://www.microsoft.com/en-us/windows/microsoft-edge"
              target="_blank"
              rel="noopener"
              >Microsoft Edge</a
            >
          </li>
          <li>
            <a
              href="https://www.mozilla.org/en-US/firefox/#desktop"
              target="_blank"
              rel="noopener"
              >Mozilla Firefox</a
            >
          </li>
          <li>
            <a
              href="https://www.apple.com/safari/"
              target="_blank"
              rel="noopener"
              >Apple Safari</a
            >
          </li>
        </ul>
        <p><strong>Android Browsers</strong></p>
        <ul>
          <li>
            <a
              href="https://play.google.com/store/apps/details?id=com.android.chrome&amp;hl=en_US"
              target="_blank"
              rel="noopener"
              >Google Chrome</a
            >
          </li>
          <li>
            <a
              href="https://play.google.com/store/apps/details?id=com.microsoft.emmx&amp;hl=en_US"
              target="_blank"
              rel="noopener"
              >Microsoft Edge</a
            >
          </li>
          <li>
            <a
              href="https://play.google.com/store/apps/details?id=org.mozilla.firefox&amp;hl=en_US"
              target="_blank"
              rel="noopener"
              >Mozilla Firefox</a
            >
          </li>
        </ul>
        <p><strong>iOS Browsers</strong></p>
        <ul>
          <li>
            <a
              href="https://itunes.apple.com/us/app/google-chrome/id535886823?mt=8"
              target="_blank"
              rel="noopener"
              >Google Chrome</a
            >
          </li>
          <li>
            <a
              href="https://itunes.apple.com/us/app/microsoft-edge/id1288723196?mt=8"
              target="_blank"
              rel="noopener"
              >Microsoft Edge</a
            >
          </li>
          <li>
            <a
              href="https://itunes.apple.com/us/app/firefox-web-browser/id989804926?mt=8"
              target="_blank"
              rel="noopener"
              >Mozilla Firefox</a
            >
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
    </div>
  </div>
</div>
