import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ActiveUserService {
  constructor(
    private translateService: TranslateService,
    private msalService: MsalService
  ) {}

  async validateActiveUser(email: string): Promise<string> {
    const tenantId = localStorage.getItem('tenantId');
    let error = null;
    if (tenantId) {
      //Try to make sure the correct account is active, if user is logged into multiple MSFT accounts
      const accounts = this.msalService.instance.getAllAccounts();
      //First check with both the username & tenantId
      //TenantId comes back from microsoft redirect & may or may not be the right tenant for the registration
      const tenantUserMatch = accounts.find(
        (a) =>
          a.username.toLowerCase() === email?.toLowerCase() &&
          a.tenantId === tenantId
      );
      if (tenantUserMatch)
        this.msalService.instance.setActiveAccount(tenantUserMatch);
      else {
        const userOnlyMatch = accounts.find(
          (a) => a.username.toLowerCase() === email?.toLowerCase()
        );
        if (userOnlyMatch) {
          //User exists, but tenantId coming back from microsoft redirect is for a different tenant
          error = this.translateService.instant('Error.InvalidTenant');
        } else {
          //No user exists matching the email
          error = this.translateService.instant('Error.InvalidUser');
        }
      }
    }
    return error;
  }
}
