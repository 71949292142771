<div class="p-3" *ngIf="isMember || isAdmin">
  <ul class="list-unstyled ps-0">
    <li class="mb-1" *ngIf="isMember">
      <button
        class="btn btn-toggle collapsed d-flex justify-content-between align-items-center"
        routerLink="/"
      >
        <span class="material-icons primary-color pr-1"> dashboard </span
        ><span class="align-middle">My Dashboard</span>
      </button>
    </li>
    <li class="mb-1" *ngIf="isAdmin">
      <button
        class="btn btn-toggle collapsed d-flex justify-content-between align-items-center"
        data-bs-toggle="collapse"
        data-bs-target="#home-collapse"
        aria-expanded="false"
      >
        <span class="material-icons primary-color pr-1"> outgoing_mail </span>
        {{ 'SideBar.Administration' | translate }}
      </button>
      <div class="collapse" id="home-collapse">
        <ul class="btn-toggle-nav list-unstyled pb-1 small">
          <li>
            <a
              [href]="hangfireUrl"
              class="link-dark rounded pl-4"
              target="_blank"
              >{{ 'SideBar.HangfireDashboard' | translate }}</a
            >
          </li>
          <li>
            <a routerLink="/admin/administrators" class="link-dark rounded pl-4"
              >{{ 'SideBar.Administrators' | translate }}</a
            >
            <ul class="list-unstyled">

              <li>
                <a routerLink="/admin/register-admin" class="link-dark rounded sub-item"
                  >{{ 'SideBar.NewAdmin' | translate }}</a
                >
              </li>
            </ul>
          </li>

          <li>
            <a routerLink="/admin/registrations" class="link-dark rounded pl-4"
            >{{ 'SideBar.Members' | translate }}</a
          >
            <ul class="list-unstyled">
              <li>
                <a routerLink="/admin/register" class="link-dark rounded sub-item"
                  >{{ 'SideBar.NewMember' | translate }}</a
                >
              </li>
            </ul>
          </li>
          <li>
            <a routerLink="/admin/reports" class="link-dark rounded pl-4"
            >{{ 'SideBar.Reports' | translate }}</a
          >
            <ul class="list-unstyled">
              <li>
                <a routerLink="/admin/reports/upload" class="link-dark rounded sub-item"
                  >{{ 'SideBar.NewReport' | translate }}</a
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</div>
