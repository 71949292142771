<div class="content" #notificationAnchor>
    <div class="d-flex justify-content-between">
      <h2>{{ "SearchAdministrators.Heading" | translate }}</h2>
      <button class="btn btn-secondary" routerLink="/admin/register-admin/">
        {{ "SearchAdministrators.Create" | translate }}
      </button>
    </div>
  
    <div class="table mt-3">
      <kendo-grid
        #grid
        [data]="gridData"
        [loading]="loadingData"
        [pageSize]="gridState.take"
        [skip]="gridState.skip"
        [pageable]="true"
        [filter]="gridState.filter"
        filterable="menu"
        [navigable]="true"
        [resizable]="true"
        [sortable]="true"
        [sort]="gridState.sort"
        (dataStateChange)="onStateChange($event)"
      >
        <kendo-grid-column
          class="text-uppercase"
          field="email"
          title="Email Address"
          [sortable]="true"
        >
          <ng-template
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService"
          >
            <kendo-grid-string-filter-menu
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
            >
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-menu>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="kendo-grid-custom-link">
                <span>{{ dataItem.email }}</span>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          class="text-uppercase"
          field="create_time"
          title="Sent"
        >
          <ng-template
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService"
          >
            <kendo-grid-date-filter-menu
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
            >
              <kendo-filter-eq-operator></kendo-filter-eq-operator>
              <kendo-filter-before-operator></kendo-filter-before-operator>
              <kendo-filter-after-operator></kendo-filter-after-operator>
            </kendo-grid-date-filter-menu>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{ dataItem.create_time | date : "medium" }}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          class="text-uppercase"
          field="expiration"
          title="Expires"
        >
          <ng-template
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService"
          >
            <kendo-grid-date-filter-menu
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
            >
              <kendo-filter-eq-operator></kendo-filter-eq-operator>
              <kendo-filter-before-operator></kendo-filter-before-operator>
              <kendo-filter-after-operator></kendo-filter-after-operator>
            </kendo-grid-date-filter-menu>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{ dataItem.expiration | date : "medium" }}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          class="text-uppercase text-center"
          field="is_complete"
          title="Status"
          [filterable]="false"
          [width]="80"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span
              class="material-icons secondary-color"
              *ngIf="dataItem.is_complete"
              title="Complete"
            >
              check_circle
            </span>
            <span
              class="material-icons text-warning"
              *ngIf="!dataItem.is_complete && isExpired(dataItem.expiration)"
              title="Expired"
            >
              error
            </span>
            <span
              class="material-icons primary-color"
              *ngIf="!dataItem.is_complete && !isExpired(dataItem.expiration)"
              title="Pending - Email Sent"
            >
              mark_email_read
            </span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          class="text-uppercase text-center"
          title="Actions"
          [filterable]="false"
          [sortable]="false"
          [width]="130"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="d-flex">
              <button
                type="button"
                class="btn"
                title="Resend"
                [disabled]="dataItem.is_complete"
                (click)="resend(dataItem.id)"
              >
                <span class="material-icons primary-color">send</span>
              </button>
              <button
                type="button"
                class="btn"
                title="Delete"
                (click)="confirm(dataItem.id)"
              >
                <span class="material-icons primary-color">delete</span>
              </button>
            </div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
  
  <kendo-dialog
    title="{{ 'General.Confirm' | translate }}"
    *ngIf="confirmationOpen"
    (close)="cancel()"
    [maxWidth]="400"
  >
    <p class="text-center m-3">
      {{ 'SearchAdministrators.DeleteWarning' | translate }}
    </p>
    <kendo-dialog-actions>
      <div class="d-flex justify-content-between">
        <button class="btn btn-secondary m-1" (click)="cancel()">{{ 'General.No' | translate }}</button>
        <button class="btn btn-primary m-1" (click)="delete()">{{ 'General.Yes' | translate }}</button>
      </div>
    </kendo-dialog-actions>
  </kendo-dialog>
  