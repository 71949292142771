<div class="container d-flex justify-content-center dynamic-html">
  <div class="col-8 mt-5">
    <div class="text">
      <h1 style="text-align: center"><strong>PORTAL TERMS OF USE</strong></h1>
      <p><strong>Last Updated: June 1, 2023</strong></p>
      <h2 style="text-align: center">
        <strong>PLEASE READ THESE PORTAL TERMS OF USE CAREFULLY</strong>
      </h2>
      <p>
        These Portal Terms of Use together with its Exhibits and such documents
        incorporated herein (“<strong><u>Terms</u></strong
        >”) between the End User (“<strong>End User</strong>”,
        “<strong>You</strong>” or “<strong>Your</strong>”) and
        <strong>Kroll Information Assurance, LLC.</strong> and its applicable
        affiliates (“<strong>Kroll</strong>” “<strong>We</strong>,”
        “<strong>Our</strong>” or “<strong>Us</strong>”) regarding the End
        User’s access to and use of the services provided by Kroll
        (“<strong>Services</strong>”) in the Kroll Responder for Office 365
        portal (“<strong>Portal</strong>”).&nbsp; All End Users must agree to
        these Terms before using the Portal and Services.
      </p>
      <p>
        If you are an individual agreeing to these Terms, you agree that these
        Terms apply to you as an End User; additionally, if you are agreeing to
        these Terms on behalf of a company, organization, other legal entity or
        legal person, you represent that you have the authority to bind that
        entity and its affiliates, related entities, and its respective
        employees, agents, delegates, representatives and any other individuals
        that access and use the Portal and receive the Services (“<strong
          >Authorized End Users</strong
        >”), to these Terms. If you do not agree with the Terms or if you do not
        have such authority, Authorized End Users will not be permitted to
        access, receive, and use the Portal and Services.
      </p>
      <h3>1.&nbsp;&nbsp;&nbsp; WHO WE ARE AND WHAT THIS DOCUMENT DOES</h3>
      <p>
        We are Kroll Information Assurance, LLC (“Kroll,” “we,” “us”), of 55 E.
        52<sup>nd</sup> Street, 17<sup>th</sup> floor, New York, NY 10055, and
        hereby license you to use:
      </p>
      <ul>
        <li>
          Kroll’s Responder for Office 365 portal software (“Portal”) and any
          updates or supplements to it.
        </li>
        <li>
          Related online or electronic documentation found in the Portal
          (“Documentation”)
        </li>
        <li>
          The service we provide to you or your customers through the Portal
          (“Service”)
        </li>
      </ul>
      <p>
        as permitted by these Terms which form a legally binding contract
        between you and Kroll. Together each of the items bullet pointed above
        are collectively referred to within these Terms and conditions as the
        “<strong>Platform</strong>”.
      </p>
      <h3>2.&nbsp;&nbsp;&nbsp; YOUR PRIVACY</h3>
      <p>
        We only use any personal data we collect through your use of the
        Platform in the ways set out in our&nbsp;<a
          href="https://www.kroll.com/en/privacy-policy"
          target="_blank"
          rel="noopener"
          >Privacy Policy</a
        >. Please be aware that Platform transmissions cannot be guaranteed to
        be completely private or secure and that any message or information you
        enter or send using the Platform may be read or intercepted by others,
        even if there is a special notice that a particular transmission is
        encrypted.&nbsp; To the extent required and pursuant to applicable law,
        Authorized End Users consent to the collection and use of their personal
        data in connection with and to the extent necessary to perform the
        Services as contemplated hereunder.
      </p>
      <p><strong>Contacting Kroll (including complaints)</strong></p>
      <p>
        If you have a problem or question with our service, or wish to contact
        us for any other reason, please notify us using our Contact Us page.
      </p>
      <h3>3.&nbsp;&nbsp;&nbsp; HOW YOU MAY USE THE PLATFORM</h3>
      <p>In return for your agreeing to comply with these Terms you may:</p>
      <ul>
        <li>
          use the Platform in accordance with your Portal End User Agreement
          only and view, use and display the Platform in accordance with your
          Portal End User Agreement only;
        </li>
        <li>
          use any Documentation within the Platform to support your permitted
          use of the Platform
        </li>
      </ul>
      <p>
        If you do not agree to these Terms you must not use our Platform and
        must contact us immediately on the details given for us above. We
        recommend that you save a copy of these Terms for future reference.
      </p>
      <h3>4.&nbsp;&nbsp;&nbsp; YOU FURTHER AGREE:</h3>
      <ul>
        <li>
          that you have obtained, will obtain and are solely responsible for
          obtaining all required rights and authorizations to access, view
          and/or edit the data provided to you or by you via the Portal;<br /><br />
        </li>
        <li>
          that all information and data provided to you or by you via the Portal
          is reasonably accurate information and data, and that your use and
          provision of such information and data is limited to updating or
          modifying records for notification to affected individuals; and<br /><br />
        </li>
        <li>
          that you will promptly notify Kroll if you access accidentally,
          inadvertently, or otherwise, another user’s information or data, and
          will immediately cease and desist any and all access to such
          information or data. If your inadvertent access included downloading
          or copying, you will immediately delete and/or destroy such downloads
          and/or copies and shall certify such deletion or destruction to Kroll
          upon request.
        </li>
      </ul>
      <h3>
        5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; THERE ARE OTHER TERMS THAT MAY
        APPLY TO YOU
      </h3>
      <p>
        These Terms refer to the following additional terms which also apply to
        you use of our Portal:
      </p>
      <ul>
        <li>
          Our&nbsp;<a
            href="https://www.kroll.com/en/privacy-policy"
            target="_blank"
            rel="noopener"
            >Privacy Policy</a
          >
        </li>
        <li>
          Our
          <a href="/end-user-agreement" target="_blank" rel="noopener"
            >End User Agreement</a
          >
          (for Kroll Responder for Office 365)
        </li>
        <li>
          Our
          <a
            href="https://www.kroll.com/en/cookies-policy"
            target="_blank"
            rel="noopener"
            >Cookies Policy</a
          >, which sets our information about the cookies on our site
        </li>
      </ul>
      <h3>
        6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; YOU MUST BE 18 TO ACCEPT THESE
        TERMS AND USE THE PLATFORM
      </h3>
      <p>
        You must be 18 or over to accept these Terms and be able to use the
        Platform.
      </p>
      <h3>
        7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; YOU MUST NOT TRANSFER YOUR RIGHT
        TO USE THE PLATFORM OR YOUR PLATFORM LOGIN DETAILS TO SOMEONE ELSE
      </h3>
      <p>We are giving you personally the right to use the Platform.</p>
      <p>
        You must not transfer your Platform details or Service to someone else,
        whether for money, for anything else or for free. If you sell any device
        on which the Platform is used, you must remove memory of anything of or
        from the Platform from the device prior to the sale. You are responsible
        for ensuring that all persons who access our Platform through your
        internet connection or device or using your account details, including
        your Authorized End Users, are aware of these Terms and other applicable
        documents referred to and that they comply with them.
      </p>
      <p>
        We do not guarantee that our Platform or any content on it will always
        be available or be uninterrupted. We may suspend or withdraw or restrict
        the availability of all or any part of our Platform for business and
        operational reasons.
      </p>
      <h3>
        8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; OUR PLATFORM IS FOR USERS IN THE
        UNITED STATES
      </h3>
      <p>
        Our Platform is directed to people residing in the United States. We do
        not represent that content available on or through our Platform are
        appropriate for use or available in other locations.
      </p>
      <h3>
        9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; YOU MUST KEEP YOUR ACCOUNT
        DETAILS SAFE
      </h3>
      <p>
        If you choose, or are provided with, a user identification code or
        password or any other piece of information as part of our security
        procedures you must treat such information as confidential. You must not
        disclose it to any third party.
      </p>
      <p>
        We have the right to disable any user identification code or password
        whether chosen by you or allocated by us at any time if in our
        reasonable opinion you have failed to comply with any of the provisions
        of these Terms.
      </p>
      <p>
        If you know or suspect that anyone other than you knows your user
        identification code or password you must promptly notify Kroll using our
        Contact Us page.
      </p>
      <h3>10.&nbsp;&nbsp;&nbsp;&nbsp; CHANGES TO THESE TERMS</h3>
      <p>
        We may need to change these Terms to reflect changes in law or best
        practice or to deal with additional features which we introduce.
      </p>
      <p>
        If you do not accept the changes you may not continue to use the
        Platform.
      </p>
      <h3>
        11.&nbsp;&nbsp;&nbsp;&nbsp; UPDATE TO THE PORTAL AND CHANGES TO THE
        SERVICE
      </h3>
      <p>
        From time to time we may automatically update the Platform and change it
        to improve performance, enhance functionality, reflect changes to the
        operating system or address security issues.
      </p>
      <h3>
        12.&nbsp;&nbsp;&nbsp;&nbsp; IF SOMEONE ELSE OWNS THE DEVICE YOU ARE
        USING
      </h3>
      <p>
        If you use the Platform on any phone or other device not owned by you,
        you must have the owner’s permission to do so. You are responsible for
        complying with these Terms, whether or not you own the phone or other
        device.
      </p>
      <h3>13.&nbsp;&nbsp;&nbsp;&nbsp; HOW WE MAY USE YOUR PERSONAL DATA</h3>
      <p>
        We will only use your personal data as set out in our Privacy Policy.
      </p>
      <h3>
        14.&nbsp;&nbsp;&nbsp;&nbsp; WE MAY COLLECT TECHNICAL DATA ABOUT YOUR
        DEVICE
      </h3>
      <p>
        By using the Platform, you agree to us collecting and using technical
        information about the devices you use and related software, hardware,
        and peripherals to improve our products and to provide the Services to
        you or your customers.
      </p>
      <h3>
        15.&nbsp;&nbsp;&nbsp;&nbsp; WE MAY COLLECT LOCATION DATA (BUT YOU CAN
        TURN LOCATION SERVICES OFF)
      </h3>
      <p>
        We may from time to time make use of location data sent from your
        devices. You can turn off this functionality at any time by turning off
        the location services settings on the device. If you leave on the
        location services you consent to us and our affiliates’ and licensees’
        transmission, collection, retention, maintenance, processing and use of
        your location data and queries to provide and improve the Platform.
      </p>
      <h3>
        16.&nbsp;&nbsp;&nbsp;&nbsp; WE ARE NOT RESPONSIBLE FOR OTHER WEBSITES
        YOU LINK TO
      </h3>
      <p>
        The Platform may contain links to other independent websites which are
        not provided by us. Such independent sites are not under our control and
        we are not responsible for and have not checked or approved their
        content or their privacy policies (if any).
      </p>
      <p>
        You will need to make your own independent judgement about whether to
        use any such independent sites, including whether to buy or enter into
        any products or services offered by them.
      </p>
      <h3>17.&nbsp;&nbsp;&nbsp;&nbsp; LICENSE RESTRICTIONS</h3>
      <p>You agree that you will:</p>
      <ul>
        <li>
          not rent, lease, sub-license, loan, provide, or otherwise make
          available the Platform in any form, in whole or in part to any person
          without prior written consent from us;<br /><br />
        </li>
        <li>
          not make a copy of the Platform, except as part of the normal use of
          the Platform or where it is necessary for the purpose of back-up or
          operational security;<br /><br />
        </li>
        <li>
          not translate, merge, adapt, vary, alter or modify, the whole or any
          part of the Platform nor permit the Platform or any part of them to be
          combined with, or become incorporated in, any other programs, except
          as necessary to use the Platform as permitted in these Terms;<br /><br />
        </li>
        <li>
          not disassemble, de-compile, reverse engineer or create derivative
          works based on the whole or any part of the Platform nor attempt to do
          any such things;<br /><br />
        </li>
        <li>
          comply with all applicable technology control or export laws and
          regulations that apply to the technology used or are supported by the
          Platform.<br /><br />
        </li>
      </ul>
      <h3>18.&nbsp;&nbsp;&nbsp;&nbsp; ACCEPTABLE USE RESTRICTIONS</h3>
      <p>You must:</p>
      <ul>
        <li>
          not use the Platform in any unlawful manner, for any unlawful purpose,
          or in any manner inconsistent with these Terms, or act fraudulently or
          maliciously, for example, by hacking into or inserting malicious code,
          such as viruses, or harmful data, into the Platform or any operating
          system;<br /><br />
        </li>
        <li>
          not infringe our proprietary or intellectual property rights or those
          of any third party in relation to you or your customers’ use of the
          Platform;<br /><br />
        </li>
        <li>
          not transmit any material that is defamatory, offensive or otherwise
          objectionable in relation to you or your customers’ use of the
          Platform;<br /><br />
        </li>
        <li>
          not use any information or results provided by the Platform to harass,
          intimidate, or to impersonate others;<br /><br />
        </li>
        <li>
          not use the Platform in a way that could damage, disable, overburden,
          impair or compromise our systems or security or interfere with other
          users;<br /><br />
        </li>
        <li>
          not collect or harvest any information or data from the Platform or
          our systems or attempt to decipher any transmissions to or from the
          servers running the Platform, and<br /><br />
        </li>
        <li>
          not use the Platform for competitive research or analysis of the
          Platform, or the development of a competing product or service or in
          any way that would be a commercial disadvantage to us.
        </li>
      </ul>
      <h3>19.&nbsp;&nbsp;&nbsp;&nbsp; INTELLECTUAL PROPERTY RIGHTS</h3>
      <p>
        19.1&nbsp;&nbsp;&nbsp; Intellectual Property Ownership. End User
        acknowledges and agrees that: (a) Kroll is the sole and exclusive owner
        of all right, title and interest in and to the Platform, including all
        intellectual property rights relating thereto, subject only to the
        rights of third parties in Third-Party Materials and the limited license
        granted to the End User under these Terms. The Platform is licensed, not
        sold, to the End User by Kroll and the End User does not have and will
        not acquire under or in connection with these Terms, any ownership
        interest in the Platform, or in any related intellectual property
        rights; and (b) if and to the extent an End User acquires any right,
        title or interest, including any intellectual property rights, in or to
        the Platform (including any rights in improvements of or to either of
        them), now or hereafter, by operation of law or otherwise, End User
        hereby unconditionally and irrevocably assigns to Kroll or Kroll’s
        designee, its entire right, title and interest in and to any and all
        such rights.
      </p>
      <p>
        19.2&nbsp;&nbsp;&nbsp; End User’s Rights and Obligations.&nbsp; End User
        further acknowledges and agrees that: (a) End User may print off one
        copy, and may download extracts, of any page(s) from our Platform for
        your internal business use, provided however, if you print off, copy or
        download any part of our Platform in breach of these Terms, your right
        to use our Platform will cease immediately and you must, at our option,
        return or destroy any copies of the materials you have made, (b) End
        User will not modify the paper or digital copies of any materials it has
        printed off or downloaded in any way and will not use any illustrations,
        photographs, video or audio sequences or any graphics separately from
        any accompanying text, (c) Kroll’s status (and that of any identified
        contributors) as the authors of content on our Platform will always be
        acknowledged, (d) End User will not use any part of the content on
        Kroll’s Platform for commercial purposes (other than as permitted via a
        services agreement) without obtaining a license or other consent to do
        so from Kroll, and (e) the trademarks expressly listed as such in this
        Portal and all other marks on the Portal are trademarks of Kroll and/or
        its affiliates. You may not use, copy, reproduce, republish, upload,
        post, transmit, distribute or modify these marks in any way without
        Kroll's prior written permission. The use of Kroll’s trademarks on any
        other website without Kroll’s prior written permission is prohibited.
      </p>
      <p>
        19.3&nbsp;&nbsp;&nbsp; End User Cooperation and Notice of Infringement.
        The End User will, during the Term: (a) take commercially reasonable
        measures to safeguard the Platform (including all copies thereof) from
        infringement, misappropriation, theft, misuse or unauthorized access;
        (b) at Kroll’s expense, take all such steps as Kroll may reasonably
        require to assist Kroll in maintaining the validity, enforceability and
        Kroll’s ownership of the intellectual property rights in the Platform;
        (c) promptly notify Kroll in writing if End User becomes aware of: (i)
        any actual or suspected infringement, misappropriation or other
        violation of Kroll’s intellectual property rights in or relating to the
        Platform; or (ii) any claim that the Platform, including any production,
        use, marketing, sale or other disposition of the Platform, in whole or
        in part, infringes, misappropriates or otherwise violates the
        intellectual property rights or other rights of any person; and (d)
        cooperate with and assist Kroll as reasonably requested in the conduct
        of any action by Kroll to prevent or abate any actual or threatened
        infringement, misappropriation or violation of Kroll’s rights in, and to
        attempt to resolve any actions relating to, the Platform.
      </p>
      <p>
        19.4&nbsp;&nbsp;&nbsp; No Implied Rights. Except for the limited rights
        and licenses expressly granted under these Terms, nothing in these Terms
        grants, by implication, waiver, estoppel or otherwise, to the End User
        or any third party any intellectual property rights or other right,
        title, or interest in or to any of the Platform.
      </p>
      <h3>20.&nbsp; &nbsp; &nbsp; <strong>INDEMNIFICATION.</strong></h3>
      <p>
        End User Indemnification. End User will indemnify, defend, and hold
        harmless Kroll and its Affiliates, and each of its and their respective
        officers, directors and employees from and against all claims, damages
        and costs (including reasonable attorneys’ fees and disbursements)
        arising out of (a) a breach of these Terms by End User or any of its
        Authorized End Users (b) any violation of or use of information not
        permitted by applicable privacy laws, or (c) relating to negligence,
        gross negligence, abuse, misapplication, misuse or recklessness or
        willful misconduct by or on behalf of the End User or any of its
        Authorized End Users with respect to the Platform or otherwise in
        connection with these Terms.
      </p>
      <h3>
        21.&nbsp; &nbsp; &nbsp;OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY
        YOU
      </h3>
      <p>
        <strong>We are not liable for business losses.</strong>&nbsp;The
        Platform is provided solely for purposes described above.&nbsp; Any
        additional use is prohibited. If you use the Platform for any
        commercial, business or resale purposes you will be in breach of these
        Terms. We will have no liability to you under or in connection with
        these Terms, or its subject matter under any legal or equitable theory,
        including breach of contract, tort (including negligence), strict
        liability, and otherwise, for any (a) increased costs, diminution in
        value or lost business, business interruption or loss of business
        opportunity, production, revenues or profits, (b) loss of goodwill or
        reputation, (c) use, inability to use, loss, interruption, delay or
        recovery of any licensed software or open source components or other
        third-party materials, (d) loss, damage, corruption, or recovery of
        data, or breach of data or system security, (e) cost of replacement
        goods or services, or (f) consequential, incidental, indirect,
        exemplary, special, enhanced, or punitive damages, in each case
        regardless of whether such persons were advised of the possibility of
        such losses or damages or such losses or damages were otherwise
        foreseeable, and notwithstanding the failure of any agreed or other
        remedy of its essential purpose. the End User will have the sole
        responsibility for protecting its data, by periodic backup or otherwise,
        used in connection with the services.
      </p>
      <p>
        We take no responsibility for your or your Authorized Users’ use of the
        Platform that results in you or your Authorized Users breaching the
        terms and conditions of any agreement you or an Authorized End User has
        with any other person or entity.
      </p>
      <p><strong>Limitations to the Platform</strong></p>
      <p>
        The Platform is provided for general information only. It does not offer
        any advice on which you should rely. You must obtain professional or
        specialist advice before taking, or refraining from taking, any action
        on the basis of information obtained from the Platform. Although we make
        reasonable efforts to update the information provided by the Platform,
        we make no representations, warranties or guarantees, whether express or
        implied, that such information is accurate, complete or up to date.
      </p>
      <p><strong>Check that the Platform is suitable for you.</strong>&nbsp;</p>
      <p>
        The Platform has not been developed to meet your individual
        requirements. Please check that the facilities and functions of the
        Platform meet your requirements.
      </p>
      <p>
        <strong>We are not responsible for events outside our control.</strong
        >&nbsp;
      </p>
      <p>
        If our provision of the Platform is delayed by an event outside our
        control then we will contact you as soon as possible to let you know and
        we will take steps to minimize the effect of the delay so far as we are
        able to do so. Provided we do this we will not be liable for delays
        caused by the event or any third-party network provider or otherwise.
      </p>
      <h3>
        22.&nbsp;&nbsp;&nbsp;&nbsp; WE MAY END YOUR RIGHTS TO USE THE PLATFORM
        IF YOU BREACH THESE TERMS
      </h3>
      <p>
        We may end your rights to use the Platform at any time by contacting you
        if you or an Authorized User have breached these Terms. This may also
        impact the Service we have already provided to you and we may seek to
        terminate the Service as a result of your breach of these Terms. If the
        breach can be cured we will give you a reasonable opportunity to do so.
      </p>
      <p>If we end your rights to use the Platform or any part of it:</p>
      <ul>
        <li>
          You must stop all activities authorized by these Terms including your
          use of the Platform;
        </li>
        <li>
          You must delete the Platform from all devices in your possession and
          confirm to us that you have done this.
        </li>
        <li>
          We may remotely block or remove your access to the Platform and cease
          providing you with the Services.
        </li>
      </ul>
      <h3>23.&nbsp;&nbsp;&nbsp;&nbsp; WE MAY TRANSFER THIS AGREEMENT</h3>
      <p>
        We may transfer our rights and obligations under these Terms to another
        organization. If this happens we will take reasonable measures to ensure
        that the transfer will not affect your rights under these Terms.
      </p>
      <h3>
        24.&nbsp;&nbsp;&nbsp;&nbsp; YOU NEED OUR CONSENT TO TRANSFER YOUR RIGHTS
      </h3>
      <p>
        You may only transfer your rights or your obligations under these Terms
        to another person upon Kroll’s prior written consent.
      </p>
      <h3>25.&nbsp;&nbsp;&nbsp;&nbsp; NO RIGHTS FOR THIRD PARTIES</h3>
      <p>
        These Terms do not give rise to any rights for third parties or other
        third-party beneficiaries.
      </p>
      <h3>
        26.&nbsp;&nbsp;&nbsp;&nbsp; IF A COURT FINDS PART OF THIS CONTRACT
        ILLEGAL, THE REST WILL CONTINUE IN FORCE
      </h3>
      <p>
        Each of the paragraphs of these Terms operates separately. If any court
        or relevant authority decides that any of them are unlawful, the
        remaining paragraphs will remain in full force and effect.
      </p>
      <h3>
        27.&nbsp;&nbsp;&nbsp;&nbsp; EVEN IF WE DELAY IN ENFORCING THIS CONTRACT,
        WE CAN STILL ENFORCE IT LATER
      </h3>
      <p>
        Even if we delay in enforcing this contract, we can still enforce it
        later. If we do not insist immediately that you do anything you are
        required to do under these Terms, or if we delay in taking steps against
        you in respect of your breaching this contract, that will not mean that
        you do not have to do those things and it will not prevent us taking
        steps against you at a later date.
      </p>
      <h3>
        28.&nbsp;&nbsp;&nbsp;&nbsp; WHICH LAWS APPLY TO THIS CONTRACT AND WHERE
        YOU MAY BRING LEGAL PROCEEDINGS
      </h3>
      <p>
        These Terms are governed by New York State law and legal proceedings are
        to be brought in New York state courts.
      </p>
      <p>
        If you have any questions or comments about these Terms, please
        <a href="/contact-us" target="_blank" rel="noopener">Contact Us</a>.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
  </div>
</div>
