<div *ngIf="isSuccess">
  <div class="d-flex justify-content-between mt-3 success-message">
    {{ success }}
  </div>
</div>
<div *ngIf="isError">
  <div class="d-flex justify-content-between mt-3 error-message">
    {{ error }}
  </div>
</div>
<div *ngIf="isLoading" class="d-flex justify-content-between info-message mt-3">
  {{ "General.Loading" | translate }}
  <div class="spinner-border primary-color" role="status"></div>
</div>
