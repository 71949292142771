import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EscalationContact } from '../models/escalation-contact';
import { Responder365AppConfig } from './app-config-service';
import { AutoGeneratedApiService } from './autogenerated-api-service';

@Injectable({
  providedIn: 'root',
})
export class EscalationContactService extends AutoGeneratedApiService<EscalationContact> {
  constructor(appConfigService: Responder365AppConfig, http: HttpClient) {
    super(appConfigService, http, 'escalation-contact');
  }
}
