<div class="pt-3 global-footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="d-flex justify-content-between">
          <div class="pb-3 footerTextContainer">
            <div class="links">
              <ng-container
                *ngFor="
                  let footer of 'Footer.FooterLinks' | translate;
                  let last = last
                "
              >
                <a href="{{ footer.RouterLink }}" target="_blank">{{
                  footer.Name | translate
                }}</a
                ><span class="pl-2 pr-2" *ngIf="!last">|</span>
              </ng-container>
            </div>
            <div class="copyright">
              <span>
                ©&nbsp;{{ copyrightYear }}&nbsp;{{
                  "Footer.CopyRightText" | translate
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
