import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loadingSubject$ = new BehaviorSubject(false);
  loadingObservable$ = this.loadingSubject$.asObservable();

  async show() {
    this.loadingSubject$.next(true);
  }

  async hide() {
    this.loadingSubject$.next(false);
  }
}
