<div class="content">
  <h2>{{ "CompleteRegistration.Heading" | translate }}</h2>
  <div *ngIf="!(loadingService.loadingObservable$ | async)">
    <div *ngIf="roleId == RoleIds.Member">
      <p>
        {{ "CompleteRegistration.SubHeading" | translate }}
      </p>
      <div *ngIf="!isComplete">
        <ul>
          <li *ngFor="let step of 'CompleteRegistration.Steps' | translate">
            {{ step }}
          </li>
        </ul>
      </div>
      <div *ngIf="!isComplete">
        <div class="d-flex justify-content-end py-1">
          <div>
            <button class="btn btn-secondary" (click)="nextStep()">
              {{ "CompleteRegistration.BeginConsentButton" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="roleId == RoleIds.Admin">
      <p>
        {{ "CompleteRegistration.AdminSubHeading" | translate }}
      </p>
      <div *ngIf="!isComplete">
        <div class="d-flex justify-content-between py-1">
          <b>{{ "CompleteRegistration.AdminComplete" | translate }}</b>
          <div>
            <button
              class="btn btn-secondary"
              (click)="completeAdminRegistration()"
            >
              {{ "CompleteRegistration.FinishSetup" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <app-status
      [success]="success"
      [error]="error"
      [isLoading]="loadingService.loadingObservable$ | async"
    ></app-status>
  </div>
</div>
