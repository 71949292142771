import { InjectionToken, inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { lastValueFrom, Observable } from 'rxjs';
import { LoadingService } from './loading-service';
import { SessionService } from './session-service';
import {
  AuthError,
  InteractionRequiredAuthError,
  RedirectRequest,
} from '@azure/msal-browser';
import { Responder365AppConfig } from './app-config-service';

export class RoleGuard {
  static forRoles(...roles: string[]) {
    return new InjectionToken<CanActivate>('RoleGuard', {
      providedIn: 'root',
      factory: () => {
        const sessionService = inject(SessionService);
        const router = inject(Router);
        const loadingService = inject(LoadingService);
        const msalService = inject(MsalService);
        const config = inject(Responder365AppConfig);
        return new RoleCheck(
          sessionService,
          router,
          loadingService,
          msalService,
          config,
          roles
        );
      },
    });
  }
}

export class RoleCheck implements CanActivate, CanActivateChild {
  constructor(
    private sessionService: SessionService,
    private router: Router,
    private loadingService: LoadingService,
    private msalService: MsalService,
    private config: Responder365AppConfig,
    private roles: string[]
  ) {}
  async canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return await this.canActivate();
  }
  async canActivate(): Promise<boolean> {
    {
      this.loadingService.show();
      let result = false;
      try {
        await this.msalService.instance.acquireTokenSilent({
          scopes: [
            `api://${this.config?.authConfig?.clientId}/User.Api.Access`,
          ],
          account: this.msalService.instance.getActiveAccount() ?? undefined,
        });
        let s = await this.sessionService.fetch();
        if (this.roles.some((r) => s.roles.includes(r))) result = true;
        else {
          if (!this.roles.includes('Admin') && s.roles.includes('Admin'))
            await this.router.navigateByUrl('/admin/registrations');
          else if (!this.roles.includes('Member') && s.roles.includes('Member'))
            await this.router.navigateByUrl('/');
        }
      } catch (err: any) {
        console.error(err);
        if (err instanceof AuthError) {
          await this.msalService.instance.loginRedirect(<RedirectRequest>{
            scopes: [
              `api://${this.config?.authConfig?.clientId}/User.Api.Access`,
            ],
            prompt: 'select_account',
          });
        } else {
          let error = 'unknown';
          switch (err.status) {
            case 401:
              error = 'invalid-user';
              break;
            case 400:
            case 403:
              await this.msalService.instance.loginRedirect(<RedirectRequest>{
                scopes: [
                  `api://${this.config?.authConfig?.clientId}/User.Api.Access`,
                ],
                prompt: 'select_account',
              });
              return false;
          }
          await this.router.navigateByUrl('/error', {
            state: { error: error },
          });
        }
      } finally {
        this.loadingService.hide();
        return result;
      }
    }
  }
}
