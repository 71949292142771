import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RegistrationService } from 'src/app/services/registration-service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  formGroup: FormGroup;
  isLoading = false;
  private nameRegex =
    /^(?=.{1,50}$)((?!.*[-']{2})[-']*)[a-zA-Z]+(?:[-']*[a-zA-Z\s])*$/;
  isError: boolean = false;
  isSuccess: boolean = false;
  constructor(private registrationService: RegistrationService) {
    this.formGroup = new FormGroup({
      firstName: new FormControl(null, [
        Validators.required,
        Validators.pattern(this.nameRegex),
      ]),
      lastName: new FormControl(null, [
        Validators.required,
        Validators.pattern(this.nameRegex),
      ]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      message: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit(): void {}

  async onSubmit() {
    this.isSuccess = false;
    this.isError = false;
    if (this.isLoading) return;
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      this.isLoading = true;
      let contactUsData = {
        first_name: this.firstName?.value,
        last_name: this.lastName?.value,
        email: this.email?.value,
        message: this.message?.value,
      };
      try {
        await this.registrationService.sendContactUsEmail(contactUsData);
        this.isSuccess = true;
        this.formGroup.reset();
      } catch (err: any) {
        this.isError = true;
      }
    }
    this.isLoading = false;
  }

  //Controls
  get firstName() {
    return this.formGroup.get('firstName');
  }
  get lastName() {
    return this.formGroup.get('lastName');
  }
  get email() {
    return this.formGroup.get('email');
  }
  get message() {
    return this.formGroup.get('message');
  }
}
