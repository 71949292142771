<form [formGroup]="formGroup">
  <div class="matrix">
    <div class="row">
      <div class="col-2 py-2 d-flex">
        <b>{{ "FinishSetup.Passphrase" | translate }}</b>
        <div>
          <span
            #anchor
            class="material-icons primary-color hover-hand ml-1"
            (mouseenter)="mouseEnter($event)"
            (mouseleave)="mouseLeave($event)"
            (click)="showPasswordTip = true"
          >
            privacy_tip
          </span>
          <kendo-popup
            #popup
            [anchor]="anchor"
            *ngIf="showPasswordTip"
            [popupAlign]="popupAlign"
            [anchorAlign]="anchorAlign"
          >
            <div class="p-2 m-1 align-middle" style="max-width: 450px">
              {{ "FinishSetup.PassPhraseTip" | translate }}
            </div>
          </kendo-popup>
        </div>
      </div>
      <div class="col-10 py-2">
        <div class="d-flex align-items-center flex-nowrap text-nowrap">
          <kendo-textbox
            formControlName="passphrase"
            (blur)="passphrase.input.nativeElement.type = 'password'"
            (focus)="passphrase.input.nativeElement.type = 'text'"
            #passphrase
          >
          </kendo-textbox>
        </div>

        <div
          *ngIf="
            (formGroup.get('passphrase')?.invalid &&
              formGroup.get('passphrase')?.touched) ||
            formGroup.get('passphrase')?.dirty
          "
        >
          <small
            class="text-danger"
            *ngIf="
              formGroup.get('passphrase')?.errors?.required ||
              formGroup.get('passphrase')?.errors?.required
            "
            >{{ "FinishSetup.PassPhraseRequired" | translate }}</small
          >
          <small
            class="text-danger"
            *ngIf="formGroup.get('passphrase')?.errors?.maxlength"
            >{{ "FinishSetup.PassPhraseMaxLength" | translate }}</small
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-2 py-2 d-flex align-items-center">
        <b>{{ "FinishSetup.CustomerLocalTime" | translate }}</b>
      </div>
      <div class="col-10 py-2">
        <div class="d-flex align-items-center flex-nowrap text-nowrap">
          <kendo-timepicker
            class="mx-1"
            #inHoursStartTime
            formControlName="inHoursStartTime"
          ></kendo-timepicker>

          <kendo-label [for]="inHoursEndTime">to</kendo-label>
          <kendo-timepicker
            class="mx-1"
            #inHoursEndTime
            formControlName="inHoursEndTime"
          ></kendo-timepicker>

          <kendo-dropdownlist
            [data]="timezones"
            textField="display"
            valueField="value"
            formControlName="inHoursTimezone"
          >
          </kendo-dropdownlist>
        </div>
        <div
          *ngIf="
            (formGroup.get('inHoursEndTime')?.invalid &&
              formGroup.get('inHoursEndTime')?.touched) ||
            formGroup.get('inHoursEndTime')?.dirty
          "
        >
          <small
            class="text-danger"
            *ngIf="
              formGroup.get('inHoursEndTime')?.errors?.required ||
              formGroup.get('inHoursStartTime')?.errors?.required
            "
            >{{ "FinishSetup.CustomerHoursRequired" | translate }}</small
          >
        </div>
        <div
          *ngIf="(formGroup?.invalid && formGroup?.touched) || formGroup?.dirty"
        >
          <small
            class="text-danger"
            *ngIf="formGroup?.errors?.dates?.includes('inHours')"
            >{{ "FinishSetup.CustomerHoursDateError" | translate }}</small
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-2"></div>
      <div class="col-5 text-center">
        <b>{{ "FinishSetup.CustomerInHours" | translate }}</b>
      </div>
      <div class="col-5 text-center">
        <b>{{ "FinishSetup.CustomerOutHours" | translate }}</b>
      </div>
    </div>
    <div class="row" *ngFor="let contactType of selectedContactTypes">
      <div class="col-2 py-2">
        <b>
          {{ contactType.name | titlecase }}
          {{ "FinishSetup.ContactDetails" | translate }}</b
        >
      </div>
      <div class="col-5" *ngFor="let supportType of supportTypes">
        <div class="row py-2">
          <div class="col-4">
            {{ "FinishSetup.Name" | translate }}
          </div>
          <div class="col-8">
            <div class="form-group">
              <div class="row">
                <div class="col">
                  <kendo-textbox
                    class="form-control"
                    placeholder="{{
                      'FinishSetup.FirstNamePlaceholder' | translate
                    }}"
                    [formControlName]="
                      contactType.name +
                      '_' +
                      supportType.name +
                      '_' +
                      'first_name'
                    "
                  ></kendo-textbox>
                </div>
                <div class="col">
                  <kendo-textbox
                    class="form-control"
                    placeholder="{{
                      'FinishSetup.LastNamePlaceholder' | translate
                    }}"
                    [formControlName]="
                      contactType.name +
                      '_' +
                      supportType.name +
                      '_' +
                      'last_name'
                    "
                  ></kendo-textbox>
                </div>
              </div>

              <div
                *ngIf="
                  (formGroup.get(
                    contactType.name +
                      '_' +
                      supportType.name +
                      '_' +
                      'first_name'
                  )?.invalid &&
                    formGroup.get(
                      contactType.name +
                        '_' +
                        supportType.name +
                        '_' +
                        'first_name'
                    )?.touched) ||
                  formGroup.get(
                    contactType.name +
                      '_' +
                      supportType.name +
                      '_' +
                      'first_name'
                  )?.dirty ||
                  (formGroup.get(
                    contactType.name +
                      '_' +
                      supportType.name +
                      '_' +
                      'last_name'
                  )?.invalid &&
                    formGroup.get(
                      contactType.name +
                        '_' +
                        supportType.name +
                        '_' +
                        'last_name'
                    )?.touched) ||
                  formGroup.get(
                    contactType.name +
                      '_' +
                      supportType.name +
                      '_' +
                      'last_name'
                  )?.dirty
                "
              >
                <small
                  class="text-danger"
                  *ngIf="
                    formGroup.get(
                      contactType.name +
                        '_' +
                        supportType.name +
                        '_' +
                        'first_name'
                    )?.errors?.required ||
                    formGroup.get(
                      contactType.name +
                        '_' +
                        supportType.name +
                        '_' +
                        'last_name'
                    )?.errors?.required
                  "
                  ><div>
                    {{ "FinishSetup.NameRequired" | translate }}
                  </div></small
                >
                <small
                  class="text-danger"
                  *ngIf="
                    formGroup.get(
                      contactType.name +
                        '_' +
                        supportType.name +
                        '_' +
                        'first_name'
                    )?.errors?.pattern ||
                    formGroup.get(
                      contactType.name +
                        '_' +
                        supportType.name +
                        '_' +
                        'last_name'
                    )?.errors?.pattern
                  "
                  ><div>{{ "FinishSetup.NameInvalid" | translate }}</div></small
                >
              </div>
            </div>
          </div>
        </div>
        <div class="row py-2">
          <div class="col-4">
            {{ "FinishSetup.PhoneNumber" | translate }}
          </div>
          <div class="col-8">
            <div class="form-group">
              <kendo-textbox
                class="form-control"
                placeholder="{{ 'FinishSetup.PhonePlaceholder' | translate }}"
                [formControlName]="
                  contactType.name + '_' + supportType.name + '_' + 'phone'
                "
              >
                <ng-template kendoTextBoxPrefixTemplate
                  ><span class="ml-1">+</span></ng-template
                >
              </kendo-textbox>
              <div
                *ngIf="
                  (formGroup.get(
                    contactType.name + '_' + supportType.name + '_' + 'phone'
                  )?.invalid &&
                    formGroup.get(
                      contactType.name + '_' + supportType.name + '_' + 'phone'
                    )?.touched) ||
                  formGroup.get(
                    contactType.name + '_' + supportType.name + '_' + 'phone'
                  )?.dirty
                "
              >
                <small
                  class="text-danger"
                  *ngIf="
                    formGroup.get(
                      contactType.name + '_' + supportType.name + '_' + 'phone'
                    )?.errors?.required
                  "
                  >{{ "FinishSetup.PhoneRequired" | translate }}</small
                >
                <small
                  class="text-danger"
                  *ngIf="
                    formGroup.get(
                      contactType.name + '_' + supportType.name + '_' + 'phone'
                    )?.errors?.pattern
                  "
                  >{{ "FinishSetup.PhoneInvalid" | translate }}</small
                >

                <small
                  class="text-danger"
                  *ngIf="
                    formGroup.get(
                      contactType.name + '_' + supportType.name + '_' + 'phone'
                    )?.errors?.maxlength
                  "
                  >{{ "FinishSetup.PhoneMaxLength" | translate }}</small
                >
              </div>
            </div>
          </div>
        </div>
        <div class="row py-2">
          <div class="col-4">
            {{ "FinishSetup.Email" | translate }}
          </div>
          <div class="col-8">
            <div class="form-group">
              <kendo-textbox
                class="form-control"
                placeholder="{{ 'FinishSetup.EmailPlaceholder' | translate }}"
                [formControlName]="
                  contactType.name + '_' + supportType.name + '_' + 'email'
                "
              ></kendo-textbox>
              <div
                *ngIf="
                  (formGroup.get(
                    contactType.name + '_' + supportType.name + '_' + 'email'
                  )?.invalid &&
                    formGroup.get(
                      contactType.name + '_' + supportType.name + '_' + 'email'
                    )?.touched) ||
                  formGroup.get(
                    contactType.name + '_' + supportType.name + '_' + 'email'
                  )?.dirty
                "
              >
                <small
                  class="text-danger"
                  *ngIf="
                    formGroup.get(
                      contactType.name + '_' + supportType.name + '_' + 'email'
                    )?.errors?.required
                  "
                  >{{ "FinishSetup.EmailRequired" | translate }}</small
                >
                <small
                  class="text-danger"
                  *ngIf="
                    formGroup.get(
                      contactType.name + '_' + supportType.name + '_' + 'email'
                    )?.errors?.email
                  "
                  >{{ "FinishSetup.EmailInvalid" | translate }}</small
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row py-2" *ngIf="!disabled">
    <div class="d-flex justify-content-between text-center py-1">
      {{ "FinishSetup.AddContact" | translate }}
      <div class="d-flex justify-content-between">
        <button
          class="btn btn-primary mr-1"
          (click)="addContact()"
          [disabled]="disabled"
          style="min-width: 40px"
          *ngIf="this.selectedContactTypes.length < this.contactTypes.length"
        >
          <span
            class="material-icons hover-hand"
            title="Add additional contact details"
          >
            add
          </span>
        </button>
        <button
          class="btn btn-primary"
          (click)="removeContact()"
          [disabled]="disabled"
          style="min-width: 40px"
          *ngIf="this.selectedContactTypes.length > 1"
        >
          <span
            class="material-icons hover-hand"
            title="Remove contact details"
          >
            remove
          </span>
        </button>
      </div>
    </div>
  </div>
  <div class="row py-2" *ngIf="!disabled">
    <div class="d-flex justify-content-between py-1">
      <b>{{ "FinishSetup.StartMonitoring" | translate }}</b>
      <div>
        <button
          class="btn btn-secondary"
          (click)="submitForm()"
          [disabled]="disabled"
        >
          {{ "FinishSetup.CompleteRegistration" | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
