import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LogSourceWrapper } from 'src/app/models/log-source';
import { LogSourceType } from 'src/app/models/log-source-type';
import { lastValueFrom } from 'rxjs';
import { LoadingService } from 'src/app/services/loading-service';
import { LogSourceService } from 'src/app/services/log-source-service';
import { LogSourceTypeService } from 'src/app/services/log-source-type-service';
import {
  RegistrationService,
  isExpired,
} from 'src/app/services/registration-service';
import { Responder365AppConfig } from 'src/app/services/app-config-service';
import { Registration } from 'src/app/models/registration';
import {
  ConsentInformation,
  PrerequisiteAppIds,
} from 'src/app/models/consent-information';
import { GridDataResult } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-monitoring-dashboard',
  templateUrl: './monitoring-dashboard.component.html',
  styleUrls: ['./monitoring-dashboard.component.scss'],
})
export class MonitoringDashboardComponent implements OnInit {
  error: string = '';
  success: string = '';
  logSourceWrappers: LogSourceWrapper[] = [];
  logSourceTypes: LogSourceType[] = [];
  isAllHealthy: boolean = true;
  isActivityFeedHealthy: boolean = true;
  isDefenderHealthy: boolean = true;
  isDefenderCloudAppsHealthy: boolean = true;
  interval: any;
  registrations: Registration[] = [];
  isExpired = isExpired;
  hasExpiredTrial: boolean = false;
  consentInformation!: ConsentInformation[];
  loadingData: boolean = false;
  gridData!: GridDataResult;

  constructor(
    private registrationService: RegistrationService,
    private logSourceService: LogSourceService,
    private logSourceTypeService: LogSourceTypeService,
    private translateService: TranslateService,
    public loadingService: LoadingService,
    private config: Responder365AppConfig
  ) {}

  async ngOnInit(): Promise<void> {
    this.loadingService.show();
    this.loadingData = true;
    try {
      this.registrations = await this.registrationService.list();
      this.hasExpiredTrial = this.registrations
        .filter((r) => r.is_trial)
        .some((r) => isExpired(r.trial_expiration));
      this.logSourceTypes = await this.logSourceTypeService.list();
      this.interval = setInterval(async () => {
        await this.fetchLogSourceDetails();
      }, this.config.pollIntervalSeconds * 1000);
      await this.fetchLogSourceDetails();
      let services = await lastValueFrom(this.translateService.get('Services'));
      let prerequisiteAppIds = this.logSourceWrappers.map(
        (lsw) => lsw.log_source_type?.prerequisite_app_id
      );
      this.gridData = {
        data: services.filter((s: any) =>
          prerequisiteAppIds.includes(s.PrerequisiteAppId)
        ),
        total: services.length,
      };
    } finally {
      this.loadingData = false;
      this.loadingService.hide();
    }
  }

  getLogSourcesForPrerequisiteAppId(
    prerequisiteAppId: string
  ): LogSourceWrapper[] {
    return this.logSourceWrappers.filter(
      (lsw) => lsw.log_source_type.prerequisite_app_id === prerequisiteAppId
    );
  }

  async adminConsent() {
    let logSourceWrappersByAppId = new Map(
      this.logSourceWrappers.map((lsw) => [
        lsw.log_source.responder_app_id,
        lsw,
      ])
    );
    this.consentInformation = [...logSourceWrappersByAppId.values()]
      .map((lsw) => {
        let order = 0;
        switch (lsw.log_source_type.prerequisite_app_id) {
          case PrerequisiteAppIds.Graph:
            order = 3;
            break;
          case PrerequisiteAppIds.Office365ManagementApi:
            order = 2;
            break;
          case PrerequisiteAppIds.MicrosoftThreatProtection:
            order = 1;
            break;
        }
        return <ConsentInformation>{
          app_id: lsw.log_source.responder_app_id,
          prerequisite_app_id: lsw.log_source_type?.prerequisite_app_id,
          consent: false,
          order: order,
        };
      })
      .sort((a, b) => a.order - b.order);
    localStorage.setItem(
      'consent-state',
      JSON.stringify(this.consentInformation)
    );

    let ci = this.consentInformation.find((ci) => ci.consent == false);
    if (ci)
      await this.registrationService.adminConsent(
        ci.app_id,
        encodeURIComponent(
          JSON.stringify({
            state: 'reconsent',
            appId: ci.app_id,
          })
        )
      );
  }

  async fetchLogSourceDetails() {
    const logSources = await this.logSourceService.list();
    let isAllHealthy = true;
    let isActivityFeedHealthy = true;
    let isDefenderHealthy = true;
    let isDefenderCloudAppsHealthy = true;
    for (const logSource of logSources.filter((ls) => ls.is_enabled)) {
      const logSourceType = this.logSourceTypes.find(
        (lst) => lst.id === logSource.log_source_type_id
      );
      const registration = this.registrations.find(
        (r) => r.id === logSource.registration_id
      );
      const existingLogSourceWrapper = this.logSourceWrappers.find(
        (lsw) => lsw.log_source.id == logSource.id
      );
      if (existingLogSourceWrapper) {
        existingLogSourceWrapper.log_source.last_retrieved =
          logSource.last_retrieved;
        existingLogSourceWrapper.log_source.is_healthy = logSource.is_healthy;
      } else {
        this.logSourceWrappers.push(<LogSourceWrapper>{
          log_source: logSource,
          log_source_type: logSourceType,
          registration: registration,
        });
      }

      if (logSource.is_healthy === false) {
        if (logSourceType?.required_permissions?.includes('ActivityFeed'))
          isActivityFeedHealthy = false;
        if (logSourceType?.name === 'Microsoft Defender for Cloud Apps')
          isDefenderCloudAppsHealthy = false;
        if (logSourceType?.name === 'Microsoft 365 Defender')
          isDefenderHealthy = false;
        isAllHealthy = false;
      }
    }

    this.isAllHealthy = isAllHealthy;
    this.isActivityFeedHealthy = isActivityFeedHealthy;
    this.isDefenderHealthy = isDefenderHealthy;
    this.isDefenderCloudAppsHealthy = isDefenderCloudAppsHealthy;

    if (this.isAllHealthy) {
      this.success = this.translateService.instant('Dashboard.SuccessMessage');

      //Right now we are always polling for updates but if we ever want to stop when reaching a successful state uncomment below
      //clearInterval(this.interval);
    } else {
      this.success = '';
    }

    if (this.hasExpiredTrial) {
      this.success = '';
      this.error = this.translateService.instant(
        'Dashboard.TrialExpiredMessage'
      );
    }
  }
}
