<div class="content">
  <div class="row py-1">
    <h2>{{ "EligibleServices.Heading" | translate }}</h2>

    <p>
      {{ "EligibleServices.SubHeading" | translate }}
    </p>
    <div *ngIf="!loadingData && !error">
      <kendo-grid #grid [data]="gridData" [resizable]="true" [sortable]="false">
        <kendo-grid-column [title]="'EligibleServices.Name' | translate">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.Name }}
            <div
              *ngIf="
                isDefenderDisabled &&
                dataItem.PrerequisiteAppId ==
                  PrerequisiteAppIds.MicrosoftThreatProtection
              "
              class="text-danger"
            >
              {{ "EligibleServices.DefenderDisabledMessage" | translate }}
            </div>
            <div
              *ngIf="
                isO365Disabled &&
                dataItem.PrerequisiteAppId ==
                  PrerequisiteAppIds.Office365ManagementApi
              "
              class="text-danger"
            >
              {{ "EligibleServices.Office365DisabledMessage" | translate }}
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [title]="'EligibleServices.Description' | translate">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.Description }}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [title]="'EligibleServices.Logs' | translate">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="d-flex mt-2" [ngSwitch]="dataItem.PrerequisiteAppId">
              <div *ngSwitchCase="PrerequisiteAppIds.Graph">
                <ul
                  style="list-style-type: none"
                  *ngFor="let item of eligibleAzureADLogSources"
                >
                  <li>
                    <div class="d-flex justify-content-left align-items-center">
                      <span
                        *ngIf="
                          isAzureAdDisabled === false &&
                          AzureAdDisabledAppRoles.length == 0
                        "
                        class="material-icons secondary-color hover-hand mr-1"
                        title="Add log type"
                      >
                        post_add
                      </span>
                      <span
                        *ngIf="
                          isAzureAdDisabled ||
                          AzureAdDisabledAppRoles.length > 0
                        "
                        class="material-icons text-warning hover-hand mr-1"
                        title="Your organization's configuration does not meet the application requirements"
                      >
                        error
                      </span>
                      {{ item.name }}
                    </div>
                    <div
                      *ngIf="
                        isAzureAdDisabled || AzureAdDisabledAppRoles.length > 0
                      "
                    >
                      <ul class="text-danger" style="list-style-type: none">
                        <li *ngIf="isAzureAdDisabled">
                          {{
                            "EligibleServices.PrerequisiteAppDisabled"
                              | translate
                          }}
                        </li>
                        <li *ngFor="let role of AzureAdDisabledAppRoles">
                          <span *ngIf="item.required_permissions == role.value">
                            The required {{ role.value }} permission is
                            disabled.
                          </span>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
              <div *ngSwitchCase="PrerequisiteAppIds.Office365ManagementApi">
                <ul
                  style="list-style-type: none"
                  *ngFor="let item of eligibleOffice365LogSources"
                >
                  <li>
                    <div class="d-flex justify-content-left align-items-center">
                      <span
                        *ngIf="
                          isO365Disabled === false &&
                          Office365DisabledAppRoles.length == 0
                        "
                        class="material-icons secondary-color hover-hand mr-1"
                        title="Add log type"
                      >
                        post_add
                      </span>
                      <span
                        *ngIf="
                          isO365Disabled || Office365DisabledAppRoles.length > 0
                        "
                        class="material-icons text-warning hover-hand mr-1"
                        title="Your organization's configuration does not meet the application requirements"
                      >
                        error
                      </span>
                      {{ item.name }}
                    </div>
                    <div
                      *ngIf="
                        isO365Disabled || Office365DisabledAppRoles.length > 0
                      "
                    >
                      <ul class="text-danger" style="list-style-type: none">
                        <li *ngIf="isO365Disabled">
                          {{
                            "EligibleServices.PrerequisiteAppDisabled"
                              | translate
                          }}
                        </li>
                        <li *ngFor="let role of Office365DisabledAppRoles">
                          <span *ngIf="item.required_permissions == role.value">
                            The required {{ role.value }} permission is
                            disabled.
                          </span>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
              <div *ngSwitchCase="PrerequisiteAppIds.MicrosoftThreatProtection">
                <ul
                  style="list-style-type: none"
                  *ngFor="let item of eligibleDefenderLogSources"
                >
                  <li>
                    <div class="d-flex justify-content-left align-items-center">
                      <span
                        *ngIf="
                          isDefenderDisabled === false &&
                          DefenderDisabledAppRoles.length == 0
                        "
                        class="material-icons secondary-color hover-hand mr-1"
                        title="Add log type"
                      >
                        post_add
                      </span>
                      <span
                        *ngIf="
                          isDefenderDisabled ||
                          DefenderDisabledAppRoles.length > 0
                        "
                        class="material-icons text-warning hover-hand mr-1"
                        title="Your organization's configuration does not meet the application requirements"
                      >
                        error
                      </span>
                      {{ item.name }}
                    </div>
                  </li>
                  <div
                    *ngIf="
                      isDefenderDisabled || DefenderDisabledAppRoles.length > 0
                    "
                  >
                    <ul class="text-danger" style="list-style-type: none">
                      <li *ngIf="isDefenderDisabled">
                        {{
                          "EligibleServices.PrerequisiteAppDisabled" | translate
                        }}
                      </li>
                      <li *ngFor="let role of DefenderDisabledAppRoles">
                        <span
                          *ngIf="
                            item.required_permissions?.includes(role.value)
                          "
                        >
                          The required {{ role.value }} permission is disabled.
                        </span>
                      </li>
                    </ul>
                  </div>
                </ul>
              </div>
            </div>
          </ng-template>
        </kendo-grid-column></kendo-grid
      >
    </div>
    <div
      class="d-flex justify-content-between pt-3"
      *ngIf="
        !isO365Disabled &&
          Office365DisabledAppRoles.length == 0 &&
          !isAzureAdDisabled &&
          AzureAdDisabledAppRoles.length == 0;
        else contactUs
      "
    >
      <b>{{ "EligibleServices.ConsentWarning" | translate }}</b>
      <div>
        <button class="btn btn-secondary" (click)="beginConsent()">
          {{ "EligibleServices.BeginConsent" | translate }}
        </button>
      </div>
    </div>
    <ng-template #contactUs>
      <div class="d-flex justify-content-end pt-3">
        <a href="/contact-us" class="btn btn-primary"> Contact Us </a>
      </div>
    </ng-template>
  </div>
  <app-status *ngIf="error" [error]="error"> </app-status>
</div>
