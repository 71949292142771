import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BrowserAuthOptions } from '@azure/msal-browser';
import { lastValueFrom } from 'rxjs';

export class Responder365AppConfig {
  apiUrl!: string;
  pollIntervalSeconds: number = 15;
  authConfig!: BrowserAuthOptions;
  appInsightsKey!: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private configObject!: Responder365AppConfig;
  private environment!: string;
  private readonly http!: HttpClient;
  constructor(httpBackend: HttpBackend) {
    const hostname = window && window.location && window.location.hostname;

    this.http = new HttpClient(httpBackend);

    if (/localhost/.test(hostname)) {
      this.environment = 'local';
    } else if (/dev/.test(hostname)) {
      this.environment = 'dev';
    } else if (/qa/.test(hostname)) {
      this.environment = 'qa';
    } else if (/stage/.test(hostname)) {
      this.environment = 'stage';
    } else {
      this.environment = 'production';
    }
    if (this.environment !== 'production') {
      console.log('environment: ' + this.environment);
    }
  }

  async loadConfig(version: string = ''): Promise<void> {
    const url = `../../assets/configuration/app.config.${this.environment}.json?v=${version}`;
    const data = await lastValueFrom(this.http.get<Responder365AppConfig>(url));
    this.configObject = data;
  }

  get config(): Responder365AppConfig {
    return this.configObject;
  }
}
