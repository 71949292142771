<div class="content">
  <h2>{{ "FinishSetup.Heading" | translate }}</h2>
  <p *ngIf="!initError">{{ "FinishSetup.SubHeading" | translate }}</p>
  <app-escalation-matrix
    *ngIf="!initError && (loadingService.loadingObservable$ | async) === false"
    [disabled]="isDisabled"
    [escalationDetail]="escalationDetail"
    [escalationContacts]="escalationContacts"
    [contactTypes]="contactTypes"
    [supportTypes]="supportTypes"
    (onSubmit)="onSubmit()"
  ></app-escalation-matrix>
  <app-status [success]="success" [error]="error" [isLoading]="isDisabled">
  </app-status>
  <div class="d-flex justify-content-end">
    <button
      class="btn btn-primary"
      *ngIf="error"
      (click)="restartRegistration()"
    >
      {{ "FinishSetup.StartOver" | translate }}
    </button>
  </div>
</div>
