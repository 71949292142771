import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EscalationDetail } from '../models/escalation-detail';
import { AutoGeneratedApiService } from './autogenerated-api-service';
import { Responder365AppConfig } from './app-config-service';

@Injectable({
  providedIn: 'root',
})
export class EscalationDetailService extends AutoGeneratedApiService<EscalationDetail> {
  constructor(appConfigService: Responder365AppConfig, http: HttpClient) {
    super(appConfigService, http, 'escalation-detail');
  }
}
