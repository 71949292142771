import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConsentInformation } from 'src/app/models/consent-information';
import { LogSource } from 'src/app/models/log-source';
import { LogSourceType } from 'src/app/models/log-source-type';
import { RoleIds } from 'src/app/models/roles';
import { Responder365AppConfig } from 'src/app/services/app-config-service';
import { LoadingService } from 'src/app/services/loading-service';
import { RegistrationService } from 'src/app/services/registration-service';

@Component({
  selector: 'app-complete-registration',
  templateUrl: './complete-registration.component.html',
  styleUrls: ['./complete-registration.component.scss'],
})
export class CompleteRegistrationComponent implements OnInit {
  apiResponse: string = '';
  error: string = '';
  success: string = '';
  registrationCode: string = '';
  logSources: LogSource[] = [];
  isComplete: boolean = false;
  isAuthenticated: boolean = false;
  roleId: number = -1;
  RoleIds = RoleIds;

  constructor(
    private registrationService: RegistrationService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    public loadingService: LoadingService,
    private config: Responder365AppConfig
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      this.loadingService.show();
      const registrationCode = this.route.snapshot.params.code;
      if (registrationCode) {
        const registrationDetails = await this.registrationService.fetch(
          registrationCode
        );
        this.registrationCode = registrationDetails?.code ?? '';
        this.logSources = registrationDetails?.log_sources ?? [];
        this.roleId = registrationDetails.role_id;
      } else {
        this.error = this.translateService.instant(
          'CompleteRegistration.InvalidCode'
        );
      }
    } catch (err: any) {
      console.error(err);
      if (err instanceof HttpErrorResponse) {
        const httpError = err as HttpErrorResponse;
        switch (httpError.error) {
          case 'Registration code has already been completed.': {
            await this.router.navigateByUrl('/');
            break;
          }
          default: {
            if (typeof httpError.error === 'string')
              this.error = httpError.error;
            else
              this.error = this.translateService.instant(
                'Error.GenericServerError'
              );
          }
        }
      }
    } finally {
      this.loadingService.hide();
    }
  }

  async nextStep() {
    this.registrationService.adminConsent(
      this.config.authConfig.clientId,
      encodeURIComponent(
        JSON.stringify({
          appId: this.config.authConfig.clientId,
          state: this.registrationCode,
        })
      )
    );
  }

  async completeAdminRegistration() {
    try {
      this.loadingService.show();
      await this.registrationService.complete(this.registrationCode);
      await this.router.navigateByUrl('/');
    } catch (err: any) {
      console.error(err);
      if (err instanceof HttpErrorResponse) {
        if (typeof err.error === 'string') this.error = err.error;
        else
          this.error = this.translateService.instant(
            'Error.GenericServerError'
          );
      }
    } finally {
      this.loadingService.hide();
    }
  }
}
