<div class="content" #notificationAnchor>
  <div class="d-flex justify-content-between">
    <h2>{{ "SearchRegistration.Heading" | translate }}</h2>
    <button class="btn btn-secondary" routerLink="/admin/register/">
      {{ "SearchRegistration.Create" | translate }}
    </button>
  </div>

  <div class="table mt-3">
    <kendo-grid
      #grid
      [data]="gridData"
      [loading]="loadingData"
      [pageSize]="gridState.take"
      [skip]="gridState.skip"
      [pageable]="true"
      [filter]="gridState.filter"
      filterable="menu"
      [navigable]="true"
      [resizable]="true"
      [sortable]="true"
      [sort]="gridState.sort"
      (dataStateChange)="onStateChange($event)"
    >
      <kendo-grid-column
        class="text-uppercase"
        field="email"
        title="Email Address"
        [sortable]="true"
      >
        <ng-template
          kendoGridFilterMenuTemplate
          let-filter
          let-column="column"
          let-filterService="filterService"
        >
          <kendo-grid-string-filter-menu
            [column]="column"
            [filter]="filter"
            [filterService]="filterService"
          >
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-menu>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="kendo-grid-custom-link">
            <a [routerLink]="'/admin/registration/' + dataItem.id">
              <span>{{ dataItem.email }}</span>
            </a>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        class="text-uppercase"
        field="tenant.company"
        title="Company"
        [sortable]="true"
      >
        <ng-template
          kendoGridFilterMenuTemplate
          let-filter
          let-column="column"
          let-filterService="filterService"
        >
          <kendo-grid-string-filter-menu
            [column]="column"
            [filter]="filter"
            [filterService]="filterService"
          >
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-menu>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{ dataItem.tenant.company }}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        class="text-uppercase text-center"
        field="is_trial"
        title="Trial"
        [width]="85"
        filter="boolean"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <span
            class="material-icons primary-color"
            *ngIf="dataItem.is_trial && !dataItem.trial_expiration"
            title="Trial period begins when registration is complete"
          >
            hourglass_top
          </span>
          <span
            class="material-icons secondary-color"
            *ngIf="
              dataItem.is_trial &&
              dataItem.trial_expiration &&
              !isExpired(dataItem.trial_expiration) &&
              daysBetween(dataItem.trial_expiration) > 0
            "
            [title]="
              'Trial active, ending in ' +
              daysBetween(dataItem.trial_expiration) +
              ' days'
            "
          >
            hourglass_top
          </span>
          <span
            class="material-icons secondary-color"
            *ngIf="
              dataItem.is_trial &&
              dataItem.trial_expiration &&
              !isExpired(dataItem.trial_expiration) &&
              daysBetween(dataItem.trial_expiration) == 0
            "
            [title]="'Trial active, ends today'"
          >
            hourglass_top
          </span>
          <span
            class="material-icons text-danger"
            *ngIf="
              dataItem.is_trial &&
              dataItem.trial_expiration &&
              isExpired(dataItem.trial_expiration) &&
              daysBetween(dataItem.trial_expiration) > 0
            "
            [title]="
              'Trial ended ' +
              daysBetween(dataItem.trial_expiration) +
              ' days ago'
            "
          >
            hourglass_bottom
          </span>
          <span
            class="material-icons text-danger"
            *ngIf="
              dataItem.is_trial &&
              dataItem.trial_expiration &&
              isExpired(dataItem.trial_expiration) &&
              daysBetween(dataItem.trial_expiration) == 0
            "
            [title]="'Trial ended earlier today'"
          >
            hourglass_bottom
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        class="text-uppercase"
        field="create_time"
        title="Sent"
      >
        <ng-template
          kendoGridFilterMenuTemplate
          let-filter
          let-column="column"
          let-filterService="filterService"
        >
          <kendo-grid-date-filter-menu
            [column]="column"
            [filter]="filter"
            [filterService]="filterService"
          >
            <kendo-filter-eq-operator></kendo-filter-eq-operator>
            <kendo-filter-before-operator></kendo-filter-before-operator>
            <kendo-filter-after-operator></kendo-filter-after-operator>
          </kendo-grid-date-filter-menu>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{ dataItem.create_time | date : "medium" }}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        class="text-uppercase"
        field="expiration"
        title="Expires"
      >
        <ng-template
          kendoGridFilterMenuTemplate
          let-filter
          let-column="column"
          let-filterService="filterService"
        >
          <kendo-grid-date-filter-menu
            [column]="column"
            [filter]="filter"
            [filterService]="filterService"
          >
            <kendo-filter-eq-operator></kendo-filter-eq-operator>
            <kendo-filter-before-operator></kendo-filter-before-operator>
            <kendo-filter-after-operator></kendo-filter-after-operator>
          </kendo-grid-date-filter-menu>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{ dataItem.expiration | date : "medium" }}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        class="text-uppercase text-center"
        field="is_complete"
        title="Status"
        [filterable]="false"
        [width]="80"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <span
            class="material-icons secondary-color"
            *ngIf="dataItem.is_complete"
            title="Complete"
          >
            check_circle
          </span>
          <span
            class="material-icons text-warning"
            *ngIf="!dataItem.is_complete && isExpired(dataItem.expiration)"
            title="Expired"
          >
            error
          </span>
          <span
            class="material-icons primary-color"
            *ngIf="!dataItem.is_complete && !isExpired(dataItem.expiration)"
            title="Pending - Email Sent"
          >
            mark_email_read
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        class="text-uppercase text-center"
        title="Actions"
        [filterable]="false"
        [sortable]="false"
        [width]="130"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="d-flex">
            <button
              type="button"
              class="btn"
              title="Resend"
              [disabled]="dataItem.is_complete"
              (click)="confirmResend(dataItem.id)"
            >
              <span class="material-icons primary-color">send</span>
            </button>
            <button
              type="button"
              class="btn"
              title="Delete"
              (click)="confirmDelete(dataItem.id)"
            >
              <span class="material-icons primary-color">delete</span>
            </button>
          </div>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>

<kendo-dialog
  title="{{ 'General.Confirm' | translate }}"
  *ngIf="deleteConfirmationOpen"
  (close)="cancel()"
  [maxWidth]="400"
>
  <p class="text-center m-3">
    {{ "SearchRegistration.DeleteWarning" | translate }}
  </p>
  <kendo-dialog-actions>
    <div class="d-flex justify-content-between">
      <button class="btn btn-secondary m-1" (click)="cancel()">
        {{ "General.No" | translate }}
      </button>
      <button class="btn btn-primary m-1" (click)="delete()">
        {{ "General.Yes" | translate }}
      </button>
    </div>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog
  title="{{ 'General.Confirm' | translate }}"
  *ngIf="resendConfirmationOpen"
  (close)="cancel()"
  [maxWidth]="400"
>
  <p class="text-center m-3">
    {{ "SearchRegistration.ResendWarning" | translate }}
  </p>
  <kendo-dialog-actions>
    <div class="d-flex justify-content-between">
      <button class="btn btn-secondary m-1" (click)="cancel()">
        {{ "General.No" | translate }}
      </button>
      <button class="btn btn-primary m-1" (click)="resend()">
        {{ "General.Yes" | translate }}
      </button>
    </div>
  </kendo-dialog-actions>
</kendo-dialog>
