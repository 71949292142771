<div class="content">
    <h2>{{ "CreateAdminRegistration.Header" | translate }}</h2>
    <form
      class="form"
      [formGroup]="formGroup"
      *ngIf="formGroup"
      (click)="success = ''"
    >
      <div class="row">
        <div class="form-group">
          <label class="control-label">
            {{ "CreateAdminRegistration.Email" | translate }}
          </label>
          <input
            type="text"
            class="form-control"
            placeholder="{{ 'CreateAdminRegistration.EmailPlaceholder' | translate }}"
            formControlName="email"
          />
          <div
            *ngIf="
              (formGroup.get('email')?.invalid &&
                formGroup.get('email')?.touched) ||
              formGroup.get('email')?.dirty
            "
          >
            <small
              class="text-danger"
              *ngIf="formGroup.get('email')?.errors?.required"
              >{{ "CreateAdminRegistration.EmailRequired" | translate }}</small
            >
            <small
              class="text-danger"
              *ngIf="formGroup.get('email')?.errors?.email"
              >{{ "CreateAdminRegistration.EmailInvalid" | translate }}</small
            >
          </div>
        </div>
      </div>
      <div class="py-2 d-flex justify-content-end">
        <button
          class="btn btn-primary"
          (click)="onSubmit()"
          type="button"
          [disabled]="isLoading"
        >
          <span class="spinner-border spinner-border-sm mr-1" *ngIf="isLoading"></span>
          Submit
        </button>
      </div>
    </form>
    <app-status
      [success]="success"
      [error]="error"
      [isLoading]="isLoading"
    ></app-status>
  </div>
  