import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContactType } from '../models/contact-type';
import { AutoGeneratedApiService } from './autogenerated-api-service';
import { Responder365AppConfig } from './app-config-service';

@Injectable({
  providedIn: 'root',
})
export class ContactTypeService extends AutoGeneratedApiService<ContactType> {
  constructor(appConfigService: Responder365AppConfig, http: HttpClient) {
    super(appConfigService, http, 'contact-type');
  }
}
