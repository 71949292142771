<div class="content">
  <h2>
    {{ "AdminConsent.Heading" | translate }}
    {{ !error ? "Succeeded" : "Failed" }}
  </h2>
  <div class="py-1" *ngIf="consent === true && !error">
    <p [ngSwitch]="prerequisiteAppId">
      {{ "AdminConsent.ConsentSuccess" | translate }}
      <span *ngSwitchCase="PrerequisiteAppIds.Graph">
        {{ "AdminConsent.GraphLogSources" | translate }}
      </span>
      <span *ngSwitchCase="PrerequisiteAppIds.Office365ManagementApi">
        {{ "AdminConsent.Office365LogSources" | translate }}
      </span>
      <span *ngSwitchCase="PrerequisiteAppIds.MicrosoftThreatProtection">
        {{ "AdminConsent.DefenderLogSources" | translate }}
      </span>
    </p>

    <div class="d-flex justify-content-between py-1">
      <p *ngIf="nextConsentInfo; else finishSetup">
        {{ "AdminConsent.SuccessExplanation" | translate }}
      </p>
      <ng-template #finishSetup>
        {{ "AdminConsent.FinishSetup" | translate }}
      </ng-template>
      <button
        class="btn btn-secondary"
        (click)="nextAdminConsent()"
        type="button"
      >
        {{ "AdminConsent.NextButton" | translate }}
      </button>
    </div>
  </div>
  <div class="row" *ngIf="shortError">
    <div class="py-1 alert alert-secondary">
      {{ shortError }}
    </div>
  </div>
  <div class="row" *ngIf="consent === false || error">
    <p [ngSwitch]="prerequisiteAppId" *ngIf="prerequisiteAppId">
      {{ "AdminConsent.ConsentFailure" | translate }}
      <span *ngSwitchCase="PrerequisiteAppIds.Graph">
        {{ "AdminConsent.GraphLogSources" | translate }}
      </span>
      <span *ngSwitchCase="PrerequisiteAppIds.Office365ManagementApi">
        {{ "AdminConsent.Office365LogSources" | translate }}
      </span>
      <span *ngSwitchCase="PrerequisiteAppIds.MicrosoftThreatProtection">
        {{ "AdminConsent.DefenderLogSources" | translate }}
      </span>
    </p>
    <div class="d-flex justify-content-between py-1">
      <p>{{ "AdminConsent.FailedExplanation" | translate }}</p>
      <div>
        <button
          class="btn btn-secondary"
          (click)="adminConsent()"
          type="button"
        >
          {{ "AdminConsent.ConsentToAppPermissions" | translate }}
        </button>
        <button
          class="btn btn-primary mt-2"
          (click)="confirmationOpen = true"
          type="button"
          *ngIf="
            appId !== config.authConfig.clientId &&
            prerequisiteAppId === PrerequisiteAppIds.MicrosoftThreatProtection
          "
        >
          {{ "AdminConsent.SkipConsent" | translate }}
        </button>
        <a href="/contact-us" class="btn btn-primary mt-2"> Contact Us </a>
      </div>
    </div>
  </div>
</div>

<kendo-dialog
  title="{{ 'General.Confirm' | translate }}"
  *ngIf="confirmationOpen"
  (close)="confirmationOpen = false"
  [maxWidth]="400"
>
  <p class="text-center m-3">
    {{ "AdminConsent.SkipConsentWarning" | translate }}
  </p>
  <kendo-dialog-actions>
    <div class="d-flex justify-content-between">
      <button class="btn btn-secondary m-1" (click)="confirmationOpen = false">
        {{ "General.Cancel" | translate }}
      </button>
      <button class="btn btn-primary m-1" (click)="skipConsent()">
        {{ "General.OK" | translate }}
      </button>
    </div>
  </kendo-dialog-actions>
</kendo-dialog>
