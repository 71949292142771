import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { RegistrationService } from 'src/app/services/registration-service';

@Component({
  selector: 'app-create-admin-registration',
  templateUrl: './create-admin-registration.component.html',
  styleUrls: ['./create-admin-registration.component.scss']
})
export class CreateAdminRegistrationComponent {
  isLoading: boolean = false;
  error: string = '';
  success: string = '';
  formGroup!: FormGroup;

  constructor(private registrationService: RegistrationService, private translateService: TranslateService, private formBuilder: FormBuilder) {
   this.createForm(); 
  }

  async onSubmit(): Promise<void> {
    try {
      if (this.formGroup.valid) {
        this.isLoading = true;
        this.error = '';
  
        await this.registrationService.createAdmin(
          this.formGroup.value.email
        );
        this.success = this.translateService.instant(
          'CreateRegistration.SuccessMessage'
        );
        this.formGroup.reset();
        this.createForm();
      } else {
        this.formGroup.markAllAsTouched();
      }
    } catch (err: any) {
      console.error(err);
      if (err instanceof HttpErrorResponse) {
        const httpError = err as HttpErrorResponse;
        switch (httpError.error) {
          default: {
            if (typeof httpError.error === 'string')
              this.error = httpError.error;
            else
              this.error = this.translateService.instant(
                'Error.GenericServerError'
              );
          }
        }
      }
    } finally {
      this.isLoading = false;
    }
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      email: new FormControl(null, [Validators.required, Validators.email])
    });
  }
}

