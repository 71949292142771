import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogSource } from '../models/log-source';
import { AutoGeneratedApiService } from './autogenerated-api-service';
import { lastValueFrom } from 'rxjs';
import { Responder365AppConfig } from './app-config-service';
@Injectable({
  providedIn: 'root',
})
export class LogSourceService extends AutoGeneratedApiService<LogSource> {
  constructor(config: Responder365AppConfig, http: HttpClient) {
    super(config, http, 'log-source');
  }

  async start(id: number): Promise<string> {
    return lastValueFrom(
      this._http.post<string>(this.baseServiceUrl + '/start', id)
    );
  }

  async stop(id: number): Promise<string> {
    return lastValueFrom(
      this._http.post<string>(this.baseServiceUrl + '/stop', id)
    );
  }
}
