<div class="container main-content-container">
  <div class="row justify-content-center ml-5 pt-5 pb-5 mb-5">
    <div class="col-10">
      <div class="title-section mt-5">
        <h1 class="h1-heading grey-heading">
          {{ "ContactUs.Heading" | translate }}
        </h1>
        <span class="subheading grey-heading">{{
          "ContactUs.SubHeading" | translate
        }}</span>
      </div>
      <div class="form-div">
        <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="form-group col">
              <label class="control-label">{{
                "ContactUs.FirstName" | translate
              }}</label>
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'ContactUs.FirstNamePlaceholder' | translate }}"
                formControlName="firstName"
              />

              <p
                class="explanation"
                *ngIf="
                  firstName?.invalid && (firstName?.dirty || firstName?.touched)
                "
              >
                <span class="form-text bad" *ngIf="firstName?.errors?.required">
                  {{ "ContactUs.FirstNameRequired" | translate }}</span
                >
                <span
                  class="form-text bad"
                  *ngIf="firstName?.errors?.pattern"
                  >{{ "ContactUs.FirstNameInvalid" | translate }}</span
                >
              </p>
            </div>
          </div>
          <div class="row">
            <div class="form-group">
              <label class="control-label">{{
                "ContactUs.LastName" | translate
              }}</label>
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'ContactUs.LastNamePlaceholder' | translate }}"
                formControlName="lastName"
              />

              <p
                class="explanation"
                *ngIf="
                  lastName?.invalid && (lastName?.dirty || lastName?.touched)
                "
              >
                <span class="form-text bad" *ngIf="lastName?.errors?.required">
                  {{ "ContactUs.LastNameRequired" | translate }}</span
                >
              </p>
              <span class="form-text bad" *ngIf="lastName?.errors?.pattern">{{
                "ContactUs.LastNameInvalid" | translate
              }}</span>
            </div>
          </div>
          <div class="row">
            <div class="form-group">
              <label class="control-label">{{
                "ContactUs.Email" | translate
              }}</label>
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'ContactUs.EmailPlaceholder' | translate }}"
                formControlName="email"
              />

              <p
                class="explanation"
                *ngIf="email?.invalid && (email?.dirty || email?.touched)"
              >
                <span class="form-text bad" *ngIf="email?.errors?.required">
                  {{ "ContactUs.EmailRequired" | translate }}</span
                >
                <span class="form-text bad" *ngIf="email?.errors?.email">{{
                  "ContactUs.EmailInvalid" | translate
                }}</span>
              </p>
            </div>
          </div>
          <div class="form-group">
            <label class="control-label">{{
              "ContactUs.YourMessage" | translate
            }}</label>
            <textarea
              class="form-control"
              cols="5"
              rows="10"
              placeholder="{{ 'ContactUs.YourMessagePlaceholder' | translate }}"
              formControlName="message"
            >
            </textarea>
            <p
              class="explanation"
              *ngIf="message?.invalid && (message?.dirty || message?.touched)"
            >
              <span class="form-text bad" *ngIf="message?.errors?.required">
                {{ "ContactUs.YourMessageRequired" | translate }}</span
              >
            </p>
          </div>
          <button
            type="submit"
            class="btn mt-20px btn-primary text-uppercase"
            [disabled]="isLoading"
          >
            <span>{{ "ContactUs.SubmitButton" | translate }}</span>
          </button>
          <div class="alert alert-success mt-20px" *ngIf="isSuccess">
            {{ "ContactUs.Success" | translate }}
          </div>
          <div class="alert alert-danger mt-20px" *ngIf="isError">
            {{ "Error.GenericServerError" | translate }}
          </div>
        </form>
        <div
          class="row info-banner mt-3 p-3"
          [innerHTML]="'ContactUs.Description' | translate"
        ></div>
      </div>
    </div>
  </div>
</div>
