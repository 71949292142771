import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@progress/kendo-angular-notification';
import { lastValueFrom } from 'rxjs';
import { ContactType } from 'src/app/models/contact-type';
import { EscalationContact } from 'src/app/models/escalation-contact';
import { EscalationDetail } from 'src/app/models/escalation-detail';
import { LogSource, LogSourceWrapper } from 'src/app/models/log-source';
import { AdminRegistrationResponse } from 'src/app/models/registration';
import { SupportType } from 'src/app/models/support-type';
import { Tenant } from 'src/app/models/tenant';
import { ContactTypeService } from 'src/app/services/contact-type-service';
import { EscalationContactService } from 'src/app/services/escalation-contact-service';
import { EscalationDetailService } from 'src/app/services/escalation-detail-service';
import { LoadingService } from 'src/app/services/loading-service';
import { LogSourceService } from 'src/app/services/log-source-service';
import { LogSourceTypeService } from 'src/app/services/log-source-type-service';
import {
  isExpired,
  RegistrationService,
} from 'src/app/services/registration-service';
import { SupportTypeService } from 'src/app/services/support-type-service';
import { TenantService } from 'src/app/services/tenant-service';

@Component({
  selector: 'app-view-registration',
  templateUrl: './view-registration.component.html',
  styleUrls: ['./view-registration.component.scss'],
})
export class ViewRegistrationComponent implements OnInit {
  error = '';
  success = '';
  registration!: AdminRegistrationResponse;
  logSourceWrappers: LogSourceWrapper[] = [];
  isExpired = isExpired;
  escalationContacts: EscalationContact[] = [];
  escalationDetail: EscalationDetail | undefined = <EscalationDetail>{};
  contactTypes: ContactType[] = [];
  supportTypes: SupportType[] = [];
  trialOptions = [
    { text: '7 days', value: 7 },
    { text: '30 days', value: 30 },
  ];
  selectedTrialOption = this.trialOptions[0];

  constructor(
    private route: ActivatedRoute,
    private registrationService: RegistrationService,
    private logTypeService: LogSourceTypeService,
    private translateService: TranslateService,
    public loadingService: LoadingService,
    private escalationDetailService: EscalationDetailService,
    private escalationContactService: EscalationContactService,
    private supportTypeService: SupportTypeService,
    private contactTypeService: ContactTypeService,
    private logSourceService: LogSourceService,
    private notificationService: NotificationService
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      this.loadingService.show();
      const id = this.route.snapshot.paramMap.get('id');
      if (id) {
        const registration = await this.registrationService.adminFetch(id);
        const [logSourceTypes, escalationDetails] = await Promise.all([
          this.logTypeService.fetchMultiple(
            registration.log_sources.map((ls) => ls.log_source_type_id)
          ),
          this.escalationDetailService.listForProperty(
            'tenant',
            registration.tenant_id
          ),
        ]);

        if (registration.is_complete) {
          this.escalationDetail =
            escalationDetails[escalationDetails.length - 1];
          if (this.escalationDetail) {
            this.escalationContacts =
              await this.escalationContactService.listForProperty(
                'escalation-detail',
                this.escalationDetail.id
              );
            const [supportTypes, contactTypes] = await Promise.all([
              this.supportTypeService.fetchMultiple(
                this.escalationContacts.map((ec) => ec.support_type_id)
              ),
              this.contactTypeService.fetchMultiple(
                this.escalationContacts.map((ec) => ec.contact_type_id)
              ),
            ]);
            this.contactTypes = contactTypes;
            this.supportTypes = supportTypes;
          }
        }

        this.registration = registration;
        let trialLengthIndex = this.trialOptions.findIndex(
          (to) => to.value === this.registration.trial_length
        );
        if (trialLengthIndex > -1)
          this.selectedTrialOption = this.trialOptions[trialLengthIndex];

        for (const logSource of this.registration?.log_sources) {
          this.logSourceWrappers.push(<LogSourceWrapper>{
            log_source: logSource,
            log_source_type: logSourceTypes.find(
              (lst) => lst.id === logSource.log_source_type_id
            ),
          });
        }
      }
    } catch (err: any) {
      console.error(err);
      if (err instanceof HttpErrorResponse) {
        const httpError = err as HttpErrorResponse;
        switch (httpError.error) {
          default: {
            if (typeof httpError.error === 'string')
              this.error = httpError.error;
            else
              this.error = await lastValueFrom(
                this.translateService.get('Error.GenericServerError')
              );
          }
        }
      }
    } finally {
      this.loadingService.hide();
    }
  }

  copyTenantId() {
    navigator.clipboard.writeText(this.registration.tenant.tenant_id);
  }

  async toggleLogSource(logSource: LogSource) {
    const logSourceType = this.logSourceWrappers.find(
      (lsw) => lsw.log_source_type.id === logSource.log_source_type_id
    )?.log_source_type;

    try {
      await this.logSourceService.save(logSource);
      this.notificationService.show({
        content: `${logSourceType?.name} successfully ${
          logSource.is_enabled ? 'enabled' : 'disabled'
        }.`,
        animation: { type: 'fade', duration: 400 },
        position: { horizontal: 'center', vertical: 'bottom' },
        type: { style: 'success', icon: true },
      });
    } catch (err: any) {
      console.error(err);
      this.notificationService.show({
        content: `An error occurred ${
          logSource.is_enabled ? 'enabling' : 'disabling'
        } ${logSourceType?.name}.  Please try again...`,
        animation: { type: 'fade', duration: 400 },
        position: { horizontal: 'center', vertical: 'bottom' },
        type: { style: 'error', icon: true },
      });
    }
  }
  async toggleTrial() {
    const action = this.registration.is_trial ? 'enabled' : 'disabled';
    try {
      if (
        this.registration.is_complete &&
        this.registration.is_trial &&
        !this.registration.trial_expiration
      ) {
        //if registration is already complete, trial date will not be set
        let trialDate = new Date();
        trialDate.setDate(trialDate.getDate() + 30);
        this.registration.trial_expiration = trialDate;
        this.selectedTrialOption = this.trialOptions[1];
        this.registration.trial_length = 30;
      }

      await this.registrationService.updateTrial(
        this.registration.id,
        this.registration.is_trial,
        this.registration.trial_expiration,
        this.registration.trial_length
      );

      const id = this.route.snapshot.paramMap.get('id');
      if (id) this.registration = await this.registrationService.adminFetch(id);

      this.notificationService.show({
        content: `Trial ${action} successfully.`,
        animation: { type: 'fade', duration: 400 },
        position: { horizontal: 'center', vertical: 'bottom' },
        type: { style: 'success', icon: true },
      });
    } catch (err: any) {
      this.notificationService.show({
        content:
          'An error occurred updating the trial details.  Please try again...',
        animation: { type: 'fade', duration: 400 },
        position: { horizontal: 'center', vertical: 'bottom' },
        type: { style: 'error', icon: true },
      });
    }
  }
  async extendTrial() {
    try {
      this.registration.trial_length = this.selectedTrialOption.value;

      let trialDate = new Date(this.registration.trial_expiration);
      trialDate.setUTCDate(
        trialDate.getUTCDate() + this.registration.trial_length
      );
      this.registration.trial_expiration = trialDate;

      await this.registrationService.updateTrial(
        this.registration.id,
        this.registration.is_trial,
        this.registration.trial_expiration,
        this.registration.trial_length
      );

      this.notificationService.show({
        content: 'Trial extended successfully.',
        animation: { type: 'fade', duration: 400 },
        position: { horizontal: 'center', vertical: 'bottom' },
        type: { style: 'success', icon: true },
      });
    } catch (err: any) {
      console.error(err);
      this.notificationService.show({
        content: 'An error occurred extending the trial.  Please try again...',
        animation: { type: 'fade', duration: 400 },
        position: { horizontal: 'center', vertical: 'bottom' },
        type: { style: 'error', icon: true },
      });
    }
  }
}
