import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Roles } from 'src/app/models/roles';
import { Responder365AppConfig } from 'src/app/services/app-config-service';
import { SessionService } from 'src/app/services/session-service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
  isAdmin: boolean | undefined;
  isMember: boolean | undefined;
  hangfireUrl!: any;
  constructor(
    private config: Responder365AppConfig,
    private sanitizer: DomSanitizer,
    private sessionService: SessionService
  ) {}

  ngOnInit(): void {
    this.sessionService.sessionObservable$.subscribe((s) => {
      this.isAdmin = s?.roles?.some((r) => r === Roles.Admin);
      this.isMember = s?.roles?.some((r) => r === Roles.Member);
    });

    const url = this.config.apiUrl + '/hangfire';
    this.hangfireUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
