import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogSourceType } from '../models/log-source-type';
import { Responder365AppConfig } from './app-config-service';
import { AutoGeneratedApiService } from './autogenerated-api-service';

@Injectable({
  providedIn: 'root',
})
export class LogSourceTypeService extends AutoGeneratedApiService<LogSourceType> {
  constructor(config: Responder365AppConfig, http: HttpClient) {
    super(config, http, 'log-source-type');
  }
}
