import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AutoGeneratedApiService } from './autogenerated-api-service';
import { Responder365AppConfig } from './app-config-service';
import { ReportDefinition } from '../models/report-definition';
import { Observable, lastValueFrom } from 'rxjs';
import { PageRequest, PageResponse } from '../models/entity-base';

@Injectable({
  providedIn: 'root',
})
export class ReportDefinitionService extends AutoGeneratedApiService<ReportDefinition> {
  constructor(config: Responder365AppConfig, http: HttpClient) {
    super(config, http, 'report-definition');
  }

  async search(request: PageRequest): Promise<PageResponse<ReportDefinition>> {
    return lastValueFrom(
      this._http.post<PageResponse<ReportDefinition>>(
        this.baseServiceUrl + '/page',
        request
      )
    );
  }

  async add(
    name: string,
    filename: string,
    base64: string,
  ) {
    return lastValueFrom(
      this._http.post(`${this.baseServiceUrl}/file`, {
        name: name,
        filename: filename,
        data: base64
      })
    );
  }

  async edit(
    id: string,
    name: string,
    filename: string,
    base64: string,
  ) {
    return lastValueFrom(
      this._http.put(`${this.baseServiceUrl}/file/${id}`, {
        name: name,
        filename: filename,
        data: base64
      })
    );
  }

  download(id: string) : Observable<Blob> {
      return this._http.get(`${this.baseServiceUrl}/file/${id}`, {
        responseType: 'blob'
      });
  }

}
