import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { PageRequest, PageResponse } from '../models/entity-base';
import { EscalationContact } from '../models/escalation-contact';
import { EscalationDetail } from '../models/escalation-detail';
import { LogSource } from '../models/log-source';
import {
  AdminRegistrationResponse,
  CreateRegistrationRequest,
  Registration,
  RegistrationResponse,
} from '../models/registration';
import { Responder365AppConfig } from './app-config-service';
import { BaseApiService } from './base-api-service';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService extends BaseApiService {
  constructor(protected config: Responder365AppConfig, http: HttpClient) {
    super(config, http, 'registration');
  }

  async fetch(identifier: string) {
    return lastValueFrom(
      this._http.post<RegistrationResponse>(
        this.baseServiceUrl + '/fetch',
        JSON.stringify(identifier),
        {
          headers: this.headers,
        }
      )
    );
  }

  async list() {
    return lastValueFrom(this._http.get<Registration[]>(this.baseServiceUrl));
  }

  async complete(
    code: string,
    escalationDetail: EscalationDetail = <EscalationDetail>{},
    escalationContacts: EscalationContact[] = []
  ) {
    return lastValueFrom(
      this._http.post(this.baseServiceUrl + '/complete', {
        code: code,
        escalation_detail: escalationDetail,
        escalation_contacts: escalationContacts,
      })
    );
  }

  async createMember(
    email: string,
    company: string,
    existingCompanyCode: string,
    logSources: LogSource[],
    isTrial: boolean = false,
    serviceType: string = ''
  ) {
    return lastValueFrom(
      this._http.post(
        this.baseServiceUrl + '/create',
        <CreateRegistrationRequest>{
          email_address: email,
          company: company,
          existing_company_code: existingCompanyCode,
          log_sources: logSources,
          is_trial: isTrial,
          service_type: serviceType,
        },
        {
          headers: this.headers,
        }
      )
    );
  }

  async createAdmin(email: string) {
    return lastValueFrom(
      this._http.post(
        this.baseServiceUrl + '/create-admin',
        JSON.stringify(email),
        {
          headers: this.headers,
        }
      )
    );
  }

  async resend(id: string): Promise<string> {
    return lastValueFrom(
      this._http.post<string>(this.baseServiceUrl + '/resend', id)
    );
  }

  async delete(id: string): Promise<void> {
    return lastValueFrom(
      this._http.post<void>(this.baseServiceUrl + '/delete', id)
    );
  }

  async updateTrial(
    id: string,
    isTrial: boolean,
    expiration: Date,
    trialLength: number
  ): Promise<void> {
    return lastValueFrom(
      this._http.post<void>(this.baseServiceUrl + '/update-trial', {
        registration_id: id,
        is_trial: isTrial,
        trial_expiration: expiration,
        trial_length: trialLength,
      })
    );
  }

  async adminConsent(appId: string, state: string = '') {
    //NOTE: The route the user is redirected after consent MUST NOT be guarded by MSALGuard.
    //After consent, the redirect back to app contains query parameters that get picked up by the MsalRedirectComponent and causes an infinite login loop
    //TODO: Replace with msalService.loginRedirect when they allow admin consent to be completed through it
    //See: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1626
    const url = `${location.origin}/consent`;
    location.href = `https://login.microsoftonline.com/organizations/adminconsent?client_id=${appId}&state=${state}&redirect_uri=${url}&scope=.default`;
  }

  async adminSearch(request: PageRequest): Promise<PageResponse<Registration>> {
    return lastValueFrom(
      this._http.post<PageResponse<Registration>>(
        this.baseServiceUrl + '/page',
        request
      )
    );
  }

  async adminFetch(id: string): Promise<AdminRegistrationResponse> {
    return lastValueFrom(
      this._http.post<AdminRegistrationResponse>(
        this.baseServiceUrl + '/admin/fetch',
        id
      )
    );
  }
  async sendContactUsEmail(formData: any) {
    return lastValueFrom(
      this._http.post<AdminRegistrationResponse>(
        this.baseServiceUrl + '/contact-us',
        formData
      )
    );
  }
}

export function isExpired(expiration: Date) {
  return new Date() > new Date(expiration);
}
