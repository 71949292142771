<div class="app-wrapper">
  <app-header></app-header>
  <div class="app-content">
    <app-side-bar *ngIf="showSidebar" [@fadeInOut]></app-side-bar>
    <main>
      <router-outlet></router-outlet>
    </main>
  </div>
  <app-footer></app-footer>
</div>
<app-loading></app-loading>
