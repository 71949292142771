import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CompleteRegistrationComponent } from 'src/app/components/complete-registration/complete-registration.component';
import { ErrorComponent } from 'src/app/components/error/error.component';
import { FinishSetupComponent } from 'src/app/components/finish-setup/finish-setup.component';
import { MonitoringDashboardComponent } from 'src/app/components/monitoring-dashboard/monitoring-dashboard.component';
import { Roles } from 'src/app/models/roles';
import { RoleGuard } from 'src/app/services/role.guard';

const routes: Routes = [
  {
    path: '',
    component: MonitoringDashboardComponent,
    pathMatch: 'full',
    canActivate: [RoleGuard.forRoles(Roles.Member)],
  },
  {
    path: 'register/admin/:code',
    component: CompleteRegistrationComponent,
    pathMatch: 'full',
  },
  {
    path: 'finish-setup',
    component: FinishSetupComponent,
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('../admin/admin.module').then((m) => m.AdminModule),
    canActivate: [RoleGuard.forRoles(Roles.Admin)],
    canActivateChild: [RoleGuard.forRoles(Roles.Admin)],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PostloginRoutingModule {}
