import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Responder365AppConfig } from 'src/app/services/app-config-service';

@Component({
  selector: 'app-report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view.component.scss']
})
export class ReportViewComponent implements OnInit {

  public reportSource: object = {};
  public reportServiceUrl: string = '';

  constructor(private route: ActivatedRoute, private config: Responder365AppConfig) {    
  }

  viewerContainerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%',
    ['font-family']: 'Open Sans'
  };

  ngOnInit() {
    this.reportServiceUrl = this.config.apiUrl + '/api/telerik-report';
    
    const id = this.route.snapshot.paramMap.get('id');
    this.reportSource = {
      report: id,
      parameters: {}
    };
  }

}
