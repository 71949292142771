import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { PageRequest } from 'src/app/models/entity-base';
import { KendoGridHelper } from 'src/app/services/kendo-grid-helper';
import { LoadingService } from 'src/app/services/loading-service';
import {
  isExpired,
  RegistrationService,
} from 'src/app/services/registration-service';
import { ReportDefinitionService } from 'src/app/services/report-definition-service';

@Component({
  selector: 'app-report-management',
  templateUrl: './report-management.component.html',
  styleUrls: ['./report-management.component.scss']
})
export class ReportManagementComponent implements OnInit {
  @ViewChild(GridComponent) grid!: GridComponent;
  @ViewChild('notificationAnchor', { read: ViewContainerRef, static: false })
  public notificationAnchor!: ViewContainerRef;
  kendoHelper!: KendoGridHelper;
  loadingData: boolean = true;
  searchQuery!: string;
  gridData!: GridDataResult;
  deleteConfirmationOpen = false;
  idToDelete: string = '';

  public gridState: any = {
    sort: [
      {
        field: 'create_time',
        dir: 'desc',
      },
    ],
    skip: 0,
    take: 10,
  };

  constructor(
    public registrationService: RegistrationService,
    private loadingService: LoadingService,
    private reportDefinitionService: ReportDefinitionService,
    private router: Router
  ) {
    this.kendoHelper = new KendoGridHelper();
    this.loadingService.show();
  }
  ngOnInit(): void {}

  async ngAfterViewInit(): Promise<void> {
    await this.getData();
    this.loadingService.hide();
  }

  public async emitSearch(searchData: any) {
    this.searchQuery = searchData?.name;
    this.gridState.skip = 0;
    await this.getData();
  }
  
  async getData() {
    let sort = this.kendoHelper.getSortString(this.gridState?.sort);
    let request = new PageRequest();
    request.order_by = sort == '' ? 'createtime DESC' : sort;
    request.page_number = this.gridState.skip / this.gridState.take + 1;
    request.page_size = this.gridState.take;
    request.where = `(${
      this.kendoHelper.getFullFilterString(this.gridState?.filter) ?? '1=1'
    })`;

    try {
      this.loadingData = true;
      let response = await this.reportDefinitionService.search(request);

      this.gridData = {
        data: response.data,
        total: response.total_count,
      };
    } catch (err) {
      console.error(err);
    } finally {
      this.loadingData = false;
    }
  }

  public async onStateChange(state: State): Promise<void> {
    this.gridState = state;
    await this.getData();
  }

  confirmDelete(reportDefinitionId: string) {
    this.deleteConfirmationOpen = true;
    this.idToDelete = reportDefinitionId; 
  }

  cancelDelete() {
    this.deleteConfirmationOpen = false;
  }

  async delete() {
    this.loadingData = true;
    await this.reportDefinitionService.delete(this.idToDelete);
    await this.getData();
    this.idToDelete = '';
    this.loadingData = false;
    this.deleteConfirmationOpen = false;
  }

  async edit(reportDefinitionId: string) {
    await this.router.navigateByUrl(
      `/admin/reports/${reportDefinitionId}/edit`
    );
  }

  async view(reportDefinitionId: string) {
    await this.router.navigateByUrl(
      `/admin/reports/${reportDefinitionId}`
    );
  }
  
  async download(reportDefinitionId: string, filename: string) {
      this.reportDefinitionService.download(reportDefinitionId).subscribe(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
        window.URL.revokeObjectURL(url);
      });
    }

 }
