export class ConsentInformation {
  app_id!: string;
  prerequisite_app_id!: string;
  consent!: boolean;
  skipped!: boolean;
  order!: number;
}

export class ServicePrincipal {
  app_id!: string;
  app_display_name!: string;
  app_roles!: AppRole[];
  account_enabled!: boolean;
  service_principal_names!: string;
  service_principal_type!: string;
  disabled_by_microsoft_status!: string;
}

export class AppRole {
  description!: string;
  display_name!: string;
  origin!: string;
  value!: string;
  is_enabled!: boolean;
}

export enum PrerequisiteAppIds {
  Graph = '00000003-0000-0000-c000-000000000000',
  Office365ManagementApi = 'c5393580-f805-4401-95e8-94b7a6ef2fc2',
  MicrosoftThreatProtection = '8ee8fdad-f234-4243-8f3b-15c294843740',
}
