import {
  HttpBackend,
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MonitoringDashboardComponent } from './components/monitoring-dashboard/monitoring-dashboard.component';
import {
  AppConfigService,
  Responder365AppConfig,
} from './services/app-config-service';
import {
  IPublicClientApplication,
  PublicClientApplication,
  BrowserCacheLocation,
  LogLevel,
  InteractionType,
} from '@azure/msal-browser';
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalRedirectComponent,
  ProtectedResourceScopes,
} from '@azure/msal-angular';
import { CreateRegistrationComponent } from './components/create-registration/create-registration.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompleteRegistrationComponent } from './components/complete-registration/complete-registration.component';
import { StatusComponent } from './components/status/status.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { GridModule } from '@progress/kendo-angular-grid';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { SearchRegistrationComponent } from './components/search-registration/search-registration.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ViewRegistrationComponent } from './components/view-registration/view-registration.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { FinishSetupComponent } from './components/finish-setup/finish-setup.component';
import { AdminConsentComponent } from './components/admin-consent/admin-consent.component';
import { PostLoginModule } from './modules/post-login/post-login.module';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { IconsModule } from '@progress/kendo-angular-icons';
import { CommonModule } from '@angular/common';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { LoadingComponent } from './components/loading/loading.component';
import { ErrorComponent } from './components/error/error.component';
import { AdminModule } from './modules/admin/admin.module';
import { MenusModule } from '@progress/kendo-angular-menu';
import { LoadingService } from './services/loading-service';
import { CreateAdminRegistrationComponent } from './components/create-admin-registration/create-admin-registration.component';
import { SearchAdminRegistrationComponent } from './components/search-admin-registration/search-admin-registration.component';
import { EscalationMatrixComponent } from './components/escalation-matrix/escalation-matrix.component';
import packageInfo from './../../package.json';
import { SessionInterceptor } from './services/session-interceptor';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { EndUserAgreementComponent } from './components/end-user-agreement/end-user-agreement.component';
import { BrowserCompatibilityComponent } from './components/browser-compatibility/browser-compatibility.component';
import { ReportManagementComponent } from './components/report-management/report-management.component';
import { TelerikReportingModule } from '@progress/telerik-angular-report-viewer';
import { ReportUploadComponent } from './components/report-upload/report-upload.component';
import { ReportEditComponent } from './components/report-edit/report-edit.component';
import { ReportViewComponent } from './components/report-view/report-view.component';
import { EligibleServicesComponent } from './components/eligible-services/eligible-services.component';
import { PopupModule } from '@progress/kendo-angular-popup';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(
    httpClient,
    './assets/i18n/',
    `.json?v=${packageInfo.version}`
  );
}

export function MSALInstanceFactory(
  appConfig: Responder365AppConfig
): IPublicClientApplication {
  return new PublicClientApplication({
    auth: appConfig.authConfig,
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(
  config: Responder365AppConfig
): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<
    string,
    (string | ProtectedResourceScopes)[] | null
  >();

  protectedResourceMap.set(`${config?.apiUrl}/api/registration/fetch`, null);

  protectedResourceMap.set(`${config?.apiUrl}/api/*`, [
    `api://${config?.authConfig?.clientId}/User.Api.Access`,
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(
  config: Responder365AppConfig
): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [`api://${config?.authConfig?.clientId}/User.Api.Access`],
    },
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MonitoringDashboardComponent,
    CreateRegistrationComponent,
    CompleteRegistrationComponent,
    StatusComponent,
    SearchRegistrationComponent,
    ViewRegistrationComponent,
    FinishSetupComponent,
    AdminConsentComponent,
    SideBarComponent,
    LoadingComponent,
    ErrorComponent,
    CreateAdminRegistrationComponent,
    SearchAdminRegistrationComponent,
    EscalationMatrixComponent,
    TermsOfUseComponent,
    ContactUsComponent,
    EndUserAgreementComponent,
    BrowserCompatibilityComponent,
    ReportManagementComponent,
    ReportUploadComponent,
    ReportEditComponent,
    ReportViewComponent,
    EligibleServicesComponent,
  ],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputsModule,
    LabelModule,
    ButtonsModule,
    IconsModule,
    LayoutModule,
    DropDownsModule,
    GridModule,
    PopupModule,
    DialogModule,
    DateInputsModule,
    IndicatorsModule,
    NotificationModule,
    MenusModule,
    TelerikReportingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    PostLoginModule,
    AdminModule,
  ],
  exports: [],
  providers: [
    {
      provide: Responder365AppConfig,
      useFactory: (appConfigService: AppConfigService) =>
        appConfigService.config,
      deps: [AppConfigService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (appConfigService: AppConfigService) => async () => {
        await appConfigService.loadConfig(packageInfo.version);
      },
      deps: [AppConfigService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [Responder365AppConfig, HttpBackend],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
      deps: [Responder365AppConfig, LoadingService, HttpBackend],
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
      deps: [Responder365AppConfig, LoadingService, HttpBackend],
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
