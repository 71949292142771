<div class="content" #notificationAnchor>
    <div class="d-flex justify-content-between">
      <h2>{{ "ReportManagement.Heading" | translate }}</h2>
      <button class="btn btn-secondary" routerLink="/admin/reports/upload/">
        {{ "ReportManagement.Upload" | translate }}
      </button>
    </div>
  
    <div class="table mt-3">
      <kendo-grid
        #grid
        [data]="gridData"
        [loading]="loadingData"
        [pageSize]="gridState.take"
        [skip]="gridState.skip"
        [pageable]="true"
        [filter]="gridState.filter"
        filterable="menu"
        [navigable]="true"
        [resizable]="true"
        [sortable]="true"
        [sort]="gridState.sort"
        (dataStateChange)="onStateChange($event)"
      >
        <kendo-grid-column
          class="text-uppercase"
          field="name"
          title="Name"
          [sortable]="true"
        >
          <ng-template
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService"
          >
            <kendo-grid-string-filter-menu
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
            >
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-menu>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="kendo-grid-custom-link">
                <span>{{ dataItem.name }}</span>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          class="text-uppercase"
          field="filename"
          title="Filename"
        >
          <ng-template
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService"
          >
            <kendo-grid-date-filter-menu
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
            >
              <kendo-filter-eq-operator></kendo-filter-eq-operator>
              <kendo-filter-before-operator></kendo-filter-before-operator>
              <kendo-filter-after-operator></kendo-filter-after-operator>
            </kendo-grid-date-filter-menu>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="kendo-grid-custom-link">
                <span>{{ dataItem.filename }}</span>
            </div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
          class="text-uppercase"
          title="Created"
          field="create_time"
          [sortable]="true"
        >
          <ng-template
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService"
          >
            <kendo-grid-string-filter-menu
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
            >
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-menu>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="kendo-grid-custom-link">
                <span>{{ dataItem.create_time | date: 'short' }}</span>
            </div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
          class="text-uppercase"
          title="Updated"
          field="modify_time"
          [sortable]="true"
        >
          <ng-template
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService"
          >
            <kendo-grid-string-filter-menu
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
            >
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-menu>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="kendo-grid-custom-link">
                <span>{{ dataItem.modify_time | date: 'short' }}</span>
            </div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
          class="text-uppercase text-center"
          title="Actions"
          [filterable]="false"
          [sortable]="false"
          [width]="230"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="d-flex">
              <button
                type="button"
                class="btn"
                title="Run"
                (click)="view(dataItem.id)"
              >
                <span class="material-icons primary-color">play_arrow</span>
              </button>

              <button
                type="button"
                class="btn"
                title="Download"
                (click)="download(dataItem.id, dataItem.filename)"
              >
                <span class="material-icons primary-color">download</span>
              </button>

              <button
                type="button"
                class="btn"
                title="Edit"
                (click)="edit(dataItem.id)"
              >
                <span class="material-icons primary-color">edit</span>
              </button>

              <button
                type="button"
                class="btn"
                title="Delete"
                (click)="confirmDelete(dataItem.id)"
              >
                <span class="material-icons primary-color">delete</span>
              </button>
            </div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
  
  <kendo-dialog
    title="{{ 'General.Confirm' | translate }}"
    *ngIf="deleteConfirmationOpen"
    (close)="cancelDelete()"
    [maxWidth]="400"
  >
    <p class="text-center m-3">
      {{ 'ReportManagement.DeleteWarning' | translate }}
    </p>
    <kendo-dialog-actions>
      <div class="d-flex justify-content-between">
        <button class="btn btn-secondary m-1" (click)="cancelDelete()">{{ 'General.No' | translate }}</button>
        <button class="btn btn-primary m-1" (click)="delete()">{{ 'General.Yes' | translate }}</button>
      </div>
    </kendo-dialog-actions>
  </kendo-dialog>
  