<div class="content">
  <h2>{{ "CreateRegistration.Header" | translate }}</h2>
  <form
    class="form"
    [formGroup]="formGroup"
    *ngIf="formGroup && logSourceTypes.length > 0"
    (click)="success = ''"
  >
    <div class="row">
      <div class="form-group">
        <label class="control-label">
          {{ "CreateRegistration.Email" | translate }}
        </label>
        <input
          type="text"
          class="form-control"
          placeholder="{{ 'CreateRegistration.EmailPlaceholder' | translate }}"
          formControlName="email"
        />
        <div
          *ngIf="
            (formGroup.get('email')?.invalid &&
              formGroup.get('email')?.touched) ||
            formGroup.get('email')?.dirty
          "
        >
          <small
            class="text-danger"
            *ngIf="formGroup.get('email')?.errors?.required"
            >{{ "CreateRegistration.EmailRequired" | translate }}</small
          >
          <small
            class="text-danger"
            *ngIf="formGroup.get('email')?.errors?.email"
            >{{ "CreateRegistration.EmailInvalid" | translate }}</small
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label class="control-label">
            {{ "CreateRegistration.Company" | translate }}
          </label>
          <input
            type="text"
            class="form-control"
            placeholder="{{
              'CreateRegistration.CompanyPlaceholder' | translate
            }}"
            formControlName="company"
          />
          <div
            *ngIf="
              (formGroup.get('company')?.invalid &&
                formGroup.get('company')?.touched) ||
              formGroup.get('company')?.dirty
            "
          >
            <small
              class="text-danger"
              *ngIf="formGroup.get('company')?.errors?.required"
              >{{ "CreateRegistration.CompanyRequired" | translate }}</small
            >
            <small
              class="text-danger"
              *ngIf="formGroup.get('company')?.errors?.maxlength"
              >{{ "CreateRegistration.CompanyMaxLength" | translate }}</small
            >
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label class="control-label">
            {{ "CreateRegistration.CompanyCode" | translate }}
          </label>
          <input
            type="text"
            class="form-control"
            formControlName="companyCode"
          />
        </div>
        <div
          *ngIf="
            (formGroup.get('companyCode')?.invalid &&
              formGroup.get('companyCode')?.touched) ||
            formGroup.get('companyCode')?.dirty
          "
        >
          <small
            class="text-danger"
            *ngIf="
              formGroup.get('companyCode')?.errors?.minlength ||
              formGroup.get('companyCode')?.errors?.maxlength
            "
            >{{ "CreateRegistration.CompanyCodeLength" | translate }}</small
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label class="control-label mr-2">
            {{ "CreateRegistration.ServiceType" | translate }}
          </label>
          <label class="radio-label mx-2">
            {{ "CreateRegistration.Standard" | translate }}
          </label>
          <input
            type="radio"
            name="serviceType"
            value="Standard"
            formControlName="serviceType"
            kendoRadioButton
          />
          <label class="radio-label mx-2">
            {{ "CreateRegistration.IncidentResponse" | translate }}
          </label>
          <input
            type="radio"
            name="serviceType"
            value="Incident Response"
            formControlName="serviceType"
            kendoRadioButton
          />
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label class="control-label mr-2">
            {{ "CreateRegistration.Trial" | translate }}
          </label>
          <kendo-switch
            size="small"
            onLabel=" "
            formControlName="isTrial"
          ></kendo-switch>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group">
        <div class="d-flex justify-content-between">
          <label class="control-label">
            {{ "CreateRegistration.LogSources" | translate }}
          </label>
          <label class="control-label">
            {{ "CreateRegistration.Interval" | translate }}
          </label>
        </div>
        <div
          class="d-flex justify-content-between py-1"
          *ngFor="let lst of logSourceTypes; let i = index"
        >
          <div formArrayName="logSourceTypes" class="text-nowrap">
            <kendo-switch
              size="small"
              onLabel=" "
              [formControlName]="i"
            ></kendo-switch>
            <label
              [for]="i.toString()"
              class="align-middle ml-1"
              (click)="toggleLogSourceType(i)"
            >
              <span class="d-inline-block text-truncate wrapper">{{
                lst.name
              }}</span>
            </label>
          </div>
          <div formArrayName="intervals">
            <div class="d-flex justify-content-end">
              <div
                *ngIf="
                  (formGroup.get('intervals.' + i)?.invalid &&
                    formGroup.get('intervals.' + i)?.touched) ||
                  formGroup.get('intervals.' + i)?.dirty
                "
              >
                <small
                  class="text-danger pr-2"
                  *ngIf="formGroup.get('intervals.' + i)?.errors?.min"
                  >{{ "CreateRegistration.MinimumInterval" | translate }}
                </small>
                <small
                  class="text-danger pr-2"
                  *ngIf="formGroup.get('intervals.' + i)?.errors?.maxError"
                  >{{ "CreateRegistration.MaximumInterval" | translate }}
                </small>
                <small
                  class="text-danger pr-2"
                  *ngIf="formGroup.get('intervals.' + i)?.errors?.required"
                  >{{ "CreateRegistration.IntervalRequired" | translate }}
                </small>
              </div>
              <kendo-numerictextbox
                class="interval"
                [min]="5"
                [max]="59"
                format="n"
                [decimals]="0"
                [formControlName]="i"
              >
              </kendo-numerictextbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-2 d-flex justify-content-between">
      <div>
        <div
          *ngIf="
            (formGroup.get('logSourceTypes')?.invalid &&
              formGroup.get('logSourceTypes')?.touched) ||
            formGroup.get('logSourceTypes')?.dirty
          "
        >
          <small
            class="text-danger"
            *ngIf="formGroup.get('logSourceTypes')?.errors?.required"
            >{{ "CreateRegistration.LogSourceRequired" | translate }}</small
          >
        </div>
      </div>

      <button
        class="btn btn-primary"
        (click)="onSubmit()"
        type="button"
        [disabled]="isLoading"
      >
        <span
          class="spinner-border spinner-border-sm mr-1"
          *ngIf="isLoading"
        ></span>
        Submit
      </button>
    </div>
  </form>
  <app-status
    [success]="success"
    [error]="error"
    [isLoading]="isLoading"
  ></app-status>
</div>
