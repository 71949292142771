import { Injectable } from '@angular/core';
import {
  ColumnBase,
  ColumnComponent,
  GridComponent,
} from '@progress/kendo-angular-grid';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  SortDescriptor,
} from '@progress/kendo-data-query';

@Injectable({
  providedIn: 'root',
})
export class KendoGridHelper {
  getDataFieldName(propertyName: string) {
    return propertyName.replace(/_/g, '');
  }

  getFilterString(
    field: string,
    operator: string,
    value: string | Number | Date
  ): string {
    let result = '';
    if (value instanceof Date) {
      //Discard any time associated with the value
      value = new Date(value.getFullYear(), value.getMonth(), value.getDate());
      result = this.getDateOperator(field, operator, value);
    } else if (value instanceof Number) {
      result = this.getNumericOperator(field, operator, value);
    } else {
      let trimmedValue = value?.toString()?.trim() ?? '';
      result = this.getStringOperator(field, operator, trimmedValue);
    }
    return result;
  }

  getSortString(sorts: SortDescriptor[] | undefined): string {
    let result = '';
    if (sorts !== undefined && sorts[0] && sorts[0].dir) {
      result = this.getDataFieldName(sorts[0].field) + ' ' + sorts[0].dir;
    }
    return result;
  }

  getFullFilterString(filter: CompositeFilterDescriptor) {
    let result = undefined;
    if (filter) {
      for (var i = 0; i < filter.filters.length; i++) {
        let compositeFilter = filter.filters[i] as CompositeFilterDescriptor;
        for (var j = 0; j < compositeFilter.filters.length; j++) {
          let singleFilter = compositeFilter.filters[j] as FilterDescriptor;
          if (!result) {
            result = this.getFilterString(
              this.getDataFieldName(singleFilter.field as string),
              singleFilter.operator as string,
              singleFilter.value
            );
          } else {
            result += ` ${compositeFilter.logic} ${this.getFilterString(
              this.getDataFieldName(singleFilter.field as string),
              singleFilter.operator as string,
              singleFilter.value
            )}`;
          }
        }
      }
    }
    return result;
  }

  getSearchFilter(text: string, columns: ColumnBase[]): string {
    let searchFilter = '';
    for (let column of columns) {
      let columnComponent = column as ColumnComponent;
      if (columnComponent.field && columnComponent.filter === 'text') {
        if (!searchFilter) {
          searchFilter = this.getFilterString(
            this.getDataFieldName(columnComponent.field as string),
            'contains',
            text
          );
        } else {
          searchFilter += ` OR ${this.getFilterString(
            this.getDataFieldName(columnComponent.field as string),
            'contains',
            text
          )}`;
        }
      }
    }
    return searchFilter;
  }

  getPageApiWhereClause(
    gridFilter: CompositeFilterDescriptor,
    gridColumns: ColumnBase[],
    searchText: string
  ) {
    let filter = this.getFullFilterString(gridFilter);
    if (searchText) {
      let searchFilter = this.getSearchFilter(searchText, gridColumns);
      if (filter) {
        filter = `${filter} AND (${searchFilter})`;
      } else {
        filter = searchFilter;
      }
    }
    return filter;
  }

  /* This method exists to convert the equals operator to a comparable expression for a date time offset comparsion.
       Since the kendo date filter only has date, we need to convert that to an expression that covers the full day time range */
  private getDateEqualsDateTimeClause(
    field: string,
    value: Date,
    equals: boolean
  ): string {
    let nextDay = new Date(value);
    nextDay.setDate(nextDay.getDate() + 1);
    return `((${field}>="${value.toISOString()}" AND ${field}<"${nextDay.toISOString()}") = ${equals})`;
  }

  private getStringOperator(
    field: string,
    operator: string,
    value: string
  ): string {
    let result = '';
    switch (operator) {
      case 'startswith':
      case 'endswith':
      case 'contains': {
        result = `${field}.${operator}("${value}")`;
        break;
      }
      case 'doesnotcontain': {
        result = `!${field}.contains("${value}")`;
        break;
      }
      case 'isempty':
      case 'eq': {
        result = `${field}="${value}"`;
        break;
      }
      case 'isnotempty':
      case 'neq': {
        result = `${field}!="${value}"`;
        break;
      }
      case 'isnull': {
        result = `${field} = NULL`;
        break;
      }
      case 'isnotnull': {
        result = `${field} != NULL`;
        break;
      }
      default: {
        console.error('Unknown operator: ' + operator);
      }
    }
    return result;
  }

  private getNumericOperator(
    field: string,
    operator: string,
    value: Number
  ): string {
    let result = '';
    switch (operator) {
      case 'isempty':
      case 'eq': {
        result = `${field}="${value}"`;
        break;
      }
      case 'isnotempty':
      case 'neq': {
        result = `${field}!="${value}"`;
        break;
      }
      case 'gte': {
        result = `${field}>="${value}"`;
        break;
      }
      case 'gt': {
        result = `${field}>"${value}"`;
        break;
      }
      case 'lte': {
        result = `${field}<="${value}"`;
        break;
      }
      case 'lt': {
        result = `${field}<"${value}"`;
        break;
      }
      case 'isnull': {
        result = `${field} = NULL`;
        break;
      }
      case 'isnotnull': {
        result = `${field} != NULL`;
        break;
      }
      default: {
        console.error('Unknown operator: ' + operator);
      }
    }
    return result;
  }
  private getDateOperator(
    field: string,
    operator: string,
    value: Date
  ): string {
    let result = '';
    switch (operator) {
      case 'eq': {
        result = this.getDateEqualsDateTimeClause(field, value, true);
        break;
      }
      case 'neq': {
        result = this.getDateEqualsDateTimeClause(field, value, false);
        break;
      }
      case 'gte': {
        result = `${field}>="${value.toISOString()}"`;
        break;
      }
      case 'gt': {
        let nextDay = new Date(value);
        nextDay.setDate(nextDay.getDate() + 1);
        result = `${field}>"${nextDay.toISOString()}"`;
        break;
      }
      case 'lte': {
        let nextDay = new Date(value);
        nextDay.setDate(nextDay.getDate() + 1);
        result = `${field}<="${nextDay.toISOString()}"`;
        break;
      }
      case 'lt': {
        result = `${field}<"${value.toISOString()}"`;
        break;
      }
      case 'isnull': {
        result = `${field} = NULL`;
        break;
      }
      case 'isnotnull': {
        result = `${field} != NULL`;
        break;
      }
      default: {
        console.error('Unknown operator: ' + operator);
      }
    }
    return result;
  }

  fitColumns(grid: GridComponent): void {
    if (grid) {
      grid.autoFitColumns(grid.columns);

      /* The code below is an attempt to address the standard kendo grid behavior with autoFitColumns
        that causes there to be weird whitespace if the total autosized column widths end up being less 
        than the width of the grid. This expands the last column to fill the remaining width (if possible)*/
      const tableWidth =
        document?.querySelector<HTMLElement>('.k-grid-table')?.offsetWidth ?? 0;
      const contentWidth =
        document?.querySelector<HTMLElement>('.k-grid-content')?.offsetWidth ??
        0;
      let emailColumn = grid.columns.find((c) =>
        (c as ColumnComponent)?.field?.includes('email')
      );
      let lastColumn = grid.columns.get(grid.columns.length - 1);
      let column = emailColumn ?? lastColumn; //use the first column bound to a field containing 'email' or fallback to the last column
      if (column) {
        const availableWidth =
          column.width + (contentWidth - tableWidth - grid.scrollbarWidth);
        if (availableWidth > column.minResizableWidth) {
          column.width = availableWidth;
        }
      }
    }
  }
}
