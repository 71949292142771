import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Tenant } from '../models/tenant';
import { AutoGeneratedApiService } from './autogenerated-api-service';
import { Responder365AppConfig } from './app-config-service';
import { lastValueFrom } from 'rxjs';
import { ServicePrincipal } from '../models/consent-information';

@Injectable({
  providedIn: 'root',
})
export class TenantService extends AutoGeneratedApiService<Tenant> {
  constructor(config: Responder365AppConfig, http: HttpClient) {
    super(config, http, 'tenant');
  }

  async listSkus() {
    return lastValueFrom(this._http.get<any>(this.baseServiceUrl + '/skus'));
  }

  async listServicePrincipals() {
    return lastValueFrom(
      this._http.get<ServicePrincipal[]>(
        this.baseServiceUrl + '/service-principals'
      )
    );
  }

  async listSkusForTenant(tenantId: string) {
    return lastValueFrom(
      this._http.get<any>(this.baseServiceUrl + `/skus?tenantId=${tenantId}`)
    );
  }

  async listServicePrincipalsForTenant(tenantId: string) {
    return lastValueFrom(
      this._http.get<ServicePrincipal[]>(
        this.baseServiceUrl + `/service-principals?tenantId=${tenantId}`
      )
    );
  }
}
