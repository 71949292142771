import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RegistrationService } from 'src/app/services/registration-service';
import { LoadingService } from 'src/app/services/loading-service';
import { EscalationDetail } from 'src/app/models/escalation-detail';
import { EscalationContact } from 'src/app/models/escalation-contact';
import { ContactTypeService } from 'src/app/services/contact-type-service';
import { SupportTypeService } from 'src/app/services/support-type-service';
import { ContactType } from 'src/app/models/contact-type';
import { SupportType } from 'src/app/models/support-type';
import { ActiveUserService } from 'src/app/services/active-user-service';

@Component({
  selector: 'app-finish-setup',
  templateUrl: './finish-setup.component.html',
  styleUrls: ['./finish-setup.component.scss'],
})
export class FinishSetupComponent implements OnInit {
  registrationCode!: string;
  formGroup!: FormGroup;
  isDisabled = false;
  initError: boolean = false;
  isError: boolean = false;
  error: string = '';
  success: string = '';
  timezones: any[] = [];
  escalationContacts: EscalationContact[] = [];
  escalationDetail: EscalationDetail = <EscalationDetail>{};
  contactTypes: ContactType[] = [];
  supportTypes: SupportType[] = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private registrationService: RegistrationService,
    private translateService: TranslateService,
    public loadingService: LoadingService,
    private contactTypeService: ContactTypeService,
    private supportTypeService: SupportTypeService,
    private activeUserService: ActiveUserService
  ) {}

  async ngOnInit(): Promise<void> {
    this.loadingService.show();
    const [allContactTypes, allSupportTypes] = await Promise.all([
      this.contactTypeService.list(),
      this.supportTypeService.list(),
    ]);
    this.contactTypes = allContactTypes;
    this.supportTypes = allSupportTypes;

    this.registrationCode = this.route.snapshot.queryParamMap.get('code') ?? '';

    try {
      const registration = await this.registrationService.fetch(
        this.registrationCode
      );

      const tenantId = localStorage.getItem('tenantId');

      if (tenantId) {
        this.error = await this.activeUserService.validateActiveUser(
          registration.email
        );
      } else {
        await this.router.navigateByUrl('/');
      }
    } catch (err: any) {
      if (err instanceof HttpErrorResponse) {
        const httpError = err as HttpErrorResponse;
        switch (httpError.error) {
          case 'Registration code has already been completed.': {
            await this.router.navigateByUrl('/');
            break;
          }
          default: {
            if (typeof httpError.error === 'string')
              this.error = httpError.error;
            else
              this.error = this.translateService.instant(
                'Error.GenericServerError'
              );
          }
        }
      } else {
        this.error = this.translateService.instant('Error.GenericServerError');
      }
      this.initError = true;
    }

    this.loadingService.hide();
  }

  async onSubmit() {
    try {
      this.isDisabled = true;
      this.error = '';

      await this.registrationService.complete(
        this.registrationCode,
        this.escalationDetail,
        this.escalationContacts
      );

      await this.router.navigateByUrl('/');
    } catch (err: any) {
      this.isError = true;
      console.error(err);
      if (err instanceof HttpErrorResponse) {
        if (typeof err.error === 'string') this.error = err.error;
        else
          this.error = this.translateService.instant(
            'Error.GenericServerError'
          );
      }
    } finally {
      this.isDisabled = false;
    }
  }

  async restartRegistration() {
    await this.router.navigateByUrl(`/register/${this.registrationCode}`);
  }
}
