import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LogSource } from 'src/app/models/log-source';
import { LogSourceType } from 'src/app/models/log-source-type';
import { LoadingService } from 'src/app/services/loading-service';
import { LogSourceTypeService } from 'src/app/services/log-source-type-service';
import { RegistrationService } from 'src/app/services/registration-service';

@Component({
  selector: 'app-create-registration',
  templateUrl: './create-registration.component.html',
  styleUrls: ['./create-registration.component.scss'],
})
export class CreateRegistrationComponent implements OnInit {
  logSourceTypes: LogSourceType[] = [];
  formGroup!: FormGroup;
  isLoading: boolean = false;
  error: string = '';
  success: string = '';
  constructor(
    private registrationService: RegistrationService,
    private logSourceTypeService: LogSourceTypeService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private loadingService: LoadingService
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      this.loadingService.show();
      this.isLoading = true;
      const logSourceTypes = await this.logSourceTypeService.list();
      this.logSourceTypes = logSourceTypes;
    } catch (err: any) {
      console.error(err);
      this.error = this.translateService.instant('Error.GenericServerError');
    } finally {
      this.isLoading = false;
      this.loadingService.hide();
    }

    this.createForm();
  }

  async onSubmit(): Promise<void> {
    try {
      if (this.formGroup.valid) {
        this.isLoading = true;
        this.error = '';
        let logSources = this.formGroup.value.logSourceTypes
          .map((checked: boolean, i: number) => {
            const logSourceType = this.logSourceTypes[i];
            return checked
              ? <LogSource>{
                  log_source_type_id: logSourceType.id,
                  is_enabled: true,
                  name:
                    this.formGroup.value.email.split('@')[1] +
                    ' - ' +
                    logSourceType.name,
                  retrieval_interval_minutes: this.formGroup.value.intervals[i],
                }
              : null;
          })
          .filter((v: boolean) => v !== null);

        await this.registrationService.createMember(
          this.formGroup.value.email,
          this.formGroup.value.company,
          this.formGroup.value.companyCode,
          logSources,
          this.formGroup.value.isTrial,
          this.formGroup.value.serviceType
        );
        this.success = this.translateService.instant(
          'CreateRegistration.SuccessMessage'
        );
        this.formGroup.reset();
        this.createForm();
      } else {
        this.formGroup.markAllAsTouched();
      }
    } catch (err: any) {
      console.error(err);
      if (err instanceof HttpErrorResponse) {
        const httpError = err as HttpErrorResponse;
        switch (httpError.error) {
          default: {
            if (typeof httpError.error === 'string')
              this.error = httpError.error;
            else
              this.error = this.translateService.instant(
                'Error.GenericServerError'
              );
          }
        }
      }
    } finally {
      this.isLoading = false;
    }
  }

  get logSourceTypeFormArray() {
    return this.formGroup.controls.logSourceTypes as FormArray;
  }

  get intervalFormArray() {
    return this.formGroup.controls.intervals as FormArray;
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      email: new FormControl(null, [Validators.required, Validators.email]),
      company: new FormControl(null, [
        Validators.required,
        Validators.maxLength(255),
      ]),
      companyCode: new FormControl(null, [
        Validators.minLength(7),
        Validators.maxLength(7),
      ]),
      logSourceTypes: new FormArray([], logSourceRequiredValidator()),
      intervals: new FormArray([]),
      isTrial: new FormControl(false, []),
      serviceType: new FormControl('Standard', []),
    });

    this.logSourceTypes.forEach(() => {
      this.logSourceTypeFormArray.push(new FormControl(true));
      this.intervalFormArray.push(
        new FormControl(5, [Validators.min(5), Validators.required])
      );
    });
  }

  toggleLogSourceType(i: number) {
    this.logSourceTypeFormArray.markAsDirty();
    const currentValue = this.logSourceTypeFormArray.controls[i].value;
    this.logSourceTypeFormArray.controls[i].setValue(!currentValue);
  }
}

export function logSourceRequiredValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let anyChecked = control.value.some((checked: boolean) => checked);
    return !anyChecked ? { required: true } : null;
  };
}
