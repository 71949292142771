import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { Responder365AppConfig } from 'src/app/services/app-config-service';
import { LoadingService } from 'src/app/services/loading-service';
import { SessionService } from 'src/app/services/session-service';
import { SideBarService } from 'src/app/services/side-bar-service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  constructor(
    public authService: MsalService,
    private sideBarService: SideBarService,
    private config: Responder365AppConfig,
    public loadingService: LoadingService,
    private sessionService: SessionService
  ) {}

  ngOnInit(): void {}

  isLoggedIn(): boolean {
    return this.authService.instance.getAllAccounts().length > 0;
  }

  getActiveUsername() {
    return this.authService.instance.getActiveAccount()?.username;
  }

  getActiveUsersName() {
    return this.authService.instance.getActiveAccount()?.name;
  }

  toggle() {
    this.sideBarService.toggleSideBar();
  }

  async switchAccount() {
    await this.loadingService.show();
    try {
      await this.sessionService.end();
    } finally {
      await this.authService.instance.loginRedirect(<RedirectRequest>{
        scopes: [`api://${this.config?.authConfig?.clientId}/User.Api.Access`],
        prompt: 'select_account',
      });
    }
  }

  async logout() {
    await this.loadingService.show();
    try {
      await this.sessionService.end();
    } finally {
      await this.authService.instance.logout({
        account: this.authService.instance.getActiveAccount(),
      });
    }
  }
}
