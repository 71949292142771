import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AdminConsentComponent } from './components/admin-consent/admin-consent.component';
import { CompleteRegistrationComponent } from './components/complete-registration/complete-registration.component';
import { PostLoginComponent } from './modules/post-login/post-login.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { EndUserAgreementComponent } from './components/end-user-agreement/end-user-agreement.component';
import { BrowserCompatibilityComponent } from './components/browser-compatibility/browser-compatibility.component';
import { EligibleServicesComponent } from './components/eligible-services/eligible-services.component';

const routes: Routes = [
  {
    path: 'register/:code',
    component: CompleteRegistrationComponent,
    pathMatch: 'full',
  },
  {
    path: 'eligible-services/:code',
    component: EligibleServicesComponent,
    pathMatch: 'full',
  },
  {
    path: 'terms-of-use',
    component: TermsOfUseComponent,
    pathMatch: 'full',
  },
  {
    path: 'end-user-agreement',
    component: EndUserAgreementComponent,
    pathMatch: 'full',
  },
  {
    path: 'browser-compatibility',
    component: BrowserCompatibilityComponent,
    pathMatch: 'full',
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
    pathMatch: 'full',
  },
  {
    path: 'consent',
    component: AdminConsentComponent,
    pathMatch: 'full',
  },
  {
    path: '',
    component: PostLoginComponent,
    loadChildren: () =>
      import('./modules/post-login/post-login.module').then(
        (m) => m.PostLoginModule
      ),
    canActivate: [MsalGuard],
    canActivateChild: [MsalGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
