export class EntityBase {
  id!: string;
  create_time!: Date;
  create_user_id!: number;
  modify_time!: Date;
  modify_user_id!: number;
  delete_time!: Date;
  delete_user_id!: number;
}

export class SearchRequest {
  page_number: number = 1;
  page_size: number = 10;
  order_by!: string;
}

export class PageRequest extends SearchRequest {
  where!: string;
}

export interface PageResponse<T> {
  data: T[];
  total_count: number;
}